<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button defaultHref="/devices"></ion-back-button>
  </ion-buttons>

  <ion-title>Add a Device</ion-title>
</ion-toolbar>

<ion-content>
  <ion-card class="device-card" (click)="checkPlatform(deviceTypes.CSLampController, true)">
    <ion-card-header>
      <ion-card-title>
        <img src="../../assets/device-types/ez-lamp-controller.png" alt="EZ Lamp Controller" />
        EZ Lamp Controller
        <app-icon name="chevron-forward"></app-icon>
      </ion-card-title>
    </ion-card-header>
  </ion-card>

  <ion-card class="device-card" (click)="checkPlatform(deviceTypes.CSRebooter, true)">
    <ion-card-header>
      <ion-card-title>
        <img src="../../assets/device-types/rebooter.png" alt="Rebooter" />
        Rebooter
        <app-icon name="chevron-forward"></app-icon>
      </ion-card-title>
    </ion-card-header>
  </ion-card>

  <ion-card class="device-card" (click)="checkPlatform(deviceTypes.CSSmartOutlet, true)">
    <ion-card-header>
      <ion-card-title>
        <img src="../../assets/device-types/smart-outlet.png" alt="SmartOutlet" />
        Smart Outlet²
        <app-icon name="chevron-forward"></app-icon>
      </ion-card-title>
    </ion-card-header>
  </ion-card>

  <ion-card class="device-card" (click)="checkPlatform(deviceTypes.CSInWallOutlet, false)">
    <ion-card-header>
      <ion-card-title>
        <img src="../../assets/device-types/in-wall-outlet.png" alt="In-Wall Outlet" />
        In-Wall Outlet
        <app-icon name="chevron-forward"></app-icon>
      </ion-card-title>
    </ion-card-header>
  </ion-card>

  <ion-card class="device-card" (click)="checkPlatform(listType, false)" *ngIf="isIosProvisioning">
    <ion-card-header>
      <ion-card-title>
        <img src="https://upload.wikimedia.org/wikipedia/commons/c/cc/Apple_HomeKit_logo.svg" alt="HK" />
        I have existing HomeKit devices
        <app-icon name="chevron-forward"></app-icon>
      </ion-card-title>
    </ion-card-header>
  </ion-card>
</ion-content>
