import { LoadingMessage } from './../../../assets/strings';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Device, DeviceService } from '@connectsense/iot8020-library';

import { UsageInterval, intervals } from '../../models/usage-interval';
import { UsageService } from '../services/usage.service';

@Component({
  selector: 'app-device-usage',
  templateUrl: './device-usage.component.html',
  styleUrls: ['../usage.scss', './device-usage.component.scss'],
  providers: [ UsageService ]
})
export class DeviceUsageComponent implements OnInit {
  readonly intervals: UsageInterval[] = intervals;
  LoadingMessage = LoadingMessage;
  deviceId: string;
  device: Device;
  loading = true;
  outlet: string;
  selectedInterval: UsageInterval = intervals[0];
  data: { x: number, y: number }[] = [];
  averageUsage: number;
  averageCost: number;

  constructor(
    private deviceService: DeviceService,
    private usageService: UsageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  get inDeviceDetail() {
    return this.router.url.includes('devices');
  }

  ngOnInit() {
    this.route.paramMap
    .subscribe((params) => {
      this.deviceId = params.get('deviceId');
      this.outlet = params.get('outlet').replace('outlet', '');

      if (!this.inDeviceDetail) { this.getDevice(); }
      this.getDeviceUsage(this.deviceId, this.outlet, this.selectedInterval);
    });
  }

  getDevice(): void {
    this.deviceService.getDevice(this.deviceId).subscribe((device: Device) => {
      this.device = device;
    });
  }

  onTabChange(event): void {
    this.selectedInterval = event.detail.value as UsageInterval;
    this.getDeviceUsage(this.deviceId, this.outlet, this.selectedInterval);
  }

  getDeviceUsage(deviceId: string, outlet: string, interval: UsageInterval): void {
    this.usageService.getDeviceUsage(deviceId, outlet, interval.label)
    .subscribe(({ data, averageCost, averageUsage }) => {
      this.loading = false;
      this.data = data.reverse();
      this.averageCost = averageCost;
      this.averageUsage = averageUsage / 1000;
    });
  }

}
