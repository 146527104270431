import { Home } from 'app/models/home';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterRoomlessHomes'
})
export class FilterRoomlessHomesPipe implements PipeTransform {

  transform(homes: Home[]): Home[] {
    return homes.filter((home) => !!home.rooms.length);
  }

}
