import { Home } from 'app/models/home';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-room-picker',
  templateUrl: './room-picker.component.html',
  styleUrls: ['./room-picker.component.scss']
})
export class RoomPickerComponent implements OnInit {
  @Input() homes: Home[];
  @Input() value: string;
  @Output() valueChange = new EventEmitter<string>();
  @Output() labelChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  isSelected(id: string): boolean {
    return this.value === id;
  }

  selectRoom(id: string, label: string) {
    this.valueChange.emit(id);
    this.labelChange.emit(label);
  }

}
