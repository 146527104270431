<ion-button *ngIf="isiOS && isNewSchedule">
  <span>Done</span>
</ion-button>

<ion-button class="ios-new" *ngIf="isiOS && !isNewSchedule">
  <ion-icon slot="icon-only" name="chevron-back"></ion-icon>
  <span class="button-text">Back</span>
</ion-button>

<ion-button
  *ngIf="!isiOS && isNewSchedule"
  [disabled]="!saveButtonEnabled"
>
  <span>Save</span>
</ion-button>

<ion-button
  *ngIf="!isiOS && !isNewSchedule"
  [disabled]="!saveButtonEnabled"
>
  <span>Update</span>
</ion-button>
