<ion-grid>
  <ion-row>
    <ion-col class="power-tile" *ngFor="let tile of tiles[device.deviceTypeId]">
      <div class="power-label">
        <ng-container *ngIf="tile.attribute === 'stateTimeValue'; else iconTile">
          <span class="power-img">{{outletState ? 'ON' : 'OFF'}}</span>
        </ng-container>
        <ng-template #iconTile>
          <app-icon class="power-img" [name]="tile.icon"></app-icon>
        </ng-template>
        <span>{{getTileLabel(tile.label)}}</span>
      </div>
      <div>
        <span class="power-value">{{getTileAttribute(tile.attribute) | number:'1.0-1'}}</span>
      </div>
      <div class="power-label power-unit-container">
        <span class="power-unit">{{getTileUnit(tile.unit)}}</span>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
<small *ngIf="occupied && !amps && device.isCSSmartOutlet()" class="power-legend">
  Small electronics and low wattage devices may not accurately register consumption
</small>
