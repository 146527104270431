import { ErrorMessage } from './../../assets/strings';
import { DeviceTypes } from '@connectsense/iot8020-library';
import { IonModal } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';
import { HomeKitRegistration } from 'plugins/homekit-reg';
import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { HomeKitProvisioning } from '@homekit-prov';
import { HomekitDevice } from 'app/models/homekitDevice';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ErrorTitle } from 'assets/strings';

@Component({
  selector: 'app-homekit-device-list',
  templateUrl: './homekit-device-list.component.html',
  styleUrls: ['./homekit-device-list.component.scss']
})
export class HomekitDeviceListComponent implements OnInit {
  @ViewChild('devicePickerModal') devicePickerModal: IonModal;

  deviceTypes = DeviceTypes;
  unregisteredHkDevices: HomekitDevice[] = [];
  hkRooms: { [roomName: string]: HomekitDevice[] } = {};
  loading = true;
  hasRequestedHomeAccess = false;
  hasGrantedHomeAccess = false;
  isUnregisteredUser = false;

  constructor(
    private ionicDialogService: IonicDialogService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap
    .subscribe(params => {
      if (params.get('isUnregisteredUser')) {
        this.isUnregisteredUser = true;
      }
      this.checkHomeKitRequested();
    });
  }

  showDevicePicker() {
    this.devicePickerModal.present();
  }

  startHomeKitSetup(deviceType: string) {
    this.devicePickerModal.dismiss();

    const onlyWacProvisioning = true;
    const queryParams = {
      deviceType,
      onlyWacProvisioning
    };
    this.router.navigate(['homekit-setup'], { queryParams });
  }

  async checkHomeKitRequested() {
    const { hasRequestedHomeAccess } = await HomeKitProvisioning.hasRequestedHomeAccess();
    this.hasRequestedHomeAccess = hasRequestedHomeAccess;

    if (hasRequestedHomeAccess) {
      return this.checkHomeKitPermissions();
    }

    this.loading = false;
  }

  async checkHomeKitPermissions() {
    const { didGrantAccess } = await HomeKitProvisioning.getAccessStatus();
    this.hasRequestedHomeAccess = true;
    this.hasGrantedHomeAccess = didGrantAccess;

    if (didGrantAccess) {
      return this.getHomeKitDevices();
    }

    this.loading = false;
  }

  async getHomeKitDevices() {
    this.loading = true;
    try {
      const { devices } = await HomeKitRegistration.getHomeKitDeviceList();
      let hkDevices: HomekitDevice[] = JSON.parse(devices);
      this.unregisteredHkDevices = hkDevices.filter(hkDevice => !hkDevice.isRegistered);
      this.unregisteredHkDevices.sort(this.sortOnlineFirst);

      this.getHomeKitRooms(this.unregisteredHkDevices);
      this.loading = false;
    } catch (e) {
      this.showErrorDialog(ErrorTitle.Error, e.message);
      this.loading = false;
    }
  }

  getHomeKitRooms(hkDevices: HomekitDevice[]) {
    this.hkRooms = {};
    for (let device of hkDevices) {
      if (device.room in this.hkRooms) {
        this.hkRooms[device.room].push(device)
      } else {
        this.hkRooms[device.room] = [device]
      }
    }
  }

  sortOnlineFirst(el1: HomekitDevice, el2: HomekitDevice) {
    if (el1.isReachable && !el2.isReachable) {
      return -1;
    }
    if (!el1.isReachable && el2.isReachable) {
      return 1;
    }
    return 0;
  }

  startCloudConnection(hkDevice: HomekitDevice) {
    if (!hkDevice.isReachable) {
      return this.showErrorDialog(
        ErrorTitle.HomeKitDeviceUnreachable,
        ErrorMessage.HomeKitDeviceUnreachable
      );
    }

    const deviceType = hkDevice.deviceType;
    const serialNumber = hkDevice.serialNumber;
    const uuid = hkDevice.uuid;
    const queryParams = {
      deviceType,
      serialNumber,
      uuid
    };
    this.router.navigate(['homekit-setup'], { queryParams });
  }

  async openSettings() {
    try {
      await HomeKitProvisioning.allowFromSettings();
    } catch (e) {
      this.showErrorDialog(ErrorTitle.Error, e.message);
    }
  }

  async openHomeApp() {
    try {
      await HomeKitRegistration.openHomeApp();
    } catch (e) {
      this.showErrorDialog(ErrorTitle.Error, e.message);
    }
  }

  showErrorDialog(title: string, message: string) {
    return this.ionicDialogService.generic(title, message);
  }

  ionViewWillLeave() {
    this.unregisteredHkDevices = [];
    this.loading = true;
    this.hasRequestedHomeAccess = false;
    this.hasGrantedHomeAccess = false;
    this.isUnregisteredUser = false;
  }
}
