import { Injectable } from '@angular/core'

import { ApiService } from '@connectsense/iot8020-library';

import { Rule } from '../rule';

@Injectable()
export class RuleService {

  constructor(
    private api: ApiService
  ) {}

  getRule(ruleId: string): Promise<Rule> {
    return this.api.getAsPromise(`/rules/${ruleId}`)
      .then(response => response['rule'])
      .then(Rule.fromJSON);
  }

  getRulesForDevice(deviceId: string): Promise<Rule[]> {
    return this.api.getAsPromise(`/rules`)
      .then(response => response['rules'])
      .then(userRules => userRules.filter(rule => rule.devices.indexOf(deviceId) !== -1))
      .then(rules => rules.map(Rule.fromJSON));
  }

  addRuleForDevice(rule: Rule): Promise<boolean> {
    return this.api.postAsPromise('/rules', rule)
      .then(response => response['rule'])
      .then(Rule.fromJSON)
      .then(savedRule => !!savedRule.ruleId);
  }

  updateRule(rule: Rule): Promise<boolean> {
    return this.api.putAsPromise(`/rules/${rule.ruleId}`, rule)
      .then(response => response['rule'])
      .then(Rule.fromJSON)
      .then(savedRule => !!savedRule.ruleId);
  }

  deleteRule(ruleId: string): Promise<boolean> {
    return this.api.deleteAsPromise(`/rules/${ruleId}`)
      .then(response => JSON.stringify(response) === '{}');
  }
}
