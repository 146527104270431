<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button [defaultHref]="inDeviceDetail ? '/devices/' + deviceId : '/usage'"></ion-back-button>
  </ion-buttons>

  <ion-title>Usage</ion-title>
</ion-toolbar>

<ion-content>
  <app-loading-indicator [message]="LoadingMessage.Usage" [condition]="loading"></app-loading-indicator>

  <ng-container *ngIf="!loading">
    <ion-toolbar class="ion-no-padding">
      <ion-segment [value]="selectedInterval" (ionChange)="onTabChange($event)">
        <ion-segment-button *ngFor="let interval of intervals" [value]="interval">
          <ion-label>{{interval.label.toUpperCase()}}</ion-label>
        </ion-segment-button>
      </ion-segment>
    </ion-toolbar>

    <div class="container title-container" *ngIf="!inDeviceDetail">
      <h1 class="outlet-title">{{device?.data['outlet' + outlet + '_name']}}</h1>
    </div>

    <div class="chart-container">
      <app-usage-chart
        [data]="data"
        [interval]="selectedInterval"
        *ngIf="deviceId && selectedInterval"
      ></app-usage-chart>
    </div>

    <ion-item-divider class="subheader">Summary</ion-item-divider>

    <div class="container">
      <h3 class="usage-subheader">Average power consumption per {{selectedInterval.averageLabel}}:</h3>
      <div class="summary-value">{{averageUsage | number}} KWH</div>

      <h3 class="usage-subheader">Average cost per {{selectedInterval.averageLabel}}:</h3>
      <div class="summary-value">{{averageCost | currency}}</div>
    </div>
  </ng-container>
</ion-content>
