import { MediaMatcher } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemingService {
  private renderer: Renderer2;
  private prefersDark: MediaQueryList;
  public isDarkTheme: boolean;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    rendererFactory: RendererFactory2,
    mediaMatcher: MediaMatcher
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.prefersDark = mediaMatcher.matchMedia('(prefers-color-scheme: dark)');
  }

  checkThemePreference() {
    this.toggleDarkTheme(this.prefersDark.matches);
    this.prefersDark.addEventListener('change', (mediaQuery) => {
      this.toggleDarkTheme(mediaQuery.matches);
    });
    if (window.navigator.userAgent.includes('AndroidDarkMode')) {
      this.toggleDarkTheme(true);
    }
  }

  toggleDarkTheme(shouldAdd: boolean): void {
    const darkClass = 'dark';
    if (shouldAdd) {
      this.renderer.addClass(this.document.body, darkClass);
      this.isDarkTheme = true;
    } else {
      this.renderer.removeClass(this.document.body, darkClass);
      this.isDarkTheme = false;
    }
  }

  toggleQrScan(shouldAdd: boolean): void {
    const qrClass = 'qr-scan';
    const statusBarTarget = '.status-bar';
    const statusBlockClass = `status-block${this.isDarkTheme ? '-dark' : ''}`;

    if (shouldAdd) {
      this.renderer.addClass(this.document.body, qrClass);
      this.renderer.addClass(this.document.querySelector(statusBarTarget), statusBlockClass)
    } else {
      this.renderer.removeClass(this.document.body, qrClass);
      this.renderer.removeClass(this.document.querySelector(statusBarTarget), statusBlockClass)
    }
  }
}
