import { ApiService } from 'app/services/api.service';
import { Injectable }          from '@angular/core'
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { Auth } from '@auth';
import { Capacitor } from '@capacitor/core';

import { AuthService, ClientStorageService, ClientStorageKey } from '@connectsense/iot8020-library';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private apiService: ApiService,
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    if (Capacitor.isPluginAvailable('Auth')) {
      const { hasValidCredentials } = await Auth.hasValidCredentials();

      if (hasValidCredentials) {
        this.checkForExistingUser();
        return true;
      }
    } else {
      if (this.auth.isAuthenticated()) {
        this.checkForExistingUser();
        return true;
      }
    }

    this.router.navigate(['/login'])
  }

  checkForExistingUser() {
    const userInfo = ClientStorageService.get(ClientStorageKey.UserProfile);
    if (userInfo) {
      return;
    }

    this.getUserInfo();
  }

  async getUserInfo() {
    this.apiService.getAsStream(`/users`).subscribe(info => {
      ClientStorageService.put(ClientStorageKey.UserProfile, info);
    });
  }
}
