<div *ngFor="let dayDate of groupedData">
  <table class="device-log-date-table">
    <tr class="header-row">
      <th colspan="3">{{ dayDate[0].date | date: 'longDate' }}</th>
    </tr>
  </table>

  <table class="device-log-content-table temp-humidity" [ngClass]="{
         'water': device.isCSWaterSensor(),
         'temp-humidity': device.isCSTempHumSensor()
         }">
    <tr *ngFor="let d of dayDate">
      <td class="device-log-date">{{ d.date | date: 'mediumTime' }}</td>
      <td class="device-log-temperature" *ngIf="device.isCSTempHumSensor()">{{ d.temperature }}°F</td>
      <td class="device-log-humidity" *ngIf="device.isCSTempHumSensor()">{{ d.humidity }}%</td>
      <td class="device-log-water" *ngIf="device.isCSWaterSensor()">{{ d.waterDetected ? 'Yes' : 'No' }}</td>
    </tr>
  </table>
</div>
