import { ErrorMessage, LoadingMessage, DialogTitle, DialogMessage, ScreenTitle, DialogButtonText } from './../../assets/strings';
import { Auth } from '@auth';
import { Observable } from 'rxjs/Rx';
import { CopilotBridge } from '@copilot-bridge';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { HomeKitRegistration } from 'plugins/homekit-reg';
import { environment } from './../../environments/environment';
import { DeviceService, DeviceTypes } from '@connectsense/iot8020-library';
import { HomeKitProvisioning } from '@homekit-prov';
import { ActivatedRoute, Router } from '@angular/router';
import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { Component, OnInit, Input, NgZone } from '@angular/core';
import { ErrorTitle } from 'assets/strings';

@Component({
  selector: 'app-homekit-setup',
  templateUrl: './homekit-setup.component.html',
  styleUrls: ['./homekit-setup.component.scss']
})
export class HomekitSetupComponent implements OnInit {
  // Step 1
  @Input() selectedDeviceType = 'smartOutlet';
  @Input() shouldSkipHomeKit = false;

  // Step 2
  provisionedSerialNumber: string;
  provisionedUuid: string;

  // Step 3
  registrationStepMessage: string;
  startTimeMilliseconds: number;

  // Step 4
  deviceName: string;
  roomName: string;
  outletAName: string;
  outletBName: string;
  SUCCESS_TIMEOUT = 3000; // Display success for 3 seconds before routing

  firstStep = 0;
  step = this.firstStep;
  title = ScreenTitle.HomeKitSetup;
  hasStartedSetup = false;
  expiration: number;
  session;
  onlyWacProvisioning = false;
  alreadyRegistered = false;
  loading = true;
  hasRequestedHomeAccess = false;
  hasGrantedHomeAccess = false;

  deviceTypes = {
    smartOutlet: {
      name: 'Smart Outlet²',
      image: 'device-types/smart-outlet.png',
      successImage: 'device-types/smart-outlet.png',
      prefix: 'CS-SO-2',
      activationInstructions: `
        press and hold both buttons for 5 seconds
        until the lights on the front begin to flash.
      `
    },
    inWallOutlet: {
      name: 'In-Wall Outlet',
      image: 'device-types/in-wall-outlet.gif',
      successImage: 'device-types/in-wall-outlet.png',
      prefix: 'CS-IWO',
      activationInstructions: `
        tap between the power outlet icons three times and hold for 5 seconds
        until the light on the front begins to flash.
      `
    },
  };

  steps = {
    0: () => {},
    1: () => {},
    2: async () => {
      this.hasStartedSetup = true;
      await this.startHomeKitProvisioning();
    },
    3: async () => {
      this.getRegistrationParams();
    },
    4: () => {
      this.successfulSetup();
    },
  }

  constructor(
    private ionicDialogService: IonicDialogService,
    private route: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceService,
    private ngZone: NgZone,
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap
    .subscribe(params => {
      this.selectedDeviceType = params.get('deviceType');
      if (params.get('onlyWacProvisioning')) {
        this.onlyWacProvisioning = true;
      }
      if (params.get('alreadyRegistered')) {
        this.alreadyRegistered = true;
        this.onlyWacProvisioning = true;
      }
      if (params.get('serialNumber') && params.get('uuid')) {
        this.provisionedSerialNumber = params.get('serialNumber');
        this.provisionedUuid = params.get('uuid');
        this.step = 2;
        this.hasStartedSetup = true;
      }
      this.checkHomeKitRequested();
    });
  }

  // Global
  forward() {
    this.ngZone.run(() => { this.step++; });
    this.go();
  }

  go() {
    const titles = {
      0: ScreenTitle.HomeKitAccess,
      1: ScreenTitle.HomeKitReady,
      2: ScreenTitle.HomeKitConnect,
      3: ScreenTitle.HomeKitRegistering,
      4: ScreenTitle.Success
    };

    if (this.step === 3 && this.onlyWacProvisioning) {
      this.step++;
    }

    this.steps[this.step]?.apply(this);
    this.title = titles[this.step];
  }

  async checkHomeKitRequested() {
    const { hasRequestedHomeAccess } = await HomeKitProvisioning.hasRequestedHomeAccess();
    this.hasRequestedHomeAccess = hasRequestedHomeAccess;

    if (hasRequestedHomeAccess) {
      return this.checkHomeKitPermissions();
    }

    this.loading = false;
  }

  async checkHomeKitPermissions() {
    const { didGrantAccess } = await HomeKitProvisioning.getAccessStatus();
    this.hasRequestedHomeAccess = true;
    this.hasGrantedHomeAccess = didGrantAccess;
    this.loading = false;

    if (didGrantAccess) {
      return this.forward();
    }
  }

  async openSettings() {
    await HomeKitProvisioning.allowFromSettings();
  }

  back() {
    if (this.step - 1 <= this.firstStep) {
      this.returnToList();
    } else {
      this.step--;
      this.hasStartedSetup = false;
      this.go();
    }
  }

  returnToList() {
    if (this.onlyWacProvisioning) {
      const isUnregisteredUser = true;
      const queryParams = { isUnregisteredUser }
      const returnUrl = this.alreadyRegistered ? '/devices' : '/homekit-list';
      this.router.navigate([returnUrl], { queryParams });
    } else {
      this.router.navigate(['/devices'], { replaceUrl: true });
    }
  }

  getSelectedDeviceProperty(property: string): string {
    return this.deviceTypes[this.selectedDeviceType] ? this.deviceTypes[this.selectedDeviceType][property] : '';
  }

  showErrorDialog(title: string, message: string) {
    return this.ionicDialogService.confirm(title, message, [{
      text: DialogButtonText.OK,
      role: 'confirm'
    }]).subscribe(() => {
      this.returnToList();
    });
  }

  // Step 1
  public showHelpDialog() {
    const body = `
      <p>
        Your device needs to be plugged-in and in pairing mode before you can start setup.
      </p>

      <p>
        To enter pairing mode,
        ${this.getSelectedDeviceProperty('activationInstructions')}
      </p>
    `;

    this.ionicDialogService.generic(
      '',
      body
    );
  }

  // Step 2
  async startHomeKitProvisioning() {
    try {
      const result = await HomeKitProvisioning.start({ deviceType: this.selectedDeviceType });
      if (result.serialNumber && result.uuid) {
        this.provisionedSerialNumber = result.serialNumber;
        this.provisionedUuid = result.uuid;
        this.forward();
      } else {
        this.showErrorDialog(ErrorTitle.Error, ErrorMessage.HomeKitResultFail);
      }
    } catch (e) {
      this.showErrorDialog(ErrorTitle.Error, e.message);
      this.back();
    }
  }

  // Step 3
  async getRegistrationParams() {
    this.registrationStepMessage = LoadingMessage.GetClaimCode;
    const idToken = await this.getIdToken();
    const accountId = this.getAccountId(idToken);
    const isStaging = !environment.production;

    this.deviceService.getClaimCode().subscribe(async (claimCode: string) => {
      this.startClaimCodeSession(claimCode);
      this.startHomeKitRegistration(this.provisionedUuid, accountId, claimCode, isStaging);
    }, () => {
      this.showErrorDialog(ErrorTitle.Error, ErrorMessage.ClaimCodeFetchFail);
    });
  }

  async getIdToken() {
    try {
      const { idToken } = await Auth.getCredentials();
      return idToken as string;
    } catch (e) {
      this.showErrorDialog(ErrorTitle.CredentialsFetchFail, ErrorMessage.CredentialsFetchFail);
    }
  }

  getAccountId(idToken: string): string {
    const decodedToken: JwtPayload = jwtDecode(idToken);
    const token: string = decodedToken.sub;
    return token.substring(token.indexOf('|') + 1).replace('amzn1.account.', '').replace(/\./g, '');
  }

  startClaimCodeSession(claimCode: string): void {
    if (!this.expiration) {
      const { exp: expiration } = jwtDecode(claimCode);
      this.expiration = expiration - 300;
      // 5 minutes off to avoid registration near expiration
    }

    const timeUntilExpiration = (this.expiration * 1000) - Date.now();

    this.session = Observable.timer(timeUntilExpiration)
    .takeWhile(() => this.step === 1)
    .switchMap(() => {
      return this.ionicDialogService.confirm(
        DialogTitle.SessionExpired,
        DialogMessage.SessionExpired,
        [
          {
            text: DialogButtonText.OK,
            role: 'confirm'
          }
        ]
      );
    }).subscribe(() => {
      this.router.navigate(['/devices'], { replaceUrl: true });
    });
  }

  async startHomeKitRegistration(uuid: string, accountId: string, claimCode: string, isStaging: boolean) {
    this.startTimeMilliseconds = Date.now();
    const thingId = this.getThingId(this.provisionedSerialNumber, this.selectedDeviceType);
    CopilotBridge.logIoT8020Registration({
      registrationStage: 'started',
      deviceType: this.selectedDeviceType,
      deviceId: thingId,
      timeElapsed: ''
    });

    try {
      await HomeKitRegistration.start(
        {isStaging, claimCode, accountId, uuid},
        (result, error) => {
          if (error) {
            this.showErrorDialog(ErrorTitle.Error, error);
          }
          if (result.registrationStep === 'Completed') {
            this.deviceName = result.deviceName;
            this.roomName = result.roomName;
            this.outletAName = result.outletAName;
            this.outletBName = result.outletBName;
            this.forward();
          } else {
            this.ngZone.run(() => {
              this.registrationStepMessage = result.registrationStep;
            });
          }
        }
      );
    } catch (e) {
      this.showErrorDialog(ErrorTitle.Error, e.message);
    }
  }

  // Step 4
  successfulSetup(): void {
    if (this.onlyWacProvisioning) {
      setTimeout(() => {
        const successUrl = this.alreadyRegistered ? '/devices' : '/homekit-list';
        return this.router.navigate([successUrl], { queryParams: { isUnregisteredUser: true } });
      }, this.SUCCESS_TIMEOUT);
    } else {
      const thingId = this.getThingId(this.provisionedSerialNumber, this.selectedDeviceType);
      CopilotBridge.logThingDiscovery({thingId: thingId});

      const elapsedTimeSeconds = Math.round((Date.now() - this.startTimeMilliseconds) / 1000);
      CopilotBridge.logIoT8020Registration({
        registrationStage: 'completed',
        deviceType: this.selectedDeviceType,
        deviceId: thingId,
        timeElapsed: String(elapsedTimeSeconds)
      });
      CopilotBridge.logOnBoardingEnd();

      setTimeout(() => {
        this.router.navigate(['/devices'], { queryParams: {
          deviceName: this.deviceName,
          pendingDevice: this.provisionedSerialNumber,
          roomName: this.roomName,
          outletAName: this.outletAName,
          outletBName: this.outletBName
        } });
      }, this.SUCCESS_TIMEOUT);
    }
  }

  getThingPrefix(deviceType?: string): string {
    let prefix: string;
    if (deviceType === DeviceTypes.CSInWallOutlet) {
      prefix = 'CS-IWO-';
    } else if (deviceType === DeviceTypes.CSSmartOutlet) {
      prefix = 'CS-SO2-';
    }

    return prefix;
  }

  getThingId(serialNumber: string, deviceType?: string): string {
    const prefix = this.getThingPrefix(deviceType);
    return prefix + serialNumber
  }

  ionViewWillLeave() {
    this.step = this.firstStep;
    this.hasStartedSetup = false;
    this.onlyWacProvisioning = false;
    this.loading = true;
    this.alreadyRegistered = false;
    this.session?.unsubscribe();
  }

  startBlufiProvisioning() {
    // temporarily route to web SO2 prov for non-hk
    if (this.selectedDeviceType === 'smartOutlet') {
      this.startSo2WebProvisioning();
    } else {
      this.router.navigate(['blufi-setup']);
    }
  }

  startSo2WebProvisioning() {
    this.deviceService.getClaimCode().subscribe(async (claimCode: string) => {
      const deviceType = DeviceTypes.CSSmartOutlet;
      const queryParams = {
        claimCode,
        deviceType
      };

      this.router.navigate(['device-setup'], { queryParams });
    }, () => {
      this.showErrorDialog(ErrorTitle.Error, ErrorMessage.ClaimCodeFetchFail);
    });
  }
}
