import { CopilotEvent } from './../../app/models/copilot-event';
export interface CopilotBridgePlugin {
  sessionStarted(tokens: LogSessionTokens): void;
  sessionEnded(): void;
  logEvent(tokens: LogEventTokens): void;
  logOutletToggle(tokens: LogToggleTokens): void;
  logReboot(tokens: LogRebootTokens): void;
  logScreenLoad(tokens: LogScreenLoadTokens): void;
  logOnBoardingStart(): void;
  logOnBoardingEnd(): void;
  logThingDiscovery(tokens: ThingIdTokens): void;
  logThingConnection(tokens: ThingIdTokens): void;
  logThingConnectionFailure(tokens: ConnectionFailureTokens): void;
  logIoT8020Registration(tokens: IoT8020RegistrationTokens): void;
  logFirmwareVersion(tokens: FirmwareVersionTokens): void;
}

export interface LogSessionTokens {
  accountId: string;
}

export interface LogEventTokens {
  eventName: CopilotEvent;
}

export interface LogToggleTokens {
  deviceId: string;
}

export interface LogRebootTokens {
  deviceId: string;
}

export interface LogScreenLoadTokens {
  screenName: string;
}

export interface ThingIdTokens {
  thingId: string;
}

export interface ConnectionFailureTokens {
  reason: string;
}

export interface IoT8020RegistrationTokens {
  registrationStage: String;
  deviceType: String;
  deviceId: String;
  timeElapsed: String;
}

export interface FirmwareVersionTokens {
  deviceTypeVersion: String;
}
