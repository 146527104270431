import { RebootAddressesComponent } from './reboot-addresses/reboot-addresses.component';
import { RebootTableComponent } from './reboot-table/reboot-table.component';
import { BlufiSetupComponent } from './blufi-setup/blufi-setup.component';
import { HomekitDeviceListComponent } from './homekit-device-list/homekit-device-list.component';
import { HomekitSetupComponent } from './homekit-setup/homekit-setup.component';
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { IoT8020ContactFormComponent } from '@connectsense/iot8020-library';

import { AuthGuard } from './services/auth-guard.service'
import { LoginGuard } from './services/login-guard'

import { DeviceListComponent } from './device-list/device-list.component'
import { DeviceDetailComponent } from './device-detail/device-detail.component'
import { LoginComponent } from './login/login.component'
import { SupportComponent } from './support/support.component'
import { DeviceDetailSettingsComponent } from './device-detail-settings/device-detail-settings.component'
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { OutletSettingsComponent } from './outlet-settings/outlet-settings.component';
import { OutletSetupComponent } from './outlet-setup/outlet-setup.component';
import { DeviceTypePickerComponent } from './device-type-picker/device-type-picker.component';
import { DeviceUsageComponent } from './usage/device-usage/device-usage.component';
import { DeviceScheduleListComponent } from './schedules/device-schedule-list/device-schedule-list.component';
import { ScheduleDetailComponent } from './schedules/schedule-detail/schedule-detail.component';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard], data: { screenName: 'Login' } },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'devices', component: DeviceListComponent, canActivate: [AuthGuard], data: { screenName: 'DeviceList' }},
  { path: 'devices/add', component: DeviceTypePickerComponent, canActivate: [AuthGuard] },
  { path: 'devices/:deviceId', component: DeviceDetailComponent, canActivate: [AuthGuard] },
  { path: 'devices/:deviceId/schedules', component: DeviceScheduleListComponent, canActivate: [AuthGuard] },
  { path: 'devices/:deviceId/schedules/:id', component: ScheduleDetailComponent, canActivate: [AuthGuard] },
  { path: 'devices/:deviceId/outlet-settings', component: OutletSettingsComponent, canActivate: [AuthGuard] },
  { path: 'devices/:deviceId/settings', component: DeviceDetailSettingsComponent, canActivate: [AuthGuard] },
  { path: 'devices/:deviceId/usage/device/:deviceId/:outlet', component: DeviceUsageComponent, canActivate: [AuthGuard] },
  { path: 'devices/:deviceId/outlet-setup', component: OutletSetupComponent, canActivate: [AuthGuard], children: [
    { path: 'schedules', component: DeviceScheduleListComponent },
    { path: 'schedules/:id', component: ScheduleDetailComponent }
  ] },
  { path: 'devices/:deviceId/reboot-history', component: RebootTableComponent, canActivate: [AuthGuard]  },
  { path: 'devices/:deviceId/reboot-addresses', component: RebootAddressesComponent },
  { path: 'contact-form', component: IoT8020ContactFormComponent, canActivate: [AuthGuard] },
  { path: 'support', component: SupportComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: 'password-reset', component: PasswordResetComponent, data: { screenName: 'ForgotPassword' } },
  { path: 'schedules', loadChildren: () => import('app/schedules/schedules.module').then(m => m.SchedulesModule) },
  { path: 'home', loadChildren: () => import('app/home/home.module').then(m => m.HomeModule) },
  { path: 'add-device', component: DeviceTypePickerComponent, canActivate: [AuthGuard], data: { screenName: 'DeviceTypeSelection' } },
  { path: 'usage', loadChildren: () => import('app/usage/usage.module').then(m => m.UsageModule), canActivate: [AuthGuard] },
  {
    path: 'device-setup',
    loadChildren: () => import('./device-setup/device-setup.module').then(m => m.DeviceSetupModule),
    canActivate: [AuthGuard]
  },
  { path: 'homekit-setup', component: HomekitSetupComponent },
  { path: 'blufi-setup', component: BlufiSetupComponent },
  { path: 'homekit-list', component: HomekitDeviceListComponent },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
  },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
