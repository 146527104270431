import { ErrorTitle, LoadingMessage, ErrorMessage } from './../../assets/strings';
import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { HomekitDevice } from './../models/homekitDevice';
import { Capacitor } from '@capacitor/core';
import { NativeFeatureNotifierService } from './../native-feature-notifier.service';
import { IwoProvisioning } from '@iwo-prov';
import { Router } from '@angular/router';
import { Component } from '@angular/core';

import { DeviceService, DeviceTypes, CSInWallOutlet } from '@connectsense/iot8020-library';

import { of } from 'rxjs';

import jwtDecode, { JwtPayload } from 'jwt-decode';
import { CopilotBridge } from '@copilot-bridge';

@Component({
  selector: 'app-device-type-picker',
  templateUrl: './device-type-picker.component.html',
  styleUrls: ['./device-type-picker.component.scss']
})
export class DeviceTypePickerComponent {
  LoadingMessage = LoadingMessage;
  deviceTypes = DeviceTypes;
  isIosProvisioning = Capacitor.getPlatform() === 'ios';
  listType = 'LIST';
  unregisteredHkDevices: HomekitDevice[] = [];

  constructor(
    private deviceService: DeviceService,
    private router: Router,
    private nativeNotifier: NativeFeatureNotifierService,
    private ionicDialogService: IonicDialogService,
  ) { }

  checkPlatform(deviceType: string, needsClaimCode: boolean): void {
    if (deviceType === DeviceTypes.CSInWallOutlet && Capacitor.getPlatform() === 'web') {
      return this.nativeNotifier.notify();
    }

    this.ionicDialogService.loading(LoadingMessage.StartProvisioning).subscribe(() => {
      this.startProvisioning(deviceType, needsClaimCode);
    });
  }

  startProvisioning(deviceType: string, needsClaimCode: boolean) {
    if (Capacitor.getPlatform() === 'ios' &&
      (deviceType === DeviceTypes.CSInWallOutlet || deviceType === DeviceTypes.CSSmartOutlet)
    ) {
      this.startHomeKitProvisioning(deviceType)
      return;
    }

    if (deviceType === this.listType) {
      this.router.navigateByUrl('/homekit-list');
      return;
    }

    CopilotBridge.logOnBoardingStart();

    if (deviceType === DeviceTypes.CSInWallOutlet) {
      this.ionicDialogService.dismissLoading();
      window.addEventListener('startConfiguration', (event: any) => {
        this.startConfiguration(event.deviceName);
      });

      const idToken = this.getIdToken();
      const accountId = this.getAccountId(idToken);
      return IwoProvisioning.start({
        accountId: accountId,
        idToken: idToken
      });
    }

    const request = needsClaimCode ? this.deviceService.getClaimCode() : of('');

    request.subscribe((claimCode: string) => {
      const queryParams = {
        claimCode,
        deviceType
      };

      this.router.navigate(['device-setup'], { queryParams });
    }, () => {
      this.showErrorDialog(ErrorTitle.Error, ErrorMessage.GenericTryAgain);
    });
  }

  showErrorDialog(title: string, message: string) {
    return this.ionicDialogService.generic(title, message);
  }

  sortOnlineFirst(el1: HomekitDevice, el2: HomekitDevice) {
    if (el1.isReachable && !el2.isReachable) {
      return -1;
    }
    if (!el1.isReachable && el2.isReachable) {
      return 1;
    }
    return 0;
  }

  startHomeKitProvisioning(deviceType: string) {
    CopilotBridge.logOnBoardingStart();
    const queryParams = {
      deviceType
    };
    this.router.navigate(['homekit-setup'], { queryParams });
  }

  startCloudConnection(hkDevice: HomekitDevice) {
    if (!hkDevice.isReachable) {
      return this.showErrorDialog(
        ErrorTitle.HomeKitDeviceUnreachable,
        ErrorMessage.HomeKitDeviceUnreachable,
      );
    }

    const { deviceType, serialNumber, uuid } = hkDevice;
    const queryParams = {
      deviceType,
      serialNumber,
      uuid
    };
    this.router.navigate(['homekit-setup'], { queryParams });
  }

  getIdToken(): string {
    return window.localStorage.getItem('id_token');
  }

  getAccountId(idToken: string): string {
    const decodedToken: JwtPayload = jwtDecode(idToken);
    const token: string = decodedToken.sub;
    return token.substring(token.indexOf('|') + 1).replace('amzn1.account.', '').replace(/\./g, '');
  }

  startConfiguration(deviceName: string) {
    this.router.navigateByUrl(`/devices?pendingDevice=${deviceName}`);
  }

  ionViewWillLeave(): void {
    this.ionicDialogService.dismissLoading();
  }
}
