import { AuthService, ClientStorageKey, ClientStorageService } from '@connectsense/iot8020-library';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { CopilotBridge } from '@copilot-bridge';
import { Capacitor } from '@capacitor/core';
import { Auth } from '@auth';

@Component({
  selector: 'app-logout',
  template: '',
})
export class LogoutComponent {
  constructor(private router: Router,
              private authService: AuthService) { }

  ionViewWillEnter() {
    /**
     * The authenticated state changes from within a child component causing angular to think
     * the app component could be in an unstable state, as the change wasn't top-down.
     * The faux promise bypasses this error check and maintains the app stable.
     * https://blog.angularindepth.com/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error-e3fd9ce7dbb4
     */
    Promise.resolve(null).then(() => {
      if (Capacitor.getPlatform() !== 'web') {
        Auth.logout();
      }

      CopilotBridge.sessionEnded();
      this.authService.logout();
      this.router.navigate(['/']);
    });
  }
}
