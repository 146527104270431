import { Device } from '@connectsense/iot8020-library';
import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({
  name: 'filterRebooters'
})
@Injectable({
  providedIn: 'root'
})

export class FilterRebootersPipe implements PipeTransform {

  transform(devices: Device[]): Device[] {
    return devices.filter((device) => !device.isCSRebooter());
  }

}
