<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button defaultHref="/login"></ion-back-button>
  </ion-buttons>

  <ion-title>HomeKit Devices</ion-title>

  <ion-buttons slot="end" *ngIf="hasGrantedHomeAccess && isUnregisteredUser">
    <ion-button fill="clear" (click)="showDevicePicker()">
      <ion-icon slot="icon-only" name="add"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-content>
  <div class="ion-padding" *ngIf="loading">
    <app-loading-indicator [condition]="true"></app-loading-indicator>
  </div>

  <div class="ion-padding" *ngIf="!loading">
    <div *ngIf="!hasRequestedHomeAccess">
      <p>To proceed, the ConnectSense app will require access to your HomeKit data.</p>
      <p>This will let us pair new devices, and show you a list of your existing ones.</p>
      <ion-button (click)="checkHomeKitPermissions()">Continue</ion-button>
    </div>

    <div *ngIf="hasRequestedHomeAccess && !hasGrantedHomeAccess">
      <p>In order to use your device with HomeKit, you will need to enable access to Home Data in the Settings app.</p>
      <p>Tap the button below to open Settings and grant permission.</p>
      <ion-button (click)="openSettings()">
        Open Settings
      </ion-button>
    </div>

    <div *ngIf="hasGrantedHomeAccess">
      <div *ngIf="unregisteredHkDevices.length > 0">
        <div *ngIf="isUnregisteredUser">
          These ConnectSense devices aren't registered with the ConnectSense cloud.<br>Control these in Apple's Home app.
          <ng-container *ngFor="let room of hkRooms | keyvalue">
            <ion-list-header class="room-header">{{room.key}}</ion-list-header>
            <app-homekit-list-item (click)="openHomeApp()" *ngFor="let device of room.value" [hkDevice]="device">
            </app-homekit-list-item>
          </ng-container>
        </div>

        <div *ngIf="!isUnregisteredUser">
          These ConnectSense devices aren't registered with the ConnectSense cloud.<br>Tap on a device to begin registration.
          <ng-container *ngFor="let room of hkRooms | keyvalue">
            <ion-list-header class="room-header">{{room.key}}</ion-list-header>
            <app-homekit-list-item (click)="startCloudConnection(device)" *ngFor="let device of room.value" [hkDevice]="device">
            </app-homekit-list-item>
          </ng-container>
        </div>
      </div>

      <div *ngIf="!unregisteredHkDevices.length > 0">
        You do not have any unregistered ConnectSense devices in HomeKit.
        <ion-button *ngIf="isUnregisteredUser" (click)="showDevicePicker()">Add a device</ion-button>
      </div>
    </div>

    <ion-modal #devicePickerModal>
      <ng-template>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button (click)="devicePickerModal.dismiss($event)">Cancel</ion-button>
          </ion-buttons>
          <ion-title>Add a Device</ion-title>
        </ion-toolbar>
        <div class="card-container">
          <ion-card class="device-card" (click)="startHomeKitSetup(deviceTypes.CSSmartOutlet)">
            <ion-card-header>
              <ion-card-title>
                <img src="../../assets/device-types/smart-outlet.png" alt="SmartOutlet" />
                Smart Outlet²
                <app-icon name="chevron-forward"></app-icon>
              </ion-card-title>
            </ion-card-header>
          </ion-card>

          <ion-card class="device-card" (click)="startHomeKitSetup(deviceTypes.CSInWallOutlet)">
            <ion-card-header>
              <ion-card-title>
                <img src="../../assets/device-types/in-wall-outlet.png" alt="In-Wall Outlet" />
                In-Wall Outlet
                <app-icon name="chevron-forward"></app-icon>
              </ion-card-title>
            </ion-card-header>
          </ion-card>
        </div>
      </ng-template>
    </ion-modal>
  </div>
</ion-content>
