import { ErrorMessage } from './../../../assets/strings';
import { IonicDialogService } from './../../services/ionic-dialog.service';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ErrorTitle } from 'assets/strings';

@Component({
  selector: 'app-schedule-day-picker',
  templateUrl: './schedule-day-picker.component.html',
  styleUrls: ['./schedule-day-picker.component.scss']
})
export class ScheduleDayPickerComponent {
  @Output() cronDaysChange = new EventEmitter<string>();
  days: string[] = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
  selectedDays: number[] = [];
  @Input() set cronDays(value: string) {
    if (value) {
      this.selectedDays = value.split(',').map(day => Number(day));
    } else {
      this.selectedDays = [1, 2, 3, 4, 5];
    }
  };

  constructor(private ionicDialogService: IonicDialogService) { }

  toggleDay(day: number) {
    if (!this.selectedDays.includes(day)) {
      this.selectedDays = [day, ...this.selectedDays];
    } else if (this.selectedDays.length === 1) {
      this.ionicDialogService.generic(ErrorTitle.Error, ErrorMessage.ScheduleInvalid);
    } else {
      this.selectedDays = this.selectedDays.filter(selectedDay => selectedDay !== day);
    }

    this.emitDays();
  }

  emitDays() {
    this.cronDaysChange.emit(this.selectedDays.sort().toString());
  }
}
