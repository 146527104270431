import { RebootService } from './../services/reboot.service';
import { LoadingMessage, DialogTitle, DialogMessage, DialogButtonText } from './../../assets/strings';
import { IonicNotificationService } from './../services/ionic-notification.service';
import { IonicDialogService } from './../services/ionic-dialog.service';
import { Component, Input, KeyValueDiffers, DoCheck, OnInit } from '@angular/core';

import { filter } from 'rxjs/operators';

import { thingShadow } from 'aws-iot-device-sdk';

import { Command, DeviceService, Device } from '@connectsense/iot8020-library';

import { RebootStatus } from '../models/reboot-status';
import { CopilotBridge } from '@copilot-bridge';

export type RebootEventMessage = {
  message: string;
  status: string;
}

@Component({
  selector: 'app-manual-reboot-button',
  templateUrl: './manual-reboot-button.component.html',
  styleUrls: ['./manual-reboot-button.component.scss']
})
export class ManualRebootButtonComponent implements DoCheck, OnInit {
  readonly REBOOT_EVENT_TYPES = ['system', 'reboot'];
  LoadingMessage = LoadingMessage;
  readonly rebootMessage = {
    initiating: 'Initiating reboot',
    [RebootStatus.Reboot]: 'Reboot in progress...',
    [RebootStatus.Boot]: 'Reboot complete',
    failed: 'There was an error rebooting the device'
  };
  @Input() device: Device;
  @Input() disabled: boolean;
  @Input() shadow: thingShadow;
  @Input() rebootText = 'Reboot Now';
  @Input() shouldShowIcon = true;
  @Input() fill = 'solid';
  loadingDialog: HTMLIonLoadingElement;
  originalRebootText = '';
  differ: any;

  constructor(
    private deviceService: DeviceService,
    private ionicDialogService: IonicDialogService,
    private ionicNotificationService: IonicNotificationService,
    private rebootService: RebootService,
    private differs:  KeyValueDiffers,
  ) { this.differ = differs.find([]).create(); }

  ngOnInit(): void {
    this.rebootService.rebootingDevices.subscribe((deviceId) => {
      if (!deviceId) {
        return;
      }

      if (deviceId.includes('FAILED') && deviceId.includes(this.device.deviceId)) {
        this.resetRebootStatus();
      } else if (this.rebootService.rebootingDeviceIds.includes(this.device.deviceId)) {
        this.setRebootStatus();
      }
    });
  }

  ngDoCheck(): void {
    if (!this.rebootService.rebootingDeviceIds.includes(this.device.deviceId)) {
      return;
    }
    const changes = this.differ.diff(this.device);
    if (changes) {
      changes.forEachChangedItem((change) => {
        if (change.key === 'data' && !change.currentValue.offline) {
          this.resetRebootStatus();
          this.rebootService.finishReboot(this.device.deviceId);
        }
      });
    }
  }

  confirmReboot(): void {
    this.ionicDialogService.confirm(
      DialogTitle.RebootManual,
      DialogMessage.RebootManual,
      [
      {
        text: DialogButtonText.Cancel,
        role: 'cancel'
      },
      {
        text: DialogButtonText.Reboot,
        role: 'confirm'
      },
    ]
    ).pipe(
      filter(Boolean)
    ).subscribe(() => {
      this.reboot();
    });
  }

  reboot(): void {
    this.rebootService.recordReboot(this.device.deviceId);
    this.setRebootStatus();
    this.deviceService.executeTopicCommand(this.device.deviceId, Command.ManualReboot, { version: 2 }, this.shadow);
    CopilotBridge.logReboot({ deviceId: this.device.deviceId });
  }

  setRebootStatus() {
    if (this.originalRebootText.length) {
      return;
    }
    this.originalRebootText = this.rebootText;
    this.rebootText = LoadingMessage.Rebooting;
    this.disabled = true;
  }

  resetRebootStatus() {
    this.rebootText = this.originalRebootText;
    this.disabled = false;
  }
}
