import { CopilotBridge } from '@copilot-bridge';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment.staging';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { Auth } from '@auth';
import { LoadingMessage, ScreenTitle, ErrorMessage, ErrorTitle, DialogButtonText } from './../../assets/strings';
import { Network, DeviceService } from '@connectsense/iot8020-library';
import { IonicDialogService } from './../services/ionic-dialog.service';
import { ApiService } from './../services/api.service';
import { Router } from '@angular/router';
import { ThemingService } from './../theming.service';
import { Component, OnInit, NgZone, Input, OnDestroy } from '@angular/core';
import { TextRecognition } from '@text-recognition';
import { SHA256 } from 'crypto-js';
import { Camera, PermissionStatus as CameraPermissionStatus } from '@capacitor/camera';
import { BlufiProvisioning } from '@blufi-prov';
import { ScreenOrientation, OrientationType } from '@capawesome/capacitor-screen-orientation';

@Component({
  selector: 'app-blufi-setup',
  templateUrl: './blufi-setup.component.html',
  styleUrls: ['./blufi-setup.component.scss']
})
export class BlufiSetupComponent implements OnInit, OnDestroy {
  @Input() selectedDeviceType = 'inWallOutlet';

  // Step 2
  badCharacterDict = {
    'l': '1',
    'o': '0',
    'b': '6',
    'g': '9',
    'q': '9',
    'G': '6',
    'B': '8',
    'Z': '2',
    'O': '0',
    'I': '1',
    'D': '0',
    'S': '5',
    'U': '0',
    'Q': '0',
    'A': '4',
    'T': '7',
    'H': '4',
    'e': '0',
    'a': '8',
    '(': ' ',
    ')': ' ',
    '[': ' ',
    ']': ' ',
    'C': ' ',
    '@': ' ',
  };
  labelLength = 14;
  homeKitDashPositions = [3, 6];
  numericHomeKitLength = 8;
  cameraPermissionStatus: CameraPermissionStatus = {} as any;
  acceptingScanParse = true;
  expiration: number;
  session;

  // Step 2
  thingId = '';
  serial: string;
  currentPreviewText = '';

  // Step 3
  macAddress = '';
  proofOfPossession = '';
  loadingMessage = LoadingMessage.ConnectingToDevice;
  connectionTimeout;

  // Step 4
  accessPoints: void | Network[];
  selectedNetwork = new Network();
  // postingNetworkCredentials = false;
  _manualNetworkName: string;

  // Step 5
  startTimeMilliseconds: number;

  // Step 6
  SUCCESS_TIMEOUT = 3000; // Display success for 3 seconds before routing

  // Global
  title = ScreenTitle.BlufiSetup;
  hasStartedSetup = false;
  firstStep = 0;
  step = this.firstStep;

  steps = {
    1: () => {},
    2: async () => {
      await this.startScan();
    },
    3: async () => {
      this.startTimeoutSession();
      this.acceptingScanParse = true;
      const didConnect = await this.connectBle(this.macAddress, this.proofOfPossession);
      if (didConnect) {
        await this.getNetworks();
      }
    },
    4: () => {
      this.selectedNetwork.password = '';
    },
    5: async () => {
      this.loadingMessage = LoadingMessage.SendingCredentials;
      await this.getRegistrationParams();
    },
    6: () => {
      this.successfulSetup();
    }
  };

  set manualNetworkName(newValue) {
    this.selectedNetwork.ssid = newValue;
    this._manualNetworkName = newValue;
  };

  constructor(
    private themingService: ThemingService,
    private router: Router,
    private apiService: ApiService,
    private ngZone: NgZone,
    private ionicDialogService: IonicDialogService,
    private deviceService: DeviceService,
  ) { ScreenOrientation.lock({ type: OrientationType.PORTRAIT }); }

  // Global
  ngOnInit() {
    this.forward();
  }

  ngOnDestroy() {
    ScreenOrientation.unlock();
  }

  forward() {
    this.ngZone.run(() => { this.step++; });
    this.go();
  }

  private go() {
    const titles = {
      1: ScreenTitle.BlufiReady,
      2: ScreenTitle.BlufiConnect,
      3: ScreenTitle.BlufiConnecting,
      4: ScreenTitle.BlufiNetwork,
      5: ScreenTitle.BlufiConnectingDevice,
      6: ScreenTitle.Success,
    };
    this.steps[this.step].apply(this);
    this.title = titles[this.step];
  }

  startManualSetup() {
  }

  // Step 2
  async startScan() {
    this.cameraPermissionStatus = await Camera.checkPermissions();

    if (this.cameraPermissionStatus.camera === 'prompt') {
      this.cameraPermissionStatus = await Camera.requestPermissions({ permissions: [ 'camera' ] });
    }

    if (this.cameraPermissionStatus.camera === 'denied') {
      return;
    }

    this.themingService.toggleQrScan(true);

    try {
      TextRecognition.startScan(async (result: any) => {
        const stringRead = result.content;

        if (this.acceptingScanParse) {
          this.parseText(stringRead);
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  parseText(text: string) {
    text = this.replaceBadCharacters(text);

    // Remove all non-numeric or -
    text = text.replace(/[^\d-]/g, '');

    if (text.length !== this.labelLength) {
      return;
    }

    if (
      text.charAt(this.homeKitDashPositions[0]) !== '-' ||
      text.charAt(this.homeKitDashPositions[1]) !== '-') {
      return;
    }

    const goodReadText = `${text.substring(0, text.length - 4)} ${text.substring(text.length - 4, text.length)}`;
    if (this.currentPreviewText !== goodReadText) {
      TextRecognition.showPreviewText({previewText: goodReadText});
    }
    this.currentPreviewText = goodReadText;

    // Remove dashes
    text = text.replace(/\-/g, '');

    const parsedHomekit = text.substring(0, this.numericHomeKitLength);
    const parsedPop = text.substring(this.numericHomeKitLength);
    const hash = this.generateHash(parsedHomekit, parsedPop);

    // prevent multiple registry calls
    this.acceptingScanParse = false;

    this.getRegistryMacAddress(hash, parsedPop);
  }

  replaceBadCharacters(text: string): string {
    let newChars = [...text];

    const chars = [...text];
    chars.forEach((character, index) => {
      if (character in this.badCharacterDict) {
        newChars[index] = this.badCharacterDict[character]
      }
    });

    return newChars.join('').replace(/\s+/g, '');
  }

  generateHash(homekitText: string, popText: string): string {
    return SHA256(homekitText + popText).toString();
  }

  async getRegistryMacAddress(hash: string, pop: string) {
    let resp;

    try {
      resp = await this.apiService.getAsPromise(`/devices/register/${hash}`);
    } catch (error) {
      console.error(error);
      this.showErrorDialog(ErrorTitle.Error, ErrorMessage.RegistryError);
    }

    if (resp === null || resp === undefined) {
      TextRecognition.incorrectPreviewText({previewText: ''});
      this.currentPreviewText = '';
      return this.acceptingScanParse = true;
    }

    if (resp.ble_mac === undefined) {
      TextRecognition.incorrectPreviewText({previewText: ''});
      this.currentPreviewText = '';
      return this.acceptingScanParse = true;
    }

    this.serial = resp.serial_number;
    this.thingId = this.getThingId(resp.serial_number);
    CopilotBridge.logThingDiscovery({thingId: this.thingId});

    const bleMac = resp.ble_mac;

    const formattedMac = this.formatMacAddress(bleMac);

    this.macAddress = formattedMac;
    this.proofOfPossession = pop;

    if (this.step === 2) {
      this.forward();
    }
  }

  getThingId(serialNumber: string, deviceType?: string): string {
    // TODO - move this work from multiple setups into a service. SetupService? DeviceDiscoveryService?
    const prefix = 'CS-IWO-';
    return prefix + serialNumber;
  }

  formatMacAddress(fullMac: string): string {
    const macLength = fullMac.length;

    // This is a workaround to handle labels that were printed with
    // the incorrect MAC address during beta
    let decimalMac = parseInt(fullMac, 16);
    if ((decimalMac % 2) === 0) {
      decimalMac = decimalMac + 1;
      fullMac = decimalMac.toString(16);
    }

    fullMac = fullMac.padStart(length, '0').toUpperCase();

    let partialMac = '';

    if (macLength === 12) {
      partialMac = fullMac.substring(6, 12);
    }

    return 'CS-IWO-' + partialMac;
  }

  // Step 3
  startTimeoutSession() {
    this.session = setInterval(() => {
      CopilotBridge.logThingConnectionFailure({reason: 'session expired'});
      this.showErrorDialog(
        ErrorTitle.TimeOut,
        ErrorMessage.TimeOut,
      );
      return clearInterval(this.session);
    }, 300000);
  }

  async connectBle(mac: string, pop: string): Promise<boolean> {
    this.themingService.toggleQrScan(false);
    await TextRecognition.stopScan();

    this.connectionTimeout = setInterval(() => {
      CopilotBridge.logThingConnectionFailure({reason: 'unable to connect ble'});
      this.showErrorDialog(
        ErrorTitle.TimeOut,
        ErrorMessage.BleTimeOut
      );
      clearInterval(this.connectionTimeout);
      return false;
    }, 30000)

    let connectionResult;

    try {
      connectionResult = await BlufiProvisioning.pairDevice({bleIdentifier: mac, proof: pop});
    } catch (error) {
      console.error(error);
      this.showErrorDialog(ErrorTitle.Error, error);
      return false;
    }

    const didConnect: boolean = connectionResult.didFindPeripheral;
    clearInterval(this.connectionTimeout);

    if (!didConnect) {
      CopilotBridge.logThingConnectionFailure({reason: 'unable to connect ble'})
      this.showErrorDialog(ErrorTitle.Error, ErrorMessage.BleFail);
      return false;
    }

    CopilotBridge.logThingConnection({thingId: this.thingId});

    this.ngZone.run(() => {
      this.loadingMessage = LoadingMessage.BleDeviceFound;
    });

    return true;
  }

  async getNetworks() {
    this.ngZone.run(() => {
      this.loadingMessage = LoadingMessage.ScanningForNetworks;
    });

    try {
      const result = await BlufiProvisioning.requestNetworks();
      let parsedNetworks: Network[];

      try {
        parsedNetworks = JSON.parse(result.networkList);
      } catch (error) {
        console.error(error);
        return this.showErrorDialog(ErrorTitle.Error, ErrorMessage.NetworkScanFail)
      }

      this.accessPoints = parsedNetworks;

      if (this.step === 3) {
        this.forward();
      }
    } catch (error) {
      console.error(error);
      this.showErrorDialog(ErrorTitle.Error, error)
    }
  }


  // Step 5
  async getRegistrationParams() {
    const idToken = await this.getIdToken();
    const accountId = this.getAccountId(idToken);
    const environmentFlag = environment.production ? 'production' : 'staging';
    const ssid = this.selectedNetwork.ssid;
    const password = this.selectedNetwork.password;

    this.deviceService.getClaimCode().subscribe(async (claimCode: string) => {
      this.startClaimCodeSession(claimCode);
      this.startBlufiProvisioning(this.proofOfPossession, claimCode, accountId, environmentFlag, ssid, password);
    }, () => {
      this.showErrorDialog(ErrorTitle.Error, ErrorMessage.ClaimCodeFetchFail);
    });
  }

  async startBlufiProvisioning(
    proof: string,
    claimCode: string,
    accountId: string,
    environmentFlag: string,
    ssid: string,
    password: string
  ) {
    this.startTimeMilliseconds = Date.now();
    CopilotBridge.logIoT8020Registration({
      registrationStage: 'started',
      deviceType: this.selectedDeviceType,
      deviceId: this.thingId,
      timeElapsed: ''
    });

    try {
      BlufiProvisioning.provision({
        proof: proof,
        claimCode: claimCode,
        accountId: accountId,
        environment: environmentFlag,
        ssid: ssid,
        password: password
      }, async (result: any) => {
        const message = result.message;
        const statusCode = result.statusCode;

        if (result === null || result === undefined || statusCode === undefined) {
          // Catch unhandled error
          const elapsedTimeSeconds = Math.round((Date.now() - this.startTimeMilliseconds) / 1000);
          CopilotBridge.logIoT8020Registration({
            registrationStage: `failed - ${message}`,
            deviceType: this.selectedDeviceType,
            deviceId: this.thingId,
            timeElapsed: String(elapsedTimeSeconds)
          });

          console.error(result);
          return this.showErrorDialog(ErrorTitle.Error, ErrorMessage.ProvisioningStatusReadFail);
        }

        if (statusCode === 4) {
          // Device registered
          clearInterval(this.session);
          return this.forward();
        }

        if (statusCode >= 5) {
          // Error codes
          const elapsedTimeSeconds = Math.round((Date.now() - this.startTimeMilliseconds) / 1000);
          CopilotBridge.logIoT8020Registration({
            registrationStage: `failed - ${message}`,
            deviceType: this.selectedDeviceType,
            deviceId: this.thingId,
            timeElapsed: String(elapsedTimeSeconds)
          });

          return this.showErrorDialog(ErrorTitle.Error, message);
        }

        this.ngZone.run(() => {
          this.loadingMessage = message;
        });
      });
    } catch (error) {
      console.log(error)
      return this.showErrorDialog(ErrorTitle.Error, error);
    }
  }

  startClaimCodeSession(claimCode: string): void {
    const timeUntilExpiration = 120 * 1000;
    // 2mins to account for registration timeout

    clearInterval(this.session)
    this.session = setInterval(() => {
      CopilotBridge.logThingConnectionFailure({reason: 'session expired'});
      this.showErrorDialog(
        ErrorTitle.TimeOut,
        ErrorMessage.RegistrationTimeout
      );
      return clearInterval(this.session);
    }, timeUntilExpiration);
  }

  async getIdToken() {
    try {
      const { idToken } = await Auth.getCredentials();
      return idToken as string;
    } catch (e) {
      this.showErrorDialog(ErrorTitle.CredentialsFetchFail, ErrorMessage.CredentialsFetchFail);
    }
  }

  getAccountId(idToken: string): string {
    const decodedToken: JwtPayload = jwtDecode(idToken);
    const token: string = decodedToken.sub;
    return token.substring(token.indexOf('|') + 1).replace('amzn1.account.', '').replace(/\./g, '');
  }

  // Step 6
  successfulSetup(): void {
    const elapsedTimeSeconds = Math.round((Date.now() - this.startTimeMilliseconds) / 1000);
    CopilotBridge.logIoT8020Registration({
      registrationStage: 'completed',
      deviceType: this.selectedDeviceType,
      deviceId: this.thingId,
      timeElapsed: String(elapsedTimeSeconds)
    });
    CopilotBridge.logOnBoardingEnd();

    setTimeout(() => {
      this.router.navigate(['/devices'], { queryParams: {
        pendingDevice: this.serial
      } });
    }, this.SUCCESS_TIMEOUT);
  }

  async openSettings() {
    await TextRecognition.openAppSettings();
  }

  back() {
    this.themingService.toggleQrScan(false);
    TextRecognition.stopScan();

    if (this.step === 1 || this.step > 2) {
      this.returnToList();
    } else {
      this.step--;
      this.hasStartedSetup = false;
      this.go();
    }
  }

  showErrorDialog(title: string, message: string) {
    return this.ionicDialogService.confirm(title, message, [{
      text: DialogButtonText.OK,
      role: 'confirm'
    }]).subscribe(() => {
      this.returnToList();
    });
  }

  returnToList() {
    this.router.navigate(['/devices'], { replaceUrl: true });
  }

  ionViewWillLeave() {
    this.step = this.firstStep;
    this.hasStartedSetup = false;
    this.acceptingScanParse = true;
    this.macAddress = '';
    this.proofOfPossession = '';
    this.serial = '';
    this.thingId = '';
    this.loadingMessage = LoadingMessage.ConnectingToDevice;
    clearInterval(this.session);
    clearInterval(this.connectionTimeout);

    BlufiProvisioning.closeConnection();
    ScreenOrientation.unlock();
  }
}
