import { BrowserModule } from '@angular/platform-browser';
import { NgModule, InjectionToken, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';

import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';

import { ChartsModule } from 'ng2-charts';

import {
  IoT8020Module,
  AuthService,
  UserService,
  NotificationService,
  ClientStorageService,
  Store,
  StoreHelper,
  ContactService,
  UserInfoService,
  DeviceService,
  DeviceTypes,
  DeviceSetupService,
  EncryptService,
  FirmwareService
} from '@connectsense/iot8020-library';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { DeviceListComponent } from './device-list/device-list.component';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { LoginComponent } from './login/login.component';
import { DeviceSummaryComponent } from './device-summary/device-summary.component';
import { DeviceLogComponent } from './device-log/device-log.component';
import { DateRangeSwitcherComponent } from './date-range-switcher/date-range-switcher.component';
import { DeviceDetailSettingsComponent } from './device-detail-settings/device-detail-settings.component';

import { AuthGuard } from './services/auth-guard.service';
import { LoginGuard } from './services/login-guard';
import { SupportComponent } from './support/support.component';
import { SignupComponent } from './signup/signup.component';
import { RuleService } from './services/rule.service';
import { EnvironmentService } from './services/environment.service';

import { SafeHtmlPipe } from './safe-html.pipe';
import { DeviceControlsComponent } from './device-controls/device-controls.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { OutletSettingsComponent } from './outlet-settings/outlet-settings.component';
import { OutletSetupComponent } from './outlet-setup/outlet-setup.component';
import { OutletTypePickerComponent } from './outlet-type-picker/outlet-type-picker.component';
import { OutletPowerMonitorComponent } from './outlet-power-monitor/outlet-power-monitor.component';
import { ValidatePasswordStrengthDirective } from './validate-password-strength/validate-password-strength.directive';
import { DeviceTypePickerComponent } from './device-type-picker/device-type-picker.component';
import { HomeService } from './home/services/home.service';

import { environment } from '../environments/environment';

import { SharedModule } from './shared/shared.module';
import { ManualRebootButtonComponent } from './manual-reboot-button/manual-reboot-button.component';
import { RebooterTypePickerComponent } from './rebooter-type-picker/rebooter-type-picker.component';
import { IonicModule } from '@ionic/angular';
import { ApiService } from './services/api.service';
import { HomekitListItemComponent } from './homekit-list-item/homekit-list-item.component';
import { HomekitSetupComponent } from './homekit-setup/homekit-setup.component';
import { HomekitDeviceListComponent } from './homekit-device-list/homekit-device-list.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { OutletTypeSelectComponent } from './outlet-type-select/outlet-type-select.component';
import { DeviceCardComponent } from './device-card/device-card.component';
import { DeviceCardControlComponent } from './device-card-control/device-card-control.component';
import { RoomPickerComponent } from './room-picker/room-picker.component';
import { SelectionModalComponent } from './selection-modal/selection-modal.component';
import { SchedulePopoverSelectComponent } from './schedule-popover-select/schedule-popover-select.component';
import { RebootAddressesComponent } from './reboot-addresses/reboot-addresses.component';

// See bug https://github.com/angular/angular/issues/11262
export function apiServiceFactory(http, router, notificationService, env) {
  return new ApiService(http, router, notificationService, env);
}

export function authServiceFactory(env, userInfoService, contactService, userService) {
  return new AuthService(env, userInfoService, contactService, userService);
}

export function deviceServiceFactory(apiService, storeHelper, environmentService, supportedDeviceTypes) {
  return new DeviceService(apiService, storeHelper, environmentService, supportedDeviceTypes);
}

export function userServiceFactory(apiService) {
  return new UserService(apiService);
}

export function deviceSetupServiceFactory(http, encrypt, env) {
  return new DeviceSetupService(http, encrypt, env);
}

export function firmwareServiceFactory(apiService, deviceService) {
  return new FirmwareService(apiService, deviceService);
}

export const SUPPORTED_DEVICE_TYPES = new InjectionToken('supportedDeviceTypes');

Sentry.init(
  {
    dsn: environment.SENTRY_DSN,
    ignoreErrors: [
      /premature close/,
      /Handled unknown error/,
      /Uncaught, unspecified/,
      /WebSocket connection/,
      /network connection/
    ],
    environment: environment.NAME,
  },
  SentryAngular.init,
);

@NgModule({
  declarations: [
    AppComponent,
    DeviceListComponent,
    DeviceDetailComponent,
    LoginComponent,
    DeviceSummaryComponent,
    DeviceLogComponent,
    DateRangeSwitcherComponent,
    DeviceDetailSettingsComponent,
    SupportComponent,
    SignupComponent,
    SafeHtmlPipe,
    DeviceControlsComponent,
    PasswordResetComponent,
    OutletSettingsComponent,
    OutletTypePickerComponent,
    OutletSetupComponent,
    OutletPowerMonitorComponent,
    ValidatePasswordStrengthDirective,
    OutletPowerMonitorComponent,
    DeviceTypePickerComponent,
    RebooterTypePickerComponent,
    ManualRebootButtonComponent,
    HomekitListItemComponent,
    HomekitSetupComponent,
    HomekitDeviceListComponent,
    SuccessModalComponent,
    OutletTypeSelectComponent,
    DeviceCardComponent,
    DeviceCardControlComponent,
    RoomPickerComponent,
    SelectionModalComponent,
    SchedulePopoverSelectComponent,
    RebootAddressesComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    ChartsModule,
    BrowserAnimationsModule,
    IoT8020Module.forRoot(),
    HttpClientModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    ReactiveFormsModule,
    IonicModule.forRoot()
  ],
  providers: [
    { provide: SUPPORTED_DEVICE_TYPES, useValue: [
      DeviceTypes.CSSmartOutlet,
      DeviceTypes.CSInWallOutlet,
      DeviceTypes.CSLampController,
      DeviceTypes.CSRebooter
    ]},
    {
      provide: DeviceService,
      useFactory: deviceServiceFactory,
      deps: [ApiService, StoreHelper, EnvironmentService, SUPPORTED_DEVICE_TYPES]
    },
    AuthGuard,
    LoginGuard,
    NotificationService,
    UserInfoService,
    ClientStorageService,
    RuleService,
    ContactService,
    Store,
    StoreHelper,
    EnvironmentService,
    {
      provide: AuthService,
      useFactory: authServiceFactory,
      deps: [EnvironmentService, UserInfoService, ContactService, UserService]
    },
    {
      provide: ApiService,
      useFactory: apiServiceFactory,
      deps: [HttpClient, Router, NotificationService, EnvironmentService]
    },
    {
      provide: UserService,
      useFactory: userServiceFactory,
      deps: [ApiService]
    },
    HomeService,
    {
      provide: DeviceSetupService,
      useFactory: deviceSetupServiceFactory,
      deps: [HttpClient, EncryptService, EnvironmentService]
    },
    {
      provide: FirmwareService,
      useFactory: firmwareServiceFactory,
      deps: [ApiService, DeviceService]
    },
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler({
        extractor: (error: Error) => {
          if (
            error.message.includes('WebSocket connection') ||
            error.message.includes('Uncaught, unspecified') ||
            error.message.includes('ERR_NETWORK_CHANGED') ||
            error.message.includes('ERR_INTERNET_DISCONNECTED') ||
            error.message.includes('SSL error') ||
            error.message.includes('XMLHttpRequest cannot load') ||
            error.message.includes('premature close') ||
            error.message.includes('network connection') ||
            error.message.includes('HTTP failure')
          ) {
            return false;
          } else {
            return error;
          }
        }
      }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
