import { Router } from '@angular/router';
import { IonicNotificationService } from './ionic-notification.service';
import { ApiService } from 'app/services/api.service';
import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(
    private apiService: ApiService,
    private ionicNotificationService: IonicNotificationService,
    private router: Router,
  ) { }

  async register() {
    if (!Capacitor.isPluginAvailable('PushNotifications')) { return; }

    const result = await PushNotifications.requestPermissions();

    if (result.receive === 'granted') {
      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();
      this.listenForNotification();
      this.listenForTap();
    } else {
    }

    this.handleRegistration();

    await PushNotifications.register();
  }

  async handleRegistration() {
    const { value: token } = await this.waitForRegistration();
    const { identifier } = await Device.getId();
    await this.apiService.putAsPromise('/push-notifications', { uuid: identifier, token, timestamp: Date.now() });
  }

  waitForRegistration(): Promise<Token> {
    return new Promise(resolve => {
      PushNotifications.addListener('registration', (token: Token) => resolve(token));
    });
  }

  async listenForNotification() {
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.ionicNotificationService.show(notification.body);
      }
    );
  }

  async listenForTap() {
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        const notificationThingName = notification.notification.data.thingName;
        this.router.navigateByUrl('/devices/' + notificationThingName);
      }
    );
  }
}
