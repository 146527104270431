<ion-toggle
    *ngIf="device.hasFeature(DeviceFeature.OutletControl)"
    #toggle
    [ngModel]="device.data[outlet + '_active']"
    (ngModelChange)="onOutletStateChange($event, device.deviceId, outlet)"
    color="primary"
></ion-toggle>

<app-manual-reboot-button
  *ngIf="device.hasFeature(DeviceFeature.RebootManually)"
  [device]="device"
  [disabled]="!device.isConnected()"
  [shadow]="shadow"
  rebootText="Reboot"
  [shouldShowIcon]="false"
  fill="outline"
></app-manual-reboot-button>
