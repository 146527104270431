export interface ScanResult {
  content?: string;
}

export type ScanCallback = (
  content: String | null,
  err?: any,
) => void;

export interface TextRecognitionPlugin {
  prepare(): Promise<any>;
  hideBackground(): Promise<any>;
  showBackground(): Promise<any>;
  startScan(callback: ScanCallback): Promise<ScanResult>;
  stopScan(): Promise<any>;
  checkPermission(): Promise<any>;
  openAppSettings(): Promise<any>;
  showPreviewText(input: { previewText: string }): Promise<any>;
  incorrectPreviewText(input: { previewText: string }): Promise<any>;
}
