import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { from, Observable, of } from 'rxjs';
import { _throw as observableThrowError } from 'rxjs/observable/throw';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { ClientStorageKey, ClientStorageService, ClientStorageValueType } from '@connectsense/iot8020-library';
import { EnvironmentService } from './environment.service';
import { Capacitor } from '@capacitor/core';
import { Auth } from '@auth';

@Injectable()
export class ApiService {
  private apiUrl: string;

  private headers: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    Accept:         'application/json',
    Authorization: ''
  });

  constructor(
    private http: HttpClient,
    private router: Router,
    private ionicDialogService: IonicDialogService,
    private environmentService: EnvironmentService
  ) {
    this.apiUrl = this.environmentService.get('apiUrl');
  }

  private processInvalidToken = () => {
    this.ionicDialogService.confirm('Expired Token', 'Your token has expired, please login again', [{
      text: 'OK',
      role: 'confirm'
    }]).subscribe(() => {
      this.router.navigate(['/logout']);
    });
  }

  private processStream = (response: Observable<Object>): Observable<any> => {
    return response.pipe(
      catchError(err => {
        if (this.isAuthTokenInvalid(err)) {
          this.processInvalidToken();
        }

        return observableThrowError(err);
      }));
  }

  private processPromise = (response: Observable<Object>): Promise<any> => {
    return response
      .toPromise()
      .catch(err => {
        if (this.isAuthTokenInvalid(err)) {
          this.processInvalidToken();
        }

        this.normalizeError(err);
      });
  }

  getAsStream = (path: String): Observable<any> => {
    return this.processStream(this.get(path));
  }

  getAsPromise = (path: String): Promise<any> => {
    return this.processPromise(this.get(path));
  }

  private get(path: String): Observable<Object> {
    return this.setupAuthHeader().pipe(
      switchMap(() => this.http.get(`${this.apiUrl}${path}`, {headers: this.headers}))
    );
  }

  deleteAsStream = (path: String): Observable<any> => {
    return this.processStream(this.remove(path));
  }

  deleteAsPromise = (path: String): Promise<any> => {
    return this.processPromise(this.remove(path));
  }

  private remove = (path: String): Observable<Object> => {
    return this.setupAuthHeader().pipe(
      switchMap(() => this.http.delete(`${this.apiUrl}${path}`, {headers: this.headers}))
    );
  }

  postAsStream = (path: String, body: any): Observable<any> => {
    return this.processStream(this.post(path, body));
  }

  public postAsPromise = (path: String, body: any): Promise<any> => {
    return this.processPromise(this.post(path, body));
  }

  private post = (path: String, body: any): Observable<Object> => {
    return this.setupAuthHeader().pipe(
      switchMap(() => this.http.post(`${this.apiUrl}${path}`, body,  {headers: this.headers}))
    );
  }

  putAsStream = (path: String, body: any): Observable<any> => {
    return this.processStream(this.put(path, body));
  }

  putAsPromise = (path: String, body: any): Promise<any> => {
    return this.processPromise(this.put(path, body));
  }

  private put = (path: String, body: any): Observable<Object> => {
    return this.setupAuthHeader().pipe(
      switchMap(() => this.http.put(`${this.apiUrl}${path}`, body, {headers: this.headers}))
    );
  }

  setupAuthHeader = (): Observable<void> => {
    const headerKey = 'Authorization';

    if (Capacitor.isPluginAvailable('Auth')) {
      return from(Auth.getCredentials()).pipe(tap(({ idToken }) => {
        this.headers = this.headers.set(headerKey, `Bearer ${idToken}`)
      })) as Observable<void>;
    }

    let token = ClientStorageService.get(ClientStorageKey.IdToken, ClientStorageValueType.String);

    // Token needs to be set before each request
    if (!token) {
      return;
    }

    this.headers = this.headers.set(headerKey, `Bearer ${token}`);

    return of(undefined);
  }

  private normalizeError = (err: any) => {
    const errorIsResponse = err['_body'];

    let message: string;
    if (errorIsResponse) {
      message = err.json().error.message || err.statusText;
    } else {
      message = err.message;
    }

    let error = errorIsResponse ? new Error(message) : err;
    error['response'] = err;
    console.error(error);

    throw error;
  }

  private isAuthTokenInvalid(response: HttpErrorResponse): boolean {
    const errorObj = response.error;
    const errorMessages = ['Authentication token missing', 'Token has expired', 'Not an admin'];
    const forbiddenStatus = response.status === 403;
    const apiRejectedToken = errorObj && errorMessages.indexOf(errorObj.message) !== -1;

    return forbiddenStatus && apiRejectedToken;
  }
}
