import { RebooterSchedule } from './../models/rebooter-schedule';
import { SchedulePopoverOption } from './../models/schedule-popover-option';
import { PopoverController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-schedule-popover-select',
  templateUrl: './schedule-popover-select.component.html',
  styleUrls: ['./schedule-popover-select.component.scss']
})
export class SchedulePopoverSelectComponent implements OnInit {

  options = [
    { value: SchedulePopoverOption.Rebooter, text: 'Rebooter schedule', enabled: false },
    { value: SchedulePopoverOption.Other, text: 'Other schedule', enabled: true }
  ];
  platform = Capacitor.getPlatform();
  itemLines = this.platform === 'ios' ? 'inset' : 'none';

  @Input() rebootersSchedules: RebooterSchedule[];

  constructor( private popoverController: PopoverController ) { }

  ngOnInit() {
    if (this.rebootersSchedules?.some(rebooter => rebooter.enabled)) {
      this.options[0].enabled = true;
    }
  }

  selectOption(value: string) {
    if (value === SchedulePopoverOption.Other) {
      return this.dismissWithOption(value);
    } else if (value === SchedulePopoverOption.Rebooter) {
      // if more  than one rebooter, show list, otherwise route right to new sched
      if (this.rebootersSchedules.length > 1) {
        // go to schedule detail with array
        return this.dismissWithOption(SchedulePopoverOption.MultipleRebooters);
      } else {
        return this.dismissWithOption(this.rebootersSchedules[0].device.deviceId);
      }
    } else {
      return this.dismissWithOption(value);
    }
  }

  dismissWithOption(option: string) {
    this.popoverController.dismiss({
      selectedValue: option
    });
  }

}
