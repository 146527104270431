<ion-toolbar>
  <ion-buttons slot="start">
    <ion-button *ngIf="!hasStartedSetup" (click)="back()">
      <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
    </ion-button>
  </ion-buttons>

  <ion-title>{{title}}</ion-title>
</ion-toolbar>

<div class="ion-padding loading-container" *ngIf="loading">
  <app-loading-indicator [condition]="true"></app-loading-indicator>
</div>

<div class="loaded-container" *ngIf="!loading">
  <div class="step ion-padding" *ngIf="!loading && !hasGrantedHomeAccess">
    <div *ngIf="!hasRequestedHomeAccess">
      <p>To proceed, the ConnectSense app will require access to your HomeKit data.</p>
      <p>This will let us pair new devices, and show you a list of your existing ones.</p>
      <ion-button expand="block" (click)="checkHomeKitPermissions()">Continue</ion-button>
    </div>

    <div *ngIf="hasRequestedHomeAccess && !hasGrantedHomeAccess">
      <p>In order to use your device with HomeKit, you will need to enable access to Home Data in the Settings app.</p>
      <p>Tap the button below to open Settings and grant permission.</p>
      <ion-button expand="block" (click)="openSettings()">
        Open Settings
      </ion-button>
    </div>
  </div>

  <div *ngIf="hasGrantedHomeAccess" class="body-content">
    <div class="step step-1 ion-padding-horizontal" *ngIf="step == 1">
      <img src="../../assets/{{getSelectedDeviceProperty('image')}}" alt="Photo of {{getSelectedDeviceProperty('name')}}" height="167" />

      <h2>{{getSelectedDeviceProperty('name')}}</h2>

      <p>
        You should see the upper left setup indicator light blinking.
      </p>

      <ion-button (click)="showHelpDialog()" class="help-button" size="small" fill="outline">Not seeing this?</ion-button>

      <ion-button expand="block" (click)="forward()" id="next_button">
        Next
      </ion-button>
    </div>

    <div class="step-2 ion-padding-horizontal loading-container" *ngIf="step == 2">
      <app-loading-indicator [condition]="true"></app-loading-indicator>
    </div>

    <div class="step-3 ion-padding-horizontal loading-container" *ngIf="step == 3">
      <app-loading-indicator [condition]="true" [message]="registrationStepMessage"></app-loading-indicator>
    </div>

    <div class="step step-4 ion-padding-horizontal loading-container" *ngIf="step == 4">
      <img src="../../assets/{{getSelectedDeviceProperty('successImage')}}" alt="Photo of {{getSelectedDeviceProperty('name')}}" height="167" width="106" />
      <h2>{{getSelectedDeviceProperty('name')}} ready!</h2>
    </div>
  </div>

  <div class="ion-padding footer" *ngIf="step < 2 && !onlyWacProvisioning">
    <ion-button (click)="startBlufiProvisioning()" class="help-button" size="small" fill="outline">Pair without HomeKit</ion-button>
  </div>
</div>
