<ion-toolbar>
  <ion-buttons slot="start" *ngIf="step <= 4">
    <ion-button *ngIf="!hasStartedSetup" (click)="back()">
      <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
    </ion-button>
  </ion-buttons>

  <ion-title>{{title}}</ion-title>
</ion-toolbar>

<div class="loaded-container">
  <div class="body-content">
    <div class="step step-1 ion-padding-horizontal" *ngIf="step == 1">
      <img src="../../assets/camera_scan.png" />

      <h2>Scan setup code</h2>

      <p>
        Press Next, then move your phone toward the outlet as shown to capture the device code.
      </p>

      <!-- <ion-button (click)="startManualSetup()" class="help-button" size="small" fill="outline">Not able to scan?</ion-button> -->

      <ion-button expand="block" (click)="forward()" id="next_button">
        Next
      </ion-button>
    </div>

    <div class="step step-2 ion-padding-horizontal" *ngIf="step == 2">

      <ion-card *ngIf="cameraPermissionStatus.camera === 'granted'">
        <ion-card-content >
          Scan the setup code on the device
          <!-- <span>or hold phone near the accessory</span> TODO: NFC -->
        </ion-card-content>
      </ion-card>

      <ion-card *ngIf="cameraPermissionStatus.camera === 'denied'">
        <ion-card-content >
          <p>Camera permission is required to scan the setup code.</p>
          <p>Tap the button below to open Settings and grant permission.</p>
          <ion-button expand="block" (click)="openSettings()">
            Open Settings
          </ion-button>
        </ion-card-content>
      </ion-card>
    </div>

    <div class="step step-3 ion-padding-horizontal loading-container" *ngIf="step == 3">
      <app-loading-indicator [condition]="true" [message]="loadingMessage"></app-loading-indicator>
    </div>

    <ion-content class="step step-4" *ngIf="step == 4">
      <app-network-list
        *ngIf="!postingNetworkCredentials"
        [accessPoints]="accessPoints"
        [selectedNetwork]="selectedNetwork"
        (onSendCredentials)="forward()"
      ></app-network-list>
    </ion-content>
  </div>

  <div class="step step-3 ion-padding-horizontal loading-container" *ngIf="step == 5">
    <app-loading-indicator [message]="loadingMessage"
      [condition]="true"></app-loading-indicator>
  </div>

  <div class="step step-3 ion-padding-horizontal loading-container" *ngIf="step == 6">
    <img src="../../assets/device-types/in-wall-outlet.png" height="167" width="106" />
    <h2>In-Wall Outlet ready!</h2>
  </div>
</div>
