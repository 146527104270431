import { registerPlugin } from '@capacitor/core';

import type { CopilotBridgePlugin } from './definitions';

const CopilotBridge = registerPlugin<CopilotBridgePlugin>(
  'CopilotBridge',
  {
    web: () => import('./web').then(m => new m.CopilotBridgeWeb()),
  },
);

export * from './definitions';
export { CopilotBridge };
