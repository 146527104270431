import { Capacitor } from '@capacitor/core';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { OutletTypes } from '../models/outlet-types';

@Component({
  selector: 'app-outlet-type-select',
  templateUrl: './outlet-type-select.component.html',
  styleUrls: ['./outlet-type-select.component.scss']
})
export class OutletTypeSelectComponent {
  @Input() deviceId: string;
  @Input() type: number;
  position = Capacitor.getPlatform() === 'ios' ? undefined : 'floating';
  get label(): string {
    return Object.values(OutletTypes).find(type => type.id === this.type)?.label;
  }

  constructor(private router: Router) {}

  handleFocus($event: FocusEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.router.navigate(['devices', this.deviceId, 'outlet-settings'], { queryParamsHandling: 'merge' });
  }
}
