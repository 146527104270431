import { ErrorTitle, ErrorMessage } from './../../../assets/strings';
import { IonicDialogService } from './../../services/ionic-dialog.service';
import { IonToggle } from '@ionic/angular';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { noop } from 'rxjs';
import { take } from 'rxjs/operators';

import { Command, CSSmartOutlet, DeviceService } from '@connectsense/iot8020-library';

import { CopilotBridge } from '@copilot-bridge';

@Component({
  selector: 'app-outlet-toggle',
  templateUrl: './outlet-toggle.component.html',
  styleUrls: ['./outlet-toggle.component.css']
})
export class OutletToggleComponent {
  @Input() device: CSSmartOutlet;
  @Input() outlet: string;
  @Input() shouldExecuteCommand = true;
  @Output() outletStateChange = new EventEmitter<any>();
  @ViewChild(IonToggle) toggle: IonToggle;

  constructor(
    private deviceService: DeviceService,
    private ionicDialogService: IonicDialogService
  ) { }

  onOutletStateChange(value: boolean, deviceId: string, outlet: string): void {
    this.device.data[outlet + '_active'] = value;

    this.outletStateChange.emit(value);
    if (!this.shouldExecuteCommand) { return; }

    const command = value ? Command.TurnOn : Command.TurnOff;

    this.deviceService.executeCommand(deviceId, command, outlet).pipe(
    take(1))
    .subscribe(noop, error => {
      this.ionicDialogService.generic(ErrorTitle.Error, ErrorMessage.OutletToggleFail);
    });
    CopilotBridge.logOutletToggle({deviceId: this.device.deviceId});
  }

}
