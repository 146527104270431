import { SuccessModalComponent } from './../success-modal/success-modal.component';
import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { AlertButton, IonicSafeString } from '@ionic/core';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IonicDialogService {

  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private modalController: ModalController,
    ) {}

  async confirmAsync(
    title: string,
    message: string,
    buttons: (string | AlertButton) [] = [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary'
      }, {
        text: 'OK',
        role: 'confirm'
      }
    ]
  ): Promise<boolean> {
    const alert = await this.alertController.create({
      header: title,
      message: new IonicSafeString(message),
      buttons: buttons
    });

    await alert.present();
    const result = await alert.onDidDismiss();

    return result.role === 'confirm';
  }

  public confirm(
    title: string,
    message: string,
    buttons: (string | AlertButton) [] = [
      {
        text: 'Cancel',
        role: 'cancel',
        cssClass: 'secondary'
      }, {
        text: 'OK',
        role: 'confirm'
      }
    ]
  ): Observable<boolean> {
    return from(this.confirmAsync(title, message, buttons));
  }

  async loadingAsync(message: string, timeout?: number): Promise<HTMLIonLoadingElement> {
    const safeString = new IonicSafeString(message);
    const messageContent = `<img src="assets/cs-loading.gif"> <span class="loading-text">${safeString.value}</span>`;
    const loading = await this.loadingController.create({
      message: messageContent ,
      duration: timeout,
      spinner: null,
      cssClass: 'cs-loading',
      translucent: true,
      showBackdrop: true,
    });
    await loading.present();

    return loading;
  }

  public loading(
    message: string,
    timeout?: number
  ): Observable<HTMLIonLoadingElement> {
    return from(this.loadingAsync(message, timeout));
  }

  public async dismissLoading() {
    try {
      await this.loadingController.dismiss();
    } catch (error) {
    }
  }

  async generic(
    title: string,
    message: string,
    buttonLabel = 'OK'
  ) {
    const alert = await this.alertController.create({
      header: title,
      message: new IonicSafeString(message),
      buttons: [buttonLabel]
    });

    await alert.present();
  }

  async successAsync() {
    const modal = await this.modalController.create({
      component: SuccessModalComponent,
      showBackdrop: false,
      cssClass: 'modal-background',
      animated: false
    });

    await modal.present();
    setTimeout(() => {
      modal.dismiss();
    }, 2000);
    const result = await modal.onDidDismiss();

    return result;
  }

  public success() {
    return from(this.successAsync());
  }
}
