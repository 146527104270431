import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NativeFeatureNotifierService {

  constructor(
    private ionicDialogService: IonicDialogService,
  ) { }

  notify(): void {
    const iosUrl = 'https://apps.apple.com/us/app/connectsense/id924559441';
    const androidUrl = 'https://play.google.com/store/apps/details?id=com.gridconnect.connectsense';

    this.ionicDialogService.generic(
      'Mobile App Required',
      `This feature is only accessible from a mobile device.
      Please download the ConnectSense app on
      <a href=${iosUrl}>iOS</a> or <a href=${androidUrl}>Android</a>
      to continue.`
    );
  }
}
