export type CallbackID = string;

export type ProvisioningCallback = (
  message: String | null,
  statusCode: String | null,
  err?: any,
) => void;

export interface BlufiProvisioningPlugin {
  pairDevice(input: {bleIdentifier: string, proof: string}): Promise<any>;
  requestNetworks(): Promise<any>;
  closeConnection(): Promise<any>;
  provision(
    input: {
      proof: string,
      claimCode: string,
      accountId: string,
      environment: string,
      ssid: string,
      password: string
    },
    callback: ProvisioningCallback
  ): Promise<CallbackID>;
}
