import { Capacitor } from '@capacitor/core';
import { switchMap, filter, take, flatMap, pluck } from 'rxjs/operators';
import { DeviceService, Device, Store, CSRebooter } from '@connectsense/iot8020-library';
import { isURL } from 'validator';
import { IonicDialogService } from './../services/ionic-dialog.service';
import { IonItemSliding, PickerController, NavController } from '@ionic/angular';
import { ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'app-reboot-addresses',
  templateUrl: './reboot-addresses.component.html',
  styleUrls: ['./reboot-addresses.component.scss']
})
export class RebootAddressesComponent implements OnInit {
  @ViewChildren(IonItemSliding) slidingItems: QueryList<IonItemSliding>;

  platform = Capacitor.getPlatform();

  loading = true;
  silentUpdating = false;
  isWaitingToNavBack = false;

  targetAddresses: string[] = [];
  outageCondition = false;
  deviceId = '';

  device: CSRebooter;

  forcingRender = {
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
  }

  addressesHint = 'Identify up to 5 addresses to ping to determine if your devices is offline.';
  outageConditionHint = 'Choose whether to reboot if ANY of the addresses are unreachable or if ALL addresses are unreachable.';

  constructor(
    private route: ActivatedRoute,
    private navController: NavController,
    private pickerController: PickerController,
    private ionicDialogService: IonicDialogService,
    private deviceService: DeviceService,
    private store: Store,
  ) {}

  get outageConditionLabel(): string {
    return this.outageCondition ? 'Any' : 'All';
  }

  ngOnInit(): void {
    this.route.paramMap
    .pipe(
      switchMap((params: Params) => {
        this.deviceId = params.get('deviceId');
        return this.store.changes.pipe(pluck(Store.Keys.Devices));
      }),
      filter(Boolean),
      take(1),
      flatMap((devices: Device[]) => devices),
      filter(({ deviceId }: Device) => deviceId === this.deviceId),
    )
    .subscribe((device: Device) => {
        this.loading = false;

        this.targetAddresses = device['config'].target_addrs;
        this.outageCondition = device['config'].any_fail_logic;
        this.device = device as CSRebooter;
      }, err =>  {}
    );
  }

  async openOutageConditionModal(event) {
    event.preventDefault();
    event.stopPropagation();

    const pickerOptions = [
      { text: 'Any', value: true },
      { text: 'All', value: false }
    ];

    const currentValue = this.outageCondition;
    const selectedIndex = pickerOptions.findIndex(option => option.value === currentValue);

    const picker = await this.pickerController.create({
      columns: [
        {
          name: 'outageCondition',
          selectedIndex: selectedIndex,
          options: pickerOptions
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Confirm',
          handler: (selection) => {
            this.outageCondition = selection.outageCondition.value;
            this.saveConfig();
          },
        },
      ],
    });

    await picker.present();
  }

  trackByFn(index: number, item: any): any {
    return index;
  }

  updateAddress(event, address, index) {
    this.silentUpdating = true;

    const sanitizedAddress = this.sanitizeAddress(event.target.value);
    this.targetAddresses[index] = sanitizedAddress;
    this.forcingRender[index] = true;
    setTimeout(() => this.forcingRender[index] = false);

    this.saveConfig();
  }

  sanitizeAddress(address: string): string {
    if (address === '') {
      return address;
    }

    const isValid = isURL(address);
    if (!isValid) {
      this.showErrorDialog();
      return '';
    }

    address = address.replace(/^(https?:\/\/)?(www\.)?/i, '');
    return address;
  }

  addAddress() {
    this.targetAddresses.push('');
  }

  deleteAddress(addressIndex, slidingItem: IonItemSliding) {
    slidingItem.close().then(() => {
      this.targetAddresses.splice(addressIndex, 1);

      this.saveConfig();
    });
  }

  showErrorDialog() {
    return this.ionicDialogService.generic(
      'Invalid URL',
      'The address you entered is not a valid URL. Please try again.'
    );
  }

  saveConfig() {
    const config = {
      target_addrs: this.targetAddresses,
      any_fail_logic: this.outageCondition
    };

    return this.deviceService.updateDeviceConfig(this.deviceId, config).subscribe(result => {
      this.silentUpdating = false;
      if (this.isWaitingToNavBack) {
        this.navController.back();
      }
    });
  }

  resetDefaults() {
    this.targetAddresses = ['google.com', 'facebook.com', 'wikipedia.org', 'amazon.com', 'baidu.com'];
    this.outageCondition = false;

    this.silentUpdating = true;
    this.saveConfig();
  }

  back() {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.hide();
    }

    if (this.silentUpdating) {
      this.isWaitingToNavBack = true;
    } else {
      this.navController.back();
    }
  }
}
