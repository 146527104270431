<ion-app [ngClass]="{'safe-area': shouldMarginSafeArea}">
  <ion-router-outlet id="sidenav-content"></ion-router-outlet>

  <ion-tabs>
    <ion-tab-bar slot="bottom" [ngClass]="{'hidden-tabs': shouldHideTabs}">
      <ion-tab-button *ngFor="let link of tabs"
        [tab]="link.url"
      >
        <app-icon [name]="link.icon" [useDefault]="link.useDefault"></app-icon>
        <ion-label>{{link.label}}</ion-label>
      </ion-tab-button>
    </ion-tab-bar>
  </ion-tabs>
</ion-app>
