<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button defaultHref="" (click)="submit()"></ion-back-button>
  </ion-buttons>

  <ion-title>{{title}}</ion-title>
</ion-toolbar>

<ion-content>
  <app-outlet-type-picker *ngIf="selectorType === 'outlet'" [(type)]="value"></app-outlet-type-picker>
  <app-room-picker *ngIf="selectorType === 'room'" [(value)]="value" [(homes)]="array"></app-room-picker>
  <app-rebooter-type-picker *ngIf="selectorType === 'rebooter'" [(type)]="value"></app-rebooter-type-picker>
</ion-content>
