import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { RebooterType, RebooterTypes } from '../models/rebooter-types'

@Component({
  selector: 'app-rebooter-type-picker',
  templateUrl: './rebooter-type-picker.component.html',
  styleUrls: ['./rebooter-type-picker.component.scss']
})
export class RebooterTypePickerComponent implements OnInit {
  @Input() type: number;
  @Output() typeChange = new EventEmitter<number>();
  readonly rebooterTypes = RebooterTypes;

  isSelected(id: number): boolean {
    return this.type === id;
  }

  selectType(type: RebooterType) {
    this.type = type.outlet1_type;
    this.typeChange.emit(type.outlet1_type);
  }

  ngOnInit(): void {
    this.type = this.type || 0;
  }
}
