import { Component, EventEmitter, Output, ViewChild, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-date-range-switcher',
  templateUrl: './date-range-switcher.component.html',
  styleUrls: ['./date-range-switcher.component.css']
})
export class DateRangeSwitcherComponent implements AfterViewInit {
  dataRange: { label: string, value: number }[] = [
    { label: 'Past 24 Hours', value: 0 },
    { label: 'Past 48 Hours', value: 1 },
    { label: 'Past 72 Hours', value: 2 }
  ];
  @Output() onRangeSelected = new EventEmitter<number>();

  dateRangeChanged(e): void {
    this.onRangeSelected.emit(+e);
  }

  ngAfterViewInit() {
    this.dataRange[0].label = 'Past 24 Hours';
    this.dataRange[1].label = 'Past 48 Hours';
    this.dataRange[2].label = 'Past 72 Hours';
  }
}
