<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button defaultHref="../"></ion-back-button>
  </ion-buttons>

  <ion-title>Reboot History</ion-title>
</ion-toolbar>

<ion-content>
  <div class="loading-container" *ngIf="loading">
    <app-loading-indicator [condition]="true" message="Loading events"></app-loading-indicator>
  </div>

  <ion-list *ngIf="!loading">
    <ion-item *ngFor="let event of eventsDataSource" [lines]="itemLines">
      <ion-label class="event-name">{{event.eventName}}</ion-label>
      <ion-note class="timestamp" slot="end">{{event.timestamp * 1000 | friendlyDate: true}}</ion-note>
    </ion-item>
  </ion-list>
</ion-content>
