<form>
  <ion-list>
    <ion-list-header>
      Network list
    </ion-list-header>
    <ion-radio-group [(ngModel)]="selectedNetwork.ssid" name="networkList">
      <ion-item *ngFor="let ap of accessPoints">
        <div class="icon-wrap wifi-{{getNetworkStrength(ap)}}" slot="start">
          <div class="wifi-icon"></div>
        </div>
        <ion-label>
          {{ap.ssid}}
        </ion-label>
        <ion-radio [value]="ap.ssid" (change)="knownNetworkSelected($event)"></ion-radio>
      </ion-item>
      <ion-item>
        <app-icon name="wifi" slot="start"></app-icon>
        <ion-label>Other</ion-label>
        <ion-radio (change)="otherChanged($event)"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>

  <ion-item>
    <ion-label position="floating">Name</ion-label>
    <ion-input
      #networkName
      placeholder="Name"
      [(ngModel)]="selectedNetwork.ssid"
      name="networkName"
      (keyup.enter)="focusInput(passwordInput)"
      [disabled]="userSelectedKnownNetwork"></ion-input>
  </ion-item>

  <ion-item>
    <ion-label position="floating">Password</ion-label>
    <ion-input
      #networkPassword
      [(ngModel)]="selectedNetwork.password"
      name="networkPassword"
      type="password"
      enterkeyhint="enter"
      (keyup.enter)="sendNetworkCredentials($event)"></ion-input>
  </ion-item>

  <ion-button expand="block" (click)="sendNetworkCredentials($event)" [disabled]="disableRegisterButton()">Next</ion-button>
</form>
