import { Directive } from '@angular/core';
import { NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { PasswordPolicy, charsets } from 'password-sheriff';
import { format } from 'util';

@Directive({
  selector: '[appValidatePasswordStrength]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: ValidatePasswordStrengthDirective, multi: true }
  ]
})
export class ValidatePasswordStrengthDirective implements Validator {
  rules = {
    length: { minLength: 8 },
    containsAtLeast: {
      atLeast: 3,
      expressions: [
        charsets.lowerCase,
        charsets.upperCase,
        charsets.numbers,
        charsets.specialCharacters
      ]
    }
  };
  policy = new PasswordPolicy(this.rules);

  validate({ value }: { value: string }): ValidationErrors | null {
    const { verified, rules } = this.policy.missing(value);

    if (verified) { return null; }

    const failingRules = rules.filter(rule => !rule.verified);
    const errors = failingRules.map(rule => {
      const message = format(rule.message, ...rule.format);

      return { message, items: rule.items };
    });

    return { strength: errors };
  }

}
