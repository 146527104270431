import { DeviceTypes } from '@connectsense/iot8020-library';
import { Component, OnInit, Input } from '@angular/core';
import { HomekitDevice } from '../models/homekitDevice';

@Component({
  selector: 'app-homekit-list-item',
  templateUrl: './homekit-list-item.component.html',
  styleUrls: ['./homekit-list-item.component.scss']
})
export class HomekitListItemComponent {
  @Input() hkDevice: HomekitDevice;

  constructor() { }

  get deviceImage(): string {
    switch (this.hkDevice.deviceType) {
      case DeviceTypes.CSSmartOutlet:
        return 'smart-outlet';
      case DeviceTypes.CSInWallOutlet:
        return 'in-wall-outlet';
    }
  }

}
