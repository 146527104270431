<ion-item
  [lines]="itemLines"
  [ngClass]="noPadding ? 'no-padding' : ''"
  [button]="isButton"
  [detail]="false"
>
  <ion-label *ngIf="label"
    [position]="position"
    [ngClass]="{'label-disabled': labelDisabled, 'label-width': useMinLabelWidth}">
    {{label}}
  </ion-label>
  <ion-input *ngIf="!readonlyValue" #input
    [(ngModel)]="value"
    [ngClass]="noPadding ? 'no-padding' : ''"
    [type]="inputType"
    [name]="name"
    [maxlength]="maxlength"
    [placeholder]="placeholder"
    (keydown.enter)="blurInput(input)"
    autocapitalize="words"
    enterkeyhint="done">
  </ion-input>
  <ion-input *ngIf="readonlyValue"
    [ngClass]="[
      manualPosition ? 'right-align' : '',
      noPadding ? 'no-padding' : '',
      customClass ? customClass : ''
    ]"
    [type]="inputType"
    tabindex="-1"
    [value]="readonlyValue"
    readonly>
  </ion-input>
  <ion-note *ngIf="hint"
    slot="helper"
    class="helper">
    {{hint}}
  </ion-note>
  <ion-note *ngIf="hint"
    slot="error">
    {{hint}}
  </ion-note>
  <ion-button *ngIf="helperButtonText"
    tabindex="-1"
    (click)="displayHelpDialog($event)"
    slot="end"
    fill="clear"
    color="medium"
    class="ion-no-margin">
    <ion-icon slot="icon-only" name="help-circle-outline"></ion-icon>
  </ion-button>
</ion-item>
