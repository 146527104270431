import { LoadingMessage, NotificationMessage, DialogTitle, DialogMessage } from './../../assets/strings';
import { IonicNotificationService } from './../services/ionic-notification.service';
import { IonicDialogService } from './../services/ionic-dialog.service';

import { Component, Input, ViewContainerRef, ViewChild, ViewChildren, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { forkJoin, Observable, of } from 'rxjs';
import { pluck, filter, flatMap, switchMap, take } from 'rxjs/operators';

import { Device, DeviceService, Store, DeviceFeature } from '@connectsense/iot8020-library';

import { Home } from '../models/home';
import { HomeService } from '../home/services/home.service';
import { RebooterTypes, RebooterType } from '../models/rebooter-types';
import { IonRange } from '@ionic/angular';

@Component({
  selector: 'app-device-detail-settings',
  templateUrl: './device-detail-settings.component.html',
  styleUrls: ['./device-detail-settings.component.css']
})
export class DeviceDetailSettingsComponent implements OnInit {
  @ViewChild('detectionTimeSlider') detectionTimeSlider: IonRange;
  @ViewChild('delaySlider') delaySlider: IonRange;
  device: Device;
  deviceId: string;
  loading = true;
  disableForm = false;
  homes: Home[] = [];
  RebooterTypes = RebooterTypes;
  DeviceFeature = DeviceFeature;
  tabs = ['General', 'Advanced'];
  selectedTab = this.tabs[0];

  constructor(
    private deviceService: DeviceService,
    private ionicDialogService: IonicDialogService,
    private ionicNotificationService: IonicNotificationService,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService,
    private store: Store
  ) {}

  onRecordingIntervalSliderChanged($event) {
    this.device.recording_interval = $event.detail.value;
  }

  onSubmit($event) {
    $event.preventDefault();
    this.disableForm = true;

    forkJoin(
      this.deviceService.updateDevice(this.device),
      this.homeService.changeDeviceRoom(this.deviceId, this.device.roomId)
    ).subscribe(() => {
        this.disableForm = false;
        this.ionicNotificationService.show(NotificationMessage.DeviceUpdated);
      }, error => {
        this.disableForm = false;
        console.error('An error occurred', error)
        this.ionicNotificationService.show(NotificationMessage.GenericError);
      });
  }

  onSubmitAdvanced($event) {
    $event.preventDefault();
    this.disableForm = true;

    this.updateConfig()
    .subscribe(() => {
      this.disableForm = false;
      this.ionicNotificationService.show(NotificationMessage.DeviceUpdated);
    }, error => {
      this.disableForm = false;
      console.error('An error occurred', error)
      this.ionicNotificationService.show(NotificationMessage.GenericError);
    });
  }

  selectType(selectedType: number) {
    const rebooterType = RebooterTypes.find((type) => {
      return type.outlet1_type === selectedType;
    });

    this.device['config'].outlet1_on_delay = rebooterType.outlet1_on_delay;
    this.delaySlider.value = this.device['config'].outlet1_on_delay;
  }

  setRangeValue(event, configType) {
    this.device['config'][configType] = event.detail.value;
  }

  updateConfig(): Observable<boolean> {
    if (!this.device.isCSRebooter()) {
      return of(true);
    }

    return this.deviceService.updateDeviceConfig(this.deviceId, this.device['config']);
  }

  onDeactivateButtonClicked($event) {
    $event.preventDefault();

    this.disableForm = true;

    this.ionicDialogService
      .confirm(
        DialogTitle.DeviceDelete,
        DialogMessage.DeviceDelete
      )
      .subscribe(res => {
        this.handleDialogClose(res);
      });
  }

  handleDialogClose(res?) {
    this.disableForm = false;

    if (res) {
      this.ionicDialogService.loading(LoadingMessage.DeviceDelete);
      this.deviceService.removeDevice(this.device.deviceId)
        .subscribe(deviceRemoved => {
          this.ionicDialogService.dismissLoading();
          if (deviceRemoved) {
            this.ionicNotificationService.show(NotificationMessage.DeviceRemoved);
            this.router.navigateByUrl('/devices');
          }
        }, error => {
          this.ionicDialogService.dismissLoading();
          this.disableForm = false;
          console.error('An error occurred', error.message || error);
        });
    }
  }

  displayHelpDialog(event: Event): void {
    event.stopPropagation();
    this.ionicDialogService.generic(DialogTitle.DeviceNameHelp, DialogMessage.DeviceNameHelp);
  }

  getHomes(): void {
    this.homeService.getHomes().subscribe(homes => {
      this.homes = homes;
    });
  }

  setSliderValues() {
    if (this.selectedTab === this.tabs[0]) {
      return;
    }

    this.detectionTimeSlider.value = this.device['config'].detection_time;
    this.delaySlider.value = this.device['config'].outlet1_on_delay;
  }

  ngOnInit() {
    this.route.paramMap
    .pipe(
      switchMap((params: Params) => {
        this.deviceId = params.get('deviceId');

        return this.store.changes.pipe(pluck(Store.Keys.Devices));
      }),
      filter(Boolean),
      take(1),
      flatMap((devices: Device[]) => devices),
      filter(({ deviceId }: Device) => deviceId === this.deviceId),
    )
    .subscribe((device: Device) => {
        this.device = device;
        this.loading = false;
      }, err => this.loading = false
    );

    this.getHomes();
  }
}
