import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { Router } from '@angular/router'

import { noop } from 'rxjs';

import { Device, DeviceFeature } from '@connectsense/iot8020-library';

import { OutletTypes } from '../../models/outlet-types';

@Component({
  selector: 'app-device-list-item',
  templateUrl: './device-list-item.component.html',
  styleUrls: ['./device-list-item.component.scss']
})
export class DeviceListItemComponent implements OnInit {
  @Input() device: Device;
  @Input() selectable = false;
  @Input() selected: {
    outlet1: boolean,
    outlet2: boolean
  } = {
    outlet1: false,
    outlet2: false
  };
  @Input() shouldExecuteCommand = true;
  @Input() manualLines: string;
  @Input() rebooterEnabled: Boolean = true;
  @Input() saveEnabled: Boolean = true;
  @Output() outletSelected = new EventEmitter<{
    deviceId: string,
    actions: {}[]
  }>();
  DeviceFeature = DeviceFeature;
  OutletTypes = OutletTypes;
  itemLines = 'none';
  get outlets(): string[] {
    return this.device.hasFeature(DeviceFeature.DualOutlets) ? ['outlet1', 'outlet2'] : ['outlet1'];
  }

  constructor(private router: Router) {}

  ngOnInit() {
    if (this.manualLines) {
      this.itemLines = this.manualLines;
    }

    if (this.device.isCSRebooter()) {
      this.selected['outlet1_active'] = this.selected['outlet1_active'] ? this.selected['outlet1_active'] : false;
      this.selected['outlet1_reboot'] = this.selected['outlet1_reboot'] ? this.selected['outlet1_reboot'] : false;
    }
  }

  navigate(outlet?: string) {
    if (this.selectable) { return; }

    const device = this.device;
    this.router.navigate(['/devices', device.deviceId], { queryParams: { outlet } });
    // TODO: Enable this when all devices have real data
    // device.online ? this.router.navigate(['/devices', device.deviceId]) : this.router.navigateByUrl('/support');
  }

  handleOutletSelection(): void {
    const actions = Object.keys(this.selected).reduce((accumulatedActions, key) => {
      if (this.selected[key]) {
        let action;

        if (this.device.isCSRebooter()) {
          if (this.selected['outlet1_reboot']) {
            action = { outlet1_active: true, outlet1_reboot: true };
            accumulatedActions = [action];
          } else {
            action = {};
            accumulatedActions = [];
          }
        } else {
          action = { [`${key}_active`]: this.device.data[`${key}_active`] };
          accumulatedActions  = [action, ...accumulatedActions];
        }
      }

      return accumulatedActions;
    }, []);

    this.outletSelected.emit({ deviceId: this.device.deviceId, actions });
  }

  onOutletStateChange(value: boolean, deviceId: string, outlet: string): void {
    this.device.data[outlet + '_active'] = value;
    this.handleOutletSelection();
  }
}
