import { Injectable } from '@angular/core';

import { throwError, Observable, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { DeviceService, Device, DeviceFeature } from '@connectsense/iot8020-library';

import { daysOfWeek } from '../../models/days-of-week';
import { Schedule } from '../../models/schedule';
import { Day } from '../../models/day';
import { Actions } from '../../models/actions';
import { ApiService } from '../../services/api.service';

@Injectable()
export class ScheduleService {

  constructor(
    private apiService: ApiService,
    private deviceService: DeviceService
  ) { }

  convertScheduleToDays(schedule: Schedule, scheduleDays: Day[], devices: Device[]): Day[] {
    const { minutes, hours, days } = this.parseCronExpression(schedule.schedule);
    const timestamp = new Date().setHours(hours, minutes);

    return scheduleDays.map((day, index) => {
      if (!days.includes(index)) { return day; }

      let tempDeviceIds = Object.keys(schedule.action);
      const deviceIds = tempDeviceIds.some(deviceId => !deviceId.includes('CMR')) ? undefined : tempDeviceIds;

      const newTime = {
        timestamp,
        actions: {
          on: this.getActionDevices(schedule, 'active', true, devices),
          off: this.getActionDevices(schedule, 'active', false, devices),
          reboot: this.getActionDevices(schedule, 'reboot', true, devices)
        },
        schedules: [schedule.scheduleId],
        deviceIds: deviceIds,
      };

      if (!newTime.actions.on.length && !newTime.actions.off.length) {
        return day;
      }

      day.times = [
        newTime,
        ...day.times
      ].sort((a, b) => a.timestamp - b.timestamp);

      return day;
    });
  }

  getDays(): Observable<Day[]> {
    return forkJoin([
      this.getSchedules(),
      this.deviceService.getDevices()
    ]).pipe(map(([schedules, devices]) => {
      return schedules.reduce((scheduleDays, schedule) => {
        return this.convertScheduleToDays(schedule, scheduleDays, devices);
      }, this.initializeScheduleDays())
      .filter(({times}) => times.length > 0);
    }));
  }

  getActionDevices(schedule: Schedule, desiredKey: string, desiredState: boolean, devices: Device[]): string[] {
    return Object.keys(schedule.action)
    .filter((deviceId: string) => !!this.findDevice(deviceId, devices))
    .reduce((deviceNames: string[], deviceId: string) => {
      const actions = schedule.action[deviceId];

      const newDeviceNames = actions.filter(action => {
        return !!Object.keys(action).filter(key => key.includes(desiredKey) && action[key] === desiredState).length;
      }).map(action => {
        const [actionTarget] = Object.keys(action);
        const [outlet] = actionTarget.split('_');
        const targetDevice = this.findDevice(deviceId, devices);

        if (targetDevice.hasFeature(DeviceFeature.OutletName)) {
          return targetDevice.data[`${outlet}_name`];
        } else {
          return targetDevice.name;
        }
      });

      return [...deviceNames, ...newDeviceNames];
    }, []);
  }

  getSchedules(deviceId?: string): Observable<Schedule[]> {
    return this.apiService.getAsStream('/schedules').pipe(
      map((schedules: Schedule[]) => {
        if (!deviceId) { return schedules; }

        return schedules.filter(schedule => !!schedule.action[deviceId]);
      }));
  }

  initializeScheduleDays(): Day[]  {
    return new Array(daysOfWeek.length).fill({}).map((_, index) => {
      return {
        label: daysOfWeek[index],
        times: []
      };
    })
  }

  parseCronExpression(expression: string): {
    minutes: number,
    hours: number,
    days: number[]
  } {
    const [minutes, hours, , , days] = expression.split(' ');
    let parsedDays: number[];

    if (days.includes('-')) {
      const [start, end] = days.split('-');

      parsedDays = Array(Number(end) - Number(start) + 1).fill(undefined).map((_, index) => {
        return Number(start) + index;
      });
    } else {
      parsedDays = days.split(',').map(day => Number(day));
    }

    return {
      minutes: Number(minutes),
      hours: Number(hours),
      days: parsedDays
    };
  }

  findDevice(deviceId: string, devices: Device[]): Device {
    return devices.find(device => device.deviceId === deviceId);
  }

  buildCronExpression(time: string, days: string): string {
    const [hours, minutes] = time.split(':');

    return `${minutes} ${hours} * * ${days}`;
  }

  createSchedule(time: string, days: string, actions): Observable<Schedule> {
    const schedule: Schedule = {
      schedule: this.buildCronExpression(time, days),
      action: actions,
      tz: this.getTimezone()
    }

    return this.apiService.postAsStream('/schedules', schedule).pipe(
      map(response => response.schedule),
      catchError(error => throwError(error.json()))
    );
  }

  getSchedule(id: string): Observable<Schedule> {
    return this.apiService.getAsStream(`/schedules/${id}`);
  }

  updateSchedule(id: string, time: string, days: string, actions: Actions): Observable<Schedule> {
    if (!time) { return throwError('A valid time is required'); }

    const schedule: Schedule = {
      schedule: this.buildCronExpression(time, days),
      action: actions,
      tz: this.getTimezone()
    }

    return this.apiService.putAsStream(`/schedules/${id}`, schedule);
  }

  deleteSchedule(id: string): Observable<{success: boolean}[]> {
    return this.apiService.deleteAsStream(`/schedules/${id}`);
  }

  getTimezone(): string {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    return timeZone.replace(/\//g, '.');
  }
}
