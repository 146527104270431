import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, concatMap } from 'rxjs/operators';

import { Device } from '@connectsense/iot8020-library';

import { Home } from '../../models/home';
import { Room } from '../../models/room';
import { ApiService } from '../../services/api.service';

@Injectable()
export class HomeService {
  readonly BASE_PATH = '/graphql';
  readonly DEFAULT_HOME_NAME = 'Default Home';
  readonly DEFAULT_ROOM_NAME = 'Default Room';
  get timezone(): string {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    return timeZone;
  }

  constructor(private apiService: ApiService) {}

  createHome({ name }: Home): Observable<Home> {
    const query = `mutation {
      createHome(name: "${name}" tz: "${this.timezone}") {
        homeId, name
      }
    }`;

    return this.apiService.postAsStream(this.BASE_PATH, query).pipe(
    map(({ data: { createHome } }) => createHome || {}));
  }

  getHome(homeId: string): Observable<Home> {
    const query = `{
      homes(homeId: "${homeId}") {
        homeId name customRate
      }
    }`;
    const path = `${this.BASE_PATH}?query=${encodeURIComponent(query)}`;

    return this.apiService.getAsStream(path).pipe(
    map(({ data: { homes } }) => homes[0]));
  }

  getHomes(): Observable<Home[]> {
    const query = `{
      homes {
        homeId name rooms {
          name roomId devices {
            deviceId
          }
        }
      }
    }`;
    const path = `${this.BASE_PATH}?query=${encodeURIComponent(query)}`;

    return this.apiService.getAsStream(path).pipe(
    map(({ data: { homes } }) => homes.sort(this.sortAlphabetically) || []));
  }

  updateHome({ homeId, name, customRate }: Home): Observable<Home> {
    let args = `homeId: "${homeId}", name: "${name}", tz: "${this.timezone}"`;
    if (customRate) { args += `, customRate: "${customRate}"`; }
    const query = `mutation {
      updateHome(${args}) {
        name
      }
    }`;

    return this.apiService.postAsStream(this.BASE_PATH, query).pipe(
    map(({ data: { updateName } }) => updateName || {}));
  }

  deleteHome({ homeId }: Home): Observable<Home> {
    const query = `mutation { deleteHome(homeId: "${homeId}") { homeId, name } }`;

    return this.apiService.postAsStream(this.BASE_PATH, query).pipe(
    map(({ data: { deleteHome} }) => deleteHome || {}));
  }

  createRoom({ homeId, name, devices }: Room): Observable<Room> {
    const deviceIds = JSON.stringify(devices.map(({ deviceId }) => deviceId));
    const query = `mutation {
      createRoom(name: "${name}", homeId: "${homeId}", devices: ${deviceIds}) {
        roomId, name
      }
    }`;

    return this.apiService.postAsStream(this.BASE_PATH, query).pipe(
    map(({ data: { createRoom } }) => createRoom || {}));
  }

  getRoom(id: string): Observable<Room> {
    const query = `{ rooms(roomId: "${id}") { roomId homeId name devices { deviceId name } } }`;
    const path = `${this.BASE_PATH}?query=${encodeURIComponent(query)}`;

    return this.apiService.getAsStream(path).pipe(
    map(({ data: { rooms: [ room ] } }) => room || {}));
  }

  updateRoom({ roomId, name, devices }: Room): Observable<Room> {
    const deviceIds = JSON.stringify(devices.map(({ deviceId }) => deviceId));
    const query = `mutation {
      updateGroup(groupId: "${roomId}", name: "${name}", devices: ${deviceIds}) {
        groupId
      }
    }`;

    return this.apiService.postAsStream(this.BASE_PATH, query).pipe(
    map(({ data: { updateGroup } }) => updateGroup || {}));
  }

  deleteRoom({ roomId }: Room): Observable<Home> {
    const query = `mutation { deleteRoom(roomId: "${roomId}") { roomId, name } }`;

    return this.apiService.postAsStream(this.BASE_PATH, query).pipe(
    map(({ data: { deleteRoom} }) => deleteRoom || {}));
  }

  changeDeviceRoom(deviceId: string, roomId: string): Observable<boolean> {
    const query = `mutation {
      addDevicesToRoom(roomId: "${roomId}", devices: ["${deviceId}"]) {
        roomId
      }
    }`;

    return this.apiService.postAsStream(this.BASE_PATH, query).pipe(
    map(({ data: { addDevicesToRoom } }) => addDevicesToRoom || {}));
  }

  associateHomesWithDevices(homes: Home[], devices: Device[]): Home[] {
    return homes.map((home: Home) => {
      home.rooms.sort(this.sortAlphabetically);
      home.rooms = home.rooms.map((room: Room) => {
        room.devices = room.devices.map((device: Device) => {
          return devices.find(({deviceId}) => deviceId === device.deviceId);
        }).filter(device => !!device);

        return room;
      });

      return home;
    });
  }

  createDefaultHome(devices: Device[]) {
    const home: Home = {
      name: this.DEFAULT_HOME_NAME,
      devices: [],
      homeId: '',
      rooms: []
    };

    return this.createHome(home).pipe(
      concatMap(({ homeId }) => {
        const room: Room = {
          name: this.DEFAULT_ROOM_NAME,
          devices,
          homeId,
          roomId: ''
        };

        return this.createRoom(room);
      })
    );
  }

  sortAlphabetically = (a, b) => {
    const textA = a.name.toUpperCase();
    const textB = b.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }
}
