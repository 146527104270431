import { registerPlugin } from '@capacitor/core';

import type { TextRecognitionPlugin } from './definitions';

const TextRecognition = registerPlugin<TextRecognitionPlugin>(
  'TextRecognition'
);

export * from './definitions';
export { TextRecognition };
