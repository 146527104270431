import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Home } from '../../models/home';
import { FilterEmptyRoomsPipe } from './filter-empty-rooms.pipe';

@Pipe({
  name: 'filterEmptyHomes',
})
@Injectable({
  providedIn: 'root'
})
export class FilterEmptyHomesPipe implements PipeTransform {

  transform(homes: Home[]): Home[] {
    const emptyRoomsPipe = new FilterEmptyRoomsPipe();

    return homes.filter(({ rooms }) => {
      return !!emptyRoomsPipe.transform(rooms).length;
    });
  }

}
