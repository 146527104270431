import { NotificationMessage } from './../../assets/strings';
import { IonicNotificationService } from './../services/ionic-notification.service';
import { RebootService } from './../services/reboot.service';
import { thingShadow } from 'aws-iot-device-sdk';
import { Router } from '@angular/router';
import { Device, DeviceFeature } from '@connectsense/iot8020-library';
import { Component, Input, DoCheck, KeyValueDiffers, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss']
})
export class DeviceCardComponent implements DoCheck, OnInit {
  @Input() device: Device;
  @Input() status: string;
  @Input() shadow: thingShadow;
  differ: any;
  isRebooting = false;

  DeviceFeature = DeviceFeature;
  get outlets(): string[] {
    return this.device.hasFeature(DeviceFeature.DualOutlets) ? ['outlet1', 'outlet2'] : ['outlet1'];
  }

  constructor(
    private router: Router,
    private rebootService: RebootService,
    private differs:  KeyValueDiffers,
    private ionicNotificationService: IonicNotificationService,
  ) { this.differ = differs.find([]).create(); }

  ngOnInit(): void {
    this.rebootService.rebootingDevices.subscribe((deviceId) => {
      if (!deviceId) {
        return;
      }

      if (deviceId.includes('FAILED') && deviceId.includes(this.device.deviceId)) {
        this.isRebooting = false;
      } else if (this.rebootService.rebootingDeviceIds.includes(this.device.deviceId)) {
        this.isRebooting = true;
      }
    });
  }

  ngDoCheck(): void {
    if (!this.device.isCSRebooter() || !this.rebootService.rebootingDeviceIds.includes(this.device.deviceId)) {
      return;
    }
    const changes = this.differ.diff(this.device);
    if (changes) {
      changes.forEachChangedItem((change) => {
        if (change.key === 'data' && !change.currentValue.offline) {
          this.isRebooting = false;
          this.ionicNotificationService.show(NotificationMessage.RebootFinish);
        }
      });
    }
  }

  navigate(outlet?: string) {
    const device = this.device;
    this.router.navigate(['/devices', device.deviceId], { queryParams: { outlet } });
  }

  didClickCardControl(event: Event) {
    event.stopPropagation();
  }

}
