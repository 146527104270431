<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button defaultHref="/devices"></ion-back-button>
  </ion-buttons>

  <ion-title>{{deviceName}}</ion-title>

  <ion-buttons slot="end" *ngIf="device">
    <ion-button
      [routerLink]="['/rules']"
      [queryParams]="{deviceId: device.deviceId}"
      *ngIf="device.hasFeature(DeviceFeature.CloudRules)"
    >
      <ion-label>Add rule</ion-label>
    </ion-button>
      <ion-button
      *ngIf="router.url.includes('schedules')"
      [routerLink]="'/devices/' + this.deviceId + '/schedules/new'"
    >
      <app-icon name="add"></app-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-content>
  <app-loading-indicator [message]="LoadingMessage.Detail" [condition]="loading"></app-loading-indicator>

  <ng-container *ngIf="isRebooting">
    <ion-backdrop
      [visible]="true"
      class="rebooting-backdrop"
      [stopPropagation]="true">
    </ion-backdrop>

    <ion-card class="rebooting-card">
      <span class="rebooting-card-text">Rebooting...</span>
    </ion-card>
  </ng-container>

  <div *ngIf="device && !loading">
    <div *ngIf="device.data">
      <div class="device-info">
        <app-device-summary
          [device]="device"
          [outlet]="outlet"
          [shadow]="shadows"
        ></app-device-summary>

        <ion-item
          *ngIf="device.isCSRebooter() && lastRebootTimestamp"
          [routerLink]="['./reboot-history']"
          lines="none"
          class="events-button"
          [disabled]="!lastRebootTimestamp"
          [detail]="lastRebootTimestamp ? 'true' : 'false'"
          button
        >
          <ion-label>
            <h3 class="last-reboot-label">History</h3>
            <p class="last-reboot" readonly>Last rebooted {{lastRebootTimestamp * 1000 | friendlyDate}}</p>
          </ion-label>
        </ion-item>

        <ion-item
          *ngIf="device.isCSRebooter() && !lastRebootTimestamp"
          lines="none"
          class="events-button"
          [disabled]="!lastRebootTimestamp"
          [detail]="lastRebootTimestamp ? 'true' : 'false'"
          button
        >
          <ion-label>
            <h3 class="last-reboot-label">History</h3>
            <p class="last-reboot" readonly>No recent reboots</p>
          </ion-label>
        </ion-item>

        <ion-item
          *ngIf="device.hasFeature(DeviceFeature.UsageMetrics)"
          [routerLink]="['./usage/device', deviceId, outlet]"
          queryParamsHandling="merge"
          lines="none"
        >
          Energy usage history
        </ion-item>
      </div>

        <ion-item-divider></ion-item-divider>
      <ion-list class="settings-list">
        <ion-item
          *ngIf="device.isCSLampController()"
          [disabled]="!device.isConnected()"
          [routerLink]="['./schedules']"
          lines="none"
          class="schedules-link"
          [detail]="device.isConnected()"
        >
          Schedules
        </ion-item>

        <ion-item
          *ngIf="device.isCSRebooter() && rebooterSchedule"
          [disabled]="!device.isConnected()"
          [routerLink]="['./schedules/' + rebooterSchedule?.scheduleId]"
          lines="none"
          class="events-button"
          [detail]="device.isConnected()"
          button
        >
          <ion-label>
            <h3 [disabled]="!device.isConnected()" class="last-reboot-label">Schedule</h3>
            <p class="last-reboot" readonly>{{rebooterGlance}}</p>
          </ion-label>
        </ion-item>

        <ion-item
          *ngIf="device.isCSRebooter() && !rebooterSchedule"
          [disabled]="!device.isConnected()"
          [routerLink]="['./schedules/new']"
          lines="none"
          class="events-button"
          [detail]="device.isConnected()"
          button
        >
          <ion-label>
            <h3 class="last-reboot-label">Schedule</h3>
            <p class="last-reboot" readonly>No schedule defined</p>
          </ion-label>
        </ion-item>

        <ion-list-header>
          <ion-label>Settings</ion-label>
        </ion-list-header>

        <form [formGroup]="deviceDataFormGroup">
          <app-form-field
            *ngIf="device.hasFeature(DeviceFeature.DualOutlets)"
            [formControlName]="outlet + '_name'"
            name="name"
            maxlength="25"
            placeholder="Outlet Name"
            label="Outlet Name"
          ></app-form-field>

          <app-form-field
            *ngIf="!device.hasFeature(DeviceFeature.DualOutlets)"
            formControlName="deviceName"
            name="deviceName"
            maxlength="25"
            placeholder="Device Name"
            label="Name"
          ></app-form-field>

          <ion-item
            (click)="openSelectionModal('Select Room', roomSelectionType, device.roomId, 'homes')"
            [lines]="device.hasFeature(DeviceFeature.DualOutlets) ? itemLines : 'none'"
            button
          >
            <ion-label [position]="labelPosition">Room</ion-label>
            <ion-input [value]="roomLabel" readonly></ion-input>
          </ion-item>

          <ion-item
            *ngIf="device.hasFeature(DeviceFeature.DualOutlets)"
            (click)="openSelectionModal('Outlet Type', outletSelectionType, device.data[outlet + '_type'])"
            [lines]="itemLines"
            button
          >
            <ion-label [position]="labelPosition">Type</ion-label>
            <ion-input [value]="outletTypeLabel" readonly></ion-input>
          </ion-item>
        </form>

        <ng-container *ngIf="device.hasFeature(DeviceFeature.LEDBrightness)">
          <ion-item [lines]="itemLines">
            LED Brightness
            <ion-range
              [value]="device.data.brightness"
              (ionKnobMoveEnd)="onBrightnessChange($event.detail.value, device.deviceId)"
            ></ion-range>
          </ion-item>
        </ng-container>
        <ng-container *ngIf="device.hasFeature(DeviceFeature.DisablePhysicalControls)">
          <ion-item [lines]="none">
            <ion-label>Enable controls on device</ion-label>
            <ion-toggle
            [checked]="device.state.button_enabled"
            color="primary"
            id="button-enabled-checkbox"
            slot="end"
            (ionChange)="onButtonEnabledChange($event.detail.checked, device.deviceId)"
            ></ion-toggle>
          </ion-item>
        </ng-container>
      </ion-list>

      <ion-item-divider class="section-divider" *ngIf="platform === 'android'"></ion-item-divider>

      <ion-list>
        <ion-list-header>
          <ion-label>Advanced</ion-label>
        </ion-list-header>

        <form [formGroup]="deviceDataFormGroup">
          <app-form-field
            *ngIf="device.hasFeature(DeviceFeature.DualOutlets)"
            formControlName="deviceName"
            name="deviceName"
            maxlength="25"
            placeholder="Device Name"
            label="Device Name"
            helperButtonText="This name covers your entire device, not a single outlet"
          ></app-form-field>
        </form>

        <form *ngIf="device.isCSRebooter()" [formGroup]="rebooterAdvancedFormGroup">
          <ion-item
            (click)="openSelectionModal('Rebooter Type', rebooterSelectionType, device.config.outlet1_type)"
            [lines]="itemLines"
            button
          >
            <ion-label [position]="labelPosition">Device Type</ion-label>
            <ion-input [value]="rebooterTypeLabel" readonly></ion-input>
          </ion-item>

          <ng-container *ngIf="isRebooterV2; else legacyRebooter">
            <ion-item lines="none">
              <ion-label class="range-label" position="fixed">
                Power Cycle Time
              </ion-label>
              <ion-range
                formControlName="off_duration"
                name="off_duration"
                min="10" max="600" step="10"
              ></ion-range>
              <ion-note slot="end">
                {{rebooterAdvancedFormGroup.controls.off_duration.value}}
              </ion-note>
              <ion-note slot="helper">
                When rebooting, keep the power off for this many seconds.
              </ion-note>
            </ion-item>

            <ion-item [lines]="itemLines" class="rebooter-v2-toggle">
              <ion-label>Intelligent Reboot</ion-label>
              <ion-toggle
                formControlName="auto_reset"
                name="auto_reset"
                (click)="toggleRebootAccordion()"
              ></ion-toggle>
            </ion-item>

            <ion-accordion-group #intelligentRebootAccordion>
              <ion-accordion value="first">
                <div slot="content">
                  <ion-item lines="none">
                    <ion-label class="range-label" position="fixed">
                      Outage Time
                    </ion-label>
                    <ion-range
                      formControlName="outage_trigger_time"
                      name="outage_trigger_time"
                      min="2" max="10" step="1"
                    ></ion-range>
                    <ion-note slot="end">
                      {{rebooterAdvancedFormGroup.controls.outage_trigger_time.value}}
                    </ion-note>
                    <ion-note slot="helper">
                      Reboot after unsuccessfully reaching the monitored addresses for this long in minutes.
                    </ion-note>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label class="range-label" position="fixed">
                      Redetection Delay
                    </ion-label>
                    <ion-range
                      formControlName="detection_delay"
                      name="detection_delay"
                      min="0" max="10" step="1"
                    ></ion-range>
                    <ion-note slot="end">
                      {{rebooterAdvancedFormGroup.controls.detection_delay.value}}
                    </ion-note>
                    <ion-note slot="helper" class="bottom-note">
                      After restoring power, wait this long, in minutes, before monitoring for an internet connection.
                    </ion-note>
                  </ion-item>

                  <app-form-field
                    formControlName="max_reboots"
                    name="max_reboots"
                    [readonlyValue]="rebooterAdvancedFormGroup.controls.max_reboots.value == 0 ? 'No limit' : rebooterAdvancedFormGroup.controls.max_reboots.value"
                    maxlength="25"
                    placeholder="-"
                    label="Maximum Reboot Attempts"
                    [isButton]="true"
                    (click)="openMaxRebootModal($event)"
                  ></app-form-field>

                  <ion-item
                    [routerLink]="['./reboot-addresses']"
                    [queryParams]="{ deviceId: deviceId }"
                    [lines]="itemLines"
                    class="events-button"
                    detail="true"
                    button
                  >
                    <ion-label>
                      Monitored Addresses
                    </ion-label>
                  </ion-item>
                </div>
              </ion-accordion>
            </ion-accordion-group>

          </ng-container>

          <ng-template #legacyRebooter>
            <ion-item [lines]="itemLines">
              <ion-label>Intelligent Reboot</ion-label>
              <ion-toggle
                formControlName="auto_reset"
                name="auto_reset"
                (click)="toggleRebootAccordion()"
              ></ion-toggle>
            </ion-item>

            <ion-accordion-group #intelligentRebootAccordion>
              <ion-accordion value="first">
                <div slot="content">
                  <ion-item lines="none">
                    <ion-label class="range-label" position="fixed">
                      Detection Time
                    </ion-label>
                    <ion-range
                      formControlName="detection_time"
                      name="detection_time"
                      min="2" max="10" step="1"
                    ></ion-range>
                    <ion-note slot="end">
                      {{rebooterAdvancedFormGroup.controls.detection_time.value}}
                    </ion-note>
                    <ion-note slot="helper">
                      Reboot automatically after your device is offline for this long, in minutes.
                    </ion-note>
                  </ion-item>

                  <ion-item lines="none">
                    <ion-label class="range-label" position="fixed">
                      Reboot Delay
                    </ion-label>
                    <ion-range
                      formControlName="outlet1_on_delay"
                      name="outlet1_on_delay"
                      min="0" max="10" step="1"
                    ></ion-range>
                    <ion-note slot="end">
                      {{rebooterAdvancedFormGroup.controls.outlet1_on_delay.value}}
                    </ion-note>
                    <ion-note slot="helper" class="bottom-note">
                      After the above Detection Time, wait this much longer, in minutes, before rebooting your device.
                    </ion-note>
                  </ion-item>
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </ng-template>
        </form>

        <app-device-controls
          [device]="device"
          [firmwareUpdateAvailable]="firmwareUpdateAvailable"
          [shadow]="shadows"
          *ngIf="device.isCSOutlet()"
        ></app-device-controls>

        <app-ion-item-button color="danger" icon="close-circle" label="Remove Device" (click)="removeDevice()">
        </app-ion-item-button>
      </ion-list>
    </div>
  </div>

  <div *ngIf="errors.notFound || errors.server" class="error-wrapper">
    <h2>Sorry!</h2>
    <p *ngIf="errors.notFound">We couldn't find a device with the id of <strong>{{deviceId}}</strong></p>
    <p *ngIf="errors.server">We are having problems loading your device</p>
  </div>
</ion-content>
