<ion-toolbar>
  <ion-buttons slot="start">
    <ion-button routerLink="/devices">
      <app-icon name="chevron-back"></app-icon>
    </ion-button>
  </ion-buttons>

  <ion-title>Support</ion-title>
</ion-toolbar>

<p>Coming soon!</p>
