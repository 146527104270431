import { ErrorTitle, LoadingMessage, ErrorMessage } from './../../assets/strings';
import { PasswordResetComponent } from './../password-reset/password-reset.component';
import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService, ClientStorageKey, ClientStorageService, UserInfoService } from '@connectsense/iot8020-library';

import { CopilotEvent } from '../models/copilot-event';
import { CopilotBridge } from '@copilot-bridge';
import { Capacitor } from '@capacitor/core';
import { Auth } from '@auth';

import { Keyboard } from '@capacitor/keyboard';
import { App } from '@capacitor/app';
import { IonInput, ModalController } from '@ionic/angular';
import { ApiService } from 'app/services/api.service';

@Component({
  // Commented out due to https://github.com/angular/angular-cli/issues/3576
  // moduleId: module.id,
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('passwordText') passwordInput: IonInput;
  LoadingMessage = LoadingMessage;
  username = '';
  password = '';
  signup = false;
  private isAuthenticating = false;
  handlingSocialLogin = false;
  showPassword = false;
  passwordType = 'password';
  capacitor = Capacitor;
  isIos = this.capacitor.getPlatform() === 'ios';

  constructor(
    private authService: AuthService,
    private router: Router,
    private ionicDialogService: IonicDialogService,
    private route: ActivatedRoute,
    private userInfoService: UserInfoService,
    private dialogService: IonicDialogService,
    private apiService: ApiService,
    private modalController: ModalController,
  ) {}

  onSignupComplete(email: string) {
    this.username = email;
    this.signup = false;
  }

  async onSubmit($event) {
    $event.preventDefault();
    this.isAuthenticating = true;
    this.ionicDialogService.loading(LoadingMessage.Login);
    if (this.capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.hide();
    }

    if (this.capacitor.getPlatform() === 'web') {
      this.authService.login(this.username, this.password)
        .subscribe(() => {
          this.onLoginSuccess();
        }, error => {
          this.ionicDialogService.dismissLoading();
          const message = error.description || ErrorMessage.GenericTryAgain;
          this.isAuthenticating = false;

          this.ionicDialogService.generic(ErrorTitle.Error, message);
        });

      return;
    }

    try {
      const { expiresAt, idToken } = await Auth.login({ username: this.username, password: this.password });
      this.onLoginSuccess(expiresAt, idToken);
    } catch (e) {
      this.ionicDialogService.dismissLoading();
      this.isAuthenticating = false;
      this.ionicDialogService.generic(ErrorTitle.Error, e);
    }

  }

  async socialLogin(connection: string) {
    if (this.capacitor.getPlatform() === 'web') {
      this.authService.startSocialLogin(connection);
      return;
    }

    this.handlingSocialLogin = true;

    try {
      const { expiresAt, idToken } = await Auth.socialLogin({ connection });
      this.onLoginSuccess(expiresAt, idToken);
    } catch (e) {
      this.handlingSocialLogin = false;
      this.ionicDialogService.generic(ErrorTitle.Error, e);
    }
  }

  focusInput(input: IonInput) {
    input.setFocus();
  }

  onLoginSuccess(expiresAt?: string, idToken?: string) {
    if (expiresAt && idToken) {
      ClientStorageService.put(ClientStorageKey.ExpiresAt, (new Date(expiresAt)).getTime());
      ClientStorageService.put(ClientStorageKey.IdToken, idToken);
    }

    this.getUserInfo();

    CopilotBridge.sessionStarted({accountId: this.userInfoService.accountId});
    CopilotBridge.logEvent({eventName: CopilotEvent.LoggedInAnalyticsEvent});
    this.router.navigateByUrl('/devices');
  }

  openPasswordReset() {
    this.modalController
       .create({
         component: PasswordResetComponent,
         componentProps: { ['email']: this.username },
         canDismiss: true,
         presentingElement: document.querySelector('app-login'),
         cssClass: 'login-modal'
       })
       .then(modalEl => {
         modalEl.present();
       })
  }

  toggleShow() {
    this.showPassword = !this.showPassword;
    this.passwordType = this.showPassword ? 'text' : 'password';
  }

  ngOnInit() {
    this.route.fragment.subscribe((fragment: string) => {
      if (!fragment) { return; };

      this.handlingSocialLogin = true;

      this.authService.handleSocialLoginHash(fragment).subscribe(() => {
        this.onLoginSuccess();
      });
    });
  }

  ionViewWillLeave() {
    this.handlingSocialLogin = false;
    this.isAuthenticating = false;
    this.username = '';
    this.password = '';
    this.showPassword = false;
    this.ionicDialogService.dismissLoading();
  }

  async showUpdateDialog() {
    if (!this.capacitor.isNativePlatform()) {
      return;
    }

    const info = await App.getInfo();
    let message: string;
    let allowedVersion: string;

    if (this.capacitor.getPlatform() === 'ios') {
      allowedVersion = '6.0.0';
      message = `Welcome back! We've released a new update, so you might notice some changes:
      <ul>
        <li>We've rebuilt the login experience so that you stay logged in forever. Log in once more, and you won't get periodically logged out again!</li>
        <li>The app looks a little different, but you'll still find all of your powerful features.</li>
        <li>We've added support for dark mode. This will automatically be applied to the app based on your device's system preferences.</li>
      </ul>
      `;
    } else if (this.capacitor.getPlatform() === 'android') {
      allowedVersion = '4.0.0';
      message = `Welcome back! We've released a new update, so you might notice some changes:
      <ul>
        <li>We've rebuilt the login experience so that you stay logged in forever. Log in once more, and you won't get periodically logged out again!</li>
        <li>We've added support for dark mode. This will automatically be applied to the app based on your device's system preferences.</li>
      </ul>
      `;
    }

    if (info.version !== allowedVersion) {
      return;
    }

    this.dialogService.generic('App Update', `${message}`);
  }

  async getUserInfo() {
    this.apiService.getAsStream(`/users`).subscribe(info => {
      ClientStorageService.put(ClientStorageKey.UserProfile, info);
    });
  }
}
