import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IonicNotificationService {

  constructor(
    private toastController: ToastController
  ) { }

  async showAsync(message: string, buttonLabel, duration): Promise<boolean> {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      buttons: [buttonLabel]
    });
    await toast.present();
    await toast.onDidDismiss();

    return true;
  }

  public show(message: string, buttonLabel = 'OK', duration = 3000): Observable<boolean> {
    return from(this.showAsync(message, buttonLabel, duration));
  }
}
