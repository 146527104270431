export class RuleActionConfig {
  constructor(
    public body: string,
    public to: string,
    public contactId: string
  ) {}
}

// TODO: Update when twilio has been removed
export type RuleActionType = 'email' | 'console' | 'twilio' | 'sms' | 'voice';
export class RuleAction {
  constructor(
    public type: RuleActionType,
    public config: RuleActionConfig
  ) {}
}

export type RuleOperator = 'lessThan' | 'greaterThan' | 'equals' | 'notEquals'; // TODO: between outside
export type RuleAttribute = 'temperature' | 'humidity' | 'waterDetected' | 'offline';
interface RuleConstructorParams {
  accountId?: string,
  actions: RuleAction[],
  attribute: RuleAttribute,
  devices: string[],
  operator: RuleOperator,
  onlyAfterDuration?: number,
  ruleId?: string,
  threshold: number|boolean
}

export class Rule {
  static TemperatureHumidityOperators = [
    { value: 'lessThan', name: 'Less than' },
    { value: 'equals', name: 'Equal to' },
    { value: 'notEquals', name: 'Not equal to' },
    { value: 'greaterThan', name: 'More than' }
  ];

  static WaterOperators = [
    { value: 'equals', name: 'Equal to' },
    { value: 'notEquals', name: 'Not equal to' }
  ];

  // TODO: Update when twilio has been removed
  static Actions = [
    { value: 'email', name: 'Email' },
    { value: 'console', name: 'CONSOLE' },
    { value: 'twilio', name: 'TWILIO' },
    { value: 'sms', name: 'Text' },
    { value: 'voice', name: 'Call' },
  ];

  static Attributes = {
    WaterSensor: [
      { value: 'waterDetected', name: 'Water Detected' }
    ],
    TemperatureHumidity: [
      { value: 'humidity', name: 'Humidity' },
      { value: 'temperature', name: 'Temperature' }
    ],
    Global: [
      { value: 'offline', name: 'Offline Status'}
    ]
  };

  public accountId?: string;
  public actions: RuleAction[];
  public attribute: RuleAttribute;
  public devices: string[];
  public operator: RuleOperator;
  public onlyAfterDuration?: number;
  public ruleId?: string;
  public threshold: number|boolean;

  static fromJSON(rule: any): Rule {
    return new Rule({
      accountId: rule.accountId,
      actions: rule.actions,
      attribute: rule.attribute,
      devices: rule.devices,
      onlyAfterDuration: rule.onlyAfterDuration,
      operator: rule.operator,
      ruleId: rule.ruleId,
      threshold: rule.threshold
    });
  }

  constructor(p: RuleConstructorParams) {
    this.accountId = p.accountId;
    this.actions = p.actions;
    this.attribute = p.attribute;
    this.devices = p.devices;
    this.operator = p.operator;
    this.onlyAfterDuration = p.onlyAfterDuration;
    this.ruleId = p.ruleId;
    this.threshold = p.threshold;
  }

  // TODO: how to localize this?
  toString(): string {
    const isTempHumidityRule = this.attribute === 'temperature' || this.attribute === 'humidity';
    const isOnlineStatusRule = this.attribute === 'offline';

    const operators = isTempHumidityRule ? Rule.TemperatureHumidityOperators : Rule.WaterOperators;
    const operator = operators.filter(op => op.value === this.operator)[0];
    let attribute: string;
    let operatorAndThreshold: string;

    if (isOnlineStatusRule) {
      attribute = `When the device has been`;

      const notOffline = this.operator === 'equals' && this.threshold === false;
      const online = this.operator === 'notEquals' && this.threshold === true;
      if (notOffline || online) {
        operatorAndThreshold = 'online';
      } else {
        operatorAndThreshold = 'offline';
      }
    } else {
      if (isTempHumidityRule) {
        attribute = `When ${this.attribute} is`;
        const operatorLabel = operator.name.toLowerCase();
        operatorAndThreshold = `${operatorLabel} ${this.threshold}`;
      } else {
        attribute = `When water has`;

        const detectionConditionOne = this.operator === 'equals' && this.threshold === true;
        const detectionConditionTwo = this.operator === 'notEquals' && this.threshold === false;
        if (detectionConditionOne || detectionConditionTwo) {
          operatorAndThreshold = 'been detected';
        } else {
          operatorAndThreshold = 'not been detected';
        }
      }
    }

    let onlyAfterDuration = '';
    if (this.onlyAfterDuration) {
      onlyAfterDuration = `for at least ${this.onlyAfterDuration} minute`;

      if (this.onlyAfterDuration > 1) {
        onlyAfterDuration += `s`;
      }
    }

    let firstAction = this.actions[0];
    // TODO: Update when twilio has been removed
    if (firstAction.type === 'twilio') {
      firstAction.type = 'sms';
    }
    const verb = Rule.Actions.filter(action => action.value === firstAction.type).map(action => action.name.toLowerCase());

    const recipients = this.actions.map((action) => action.config.to).join(', ');

    let message = firstAction.config.body || '';
    if (message) {
      message = `with '${message}'`;
    }

    return `${attribute} ${operatorAndThreshold} ${onlyAfterDuration} then ${verb} ${recipients} ${message}`.trim().replace('  ', ' ');
  }

}
