<ion-item lines="none">
  <ion-label>
    {{device.data[outlet + '_active'] ? 'On' : 'Off'}}
  </ion-label>
  <ion-toggle
    #toggle
    [ngModel]="device.data[outlet + '_active']"
    (ngModelChange)="onOutletStateChange($event, device.deviceId, outlet)"
    slot="end"
    color="primary"
  ></ion-toggle>
</ion-item>
