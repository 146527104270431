import { ErrorTitle, ErrorMessage } from './../../assets/strings';
import { thingShadow } from 'aws-iot-device-sdk';
import { take } from 'rxjs/operators';
import { noop } from 'rxjs';
import { CopilotBridge } from '@copilot-bridge';
import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { CSSmartOutlet, DeviceService, Command, DeviceFeature } from '@connectsense/iot8020-library';
import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-device-card-control',
  templateUrl: './device-card-control.component.html',
  styleUrls: ['./device-card-control.component.scss']
})
export class DeviceCardControlComponent {
  @Input() device: CSSmartOutlet;
  @Input() outlet: string;
  @Input() shadow: thingShadow;
  @Output() outletStateChange = new EventEmitter<any>();
  DeviceFeature = DeviceFeature;

  constructor(
    private deviceService: DeviceService,
    private ionicDialogService: IonicDialogService
  ) { }

  onOutletStateChange(value: boolean, deviceId: string, outlet: string): void {
    this.device.data[outlet + '_active'] = value;

    this.outletStateChange.emit(value);

    const command = value ? Command.TurnOn : Command.TurnOff;

    this.deviceService.executeCommand(deviceId, command, outlet).pipe(
    take(1))
    .subscribe(noop, error => {
      this.ionicDialogService.generic(ErrorTitle.Error, ErrorMessage.OutletToggleFail);
    });
    CopilotBridge.logOutletToggle({deviceId: this.device.deviceId});
  }
}
