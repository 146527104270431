import { Pipe, PipeTransform } from '@angular/core';

import { Room } from '../../models/room';

@Pipe({
  name: 'filterEmptyRooms'
})
export class FilterEmptyRoomsPipe implements PipeTransform {

  transform(rooms: Room[]): Room[] {
    return rooms.filter((room) => !!room.devices.length);
  }

}
