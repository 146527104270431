import { NotificationMessage } from './../../assets/strings';
import { IonicNotificationService } from './../services/ionic-notification.service';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { zip } from 'rxjs';
import { pluck, mergeMap, filter, take, map } from 'rxjs/operators';

import { CSSmartOutlet, Device, Store, DeviceService } from '@connectsense/iot8020-library';

@Component({
  selector: 'app-outlet-settings',
  templateUrl: './outlet-settings.component.html',
  styleUrls: ['./outlet-settings.component.scss']
})
export class OutletSettingsComponent implements OnInit {
  deviceId: string;
  outlet: string;
  saving = false;
  type: number;

  constructor(
    private deviceService: DeviceService,
    private ionicNotificationService: IonicNotificationService,
    private route: ActivatedRoute,
    private store: Store
  ) { }

  loadDeviceData(): void {
    zip(
      this.route.paramMap,
      this.route.queryParamMap
    ).pipe(
    mergeMap(([params, queryParams]) => {
      this.deviceId = params.get('deviceId');
      this.outlet = queryParams.get('outlet');

      return this.store.changes.pipe(pluck(Store.Keys.Devices));
    }),
    filter(Boolean),
    take(1),
    map((devices: Device[]) => {
      return devices.filter(device => device.deviceId === this.deviceId);
    }))
    .subscribe(([device]: [CSSmartOutlet]) => {
      if (!device) { return; }
      this.type = device.data[`${this.outlet}_type`];
    });
  }

  onSubmit($event): void {
    $event.preventDefault();

    const data: any = {};
    data[`${this.outlet}_type`] = this.type;
    this.saving = true;

    this.deviceService.updateDeviceData(this.deviceId, data)
    .subscribe(() => {
      this.ionicNotificationService.show(NotificationMessage.OutletUpdated);
    }, () => {
      this.ionicNotificationService.show(NotificationMessage.GenericError);
    }, () => this.saving = false);
  }

  ngOnInit() {
    this.loadDeviceData();
  }

}
