  <ion-toolbar>
    <ion-title>Home</ion-title>

    <ion-buttons slot="end">
      <ion-button fill="clear" routerLink="/devices/add">
        <ion-icon slot="icon-only" name="add"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

<ion-content>
  <div *ngIf="!loading" class="loading-container">
    <ion-list class="ion-no-padding device-list" *ngIf="devices.length > 0">
      <ng-container *ngIf="ungroupedDevices.length > 0">
        <ion-list-header class="room-header">Unassigned</ion-list-header>
        <app-device-card *ngFor="let device of ungroupedDevices" [device]="device" [shadow]="shadows"></app-device-card>
      </ng-container>
      <ng-container *ngFor="let home of homes">
        <ng-container *ngIf="homes.length > 1">
          <ion-item-divider class="home-header">
            <ion-label>{{home.name}}</ion-label>
          </ion-item-divider>
        </ng-container>
        <ng-container *ngFor="let room of home.rooms | filterEmptyRooms">
          <ion-list-header class="room-header">{{room.name}}</ion-list-header>
          <app-device-card *ngFor="let device of room.devices" [device]="device" [shadow]="shadows">
          </app-device-card>
        </ng-container>
      </ng-container>
    </ion-list>

    <div *ngIf="devices.length === 0 && !hasFailedDeviceRetrieval" class="empty-devices">
      <h2>Feels pretty empty around here</h2>
      <p>
        <ion-button routerLink="/devices/add">Let's add a new device</ion-button>
      </p>
    </div>
    <div *ngIf="devices.length === 0 && hasFailedDeviceRetrieval" class="empty-devices">
      <p>
        Unable to load devices at this time. Check your network connection or try again later.
      </p>
    </div>
  </div>

  <app-loading-indicator *ngIf="loading" [message]="loadingMessage" [condition]="true"></app-loading-indicator>
</ion-content>
