import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByName'
})
export class SortByNamePipe implements PipeTransform {

  transform(array: any[], sortParameter = 'name'): any[] {
    return array.sort(this.sortAlphabetically(sortParameter));
  }

  sortAlphabetically = (sortParameter: string) => (a, b) => {
    const textA = a[sortParameter].toUpperCase();
    const textB = b[sortParameter].toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  }
}
