import { FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

export class DebouncedFormGroup extends FormGroup {
  get debouncedValueChanges() {
    return this.valueChanges.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    )
  }
}
