import { Component, Input, OnInit } from '@angular/core';

import { Config } from '@ionic/angular';

@Component({
  selector: 'app-ion-item-button',
  templateUrl: './ion-item-button.component.html',
  styleUrls: ['./ion-item-button.component.scss']
})
export class IonItemButtonComponent implements OnInit {
  @Input() color: string;
  @Input() icon: string;
  @Input() label: string;
  mode = this.config.get('mode');

  constructor(private config: Config) { }

  ngOnInit(): void {
  }

}
