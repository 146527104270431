<ng-container *ngIf="device.isCSOutlet() && !device.isCSRebooter(); else isRebooter">
  <ng-container *ngFor="let outlet of outlets; let last = last;">
    <ion-item [lines]="last ? itemLines : 'none'">
      <ion-checkbox
        class="schedule-item-checkbox"
        color="primary"
        (ionChange)="handleOutletSelection()"
        *ngIf="selectable"
        [(ngModel)]="selected[outlet]"
        [disabled]="!device.isConnected() && saveEnabled"
        slot="start"
      ></ion-checkbox>
      <ion-label
        *ngIf="device.hasFeature(DeviceFeature.DualOutlets)"
        [ngClass]="{'label-disabled': !device.isConnected() && saveEnabled}"
        class="ion-text-nowrap">
        {{device.data[outlet + '_name']}}
      </ion-label>
      <ion-label
        *ngIf="!device.hasFeature(DeviceFeature.DualOutlets)"
        [ngClass]="{'label-disabled': !device.isConnected() && saveEnabled}"
        class="ion-text-nowrap">
        {{device.name}}
      </ion-label>
      <ion-label
        *ngIf="device.isConnected()"
        class="device-action"
        slot="end">
        {{selected[outlet] ? ('Turn ' + (device.data[outlet + '_active'] ? 'on' : 'off')) : ''}}
      </ion-label>
      <ion-label
        *ngIf="!device.isConnected()"
        class="device-action"
        slot="end">
        Offline
      </ion-label>
      <ion-toggle
        [ngModel]="device.data[outlet + '_active']"
        (ngModelChange)="onOutletStateChange($event, device.deviceId, outlet)"
        [disabled]="!device.isConnected() && saveEnabled"
        slot="end"
        color="primary"
      ></ion-toggle>
    </ion-item>

  </ng-container>
</ng-container>
<ng-template #isRebooter>
  <ion-item [lines]="manualLines ? '' : 'none'" class="rebooter-item">
    <ion-checkbox
      class="schedule-item-checkbox"
      color="primary"
      (ionChange)="handleOutletSelection()"
      *ngIf="selectable"
      [(ngModel)]="selected['outlet1_reboot']"
      [disabled]="!rebooterEnabled"
      slot="start"
    ></ion-checkbox>
    <app-form-field
      [label]="device.name"
      [labelDisabled]="!rebooterEnabled"
      readonlyValue="Reboot"
      manualPosition="none"
      [manualLines]="false"
      [noPadding]="true"
    ></app-form-field>
  </ion-item>
</ng-template>
