import { Capacitor } from '@capacitor/core';

import { Router, ActivatedRoute} from '@angular/router';
import { Store } from '@connectsense/iot8020-library';
import { UsageService } from './../usage/services/usage.service';
import { Component } from '@angular/core';
import { subMonths } from 'date-fns';

@Component({
  selector: 'app-reboot-table',
  templateUrl: './reboot-table.component.html',
  styleUrls: ['./reboot-table.component.scss'],
  providers: [ UsageService ]
})
export class RebootTableComponent {
  deviceId: string;
  eventsDataSource;
  loading = true;
  platform = Capacitor.getPlatform();
  itemLines = this.platform === 'ios' ? 'inset' : 'none';

  constructor(
    private usageService: UsageService,
    private route: ActivatedRoute,
    public router: Router,
  ) { }

  ionViewWillEnter(): void {
    this.route
      .paramMap
      .subscribe(params => {
        this.deviceId = params.get('deviceId');
        this.getRebootEvents();
      });
  }

  getRebootEvents() {
    this.usageService.getRebootEvents(this.deviceId, subMonths(new Date(), 3)).subscribe(events => {
      this.eventsDataSource = events;
      this.eventsDataSource = this.usageService.readifyRebootEvents(events);
      this.loading = false;
    });
  }
}
