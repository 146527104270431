export const environment = {
  production: true,
  apiUrl: 'https://2-api.connectsense-staging.com',
  AUTH0_CLIENT_ID: 'Fx6gl1E3Y9yRO3R1lJX9qsLyBoPVJKRL',
  AUTH0_DOMAIN: 'connectsense-staging.auth0.com',
  AUTH0_CALLBACK_URL: 'https://2.connectsense-staging.com/login',
  AUTH0_REALM: 'Username-Password-Authentication',
  PROVISIONING_APP_URL: 'http://provisioning-staging.connectsense.com',
  SENTRY_DSN: 'https://74d1ed781d08470284b07ff648bc8314@o1128517.ingest.sentry.io/6585363',
  NAME: 'staging',
  appVersion: require('../../package.json').version
};
