import { HomeKitProvisioning } from '@homekit-prov';
import { ErrorTitle, LoadingMessage, DialogTitle, DialogMessage, ErrorMessage, DialogButtonText } from './../../assets/strings';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { IonicDialogService } from './../services/ionic-dialog.service';
import { Component, DoCheck, Input, KeyValueDiffers } from '@angular/core';

import { noop, from, race, Observable } from 'rxjs';
import { filter, tap, mergeMap, first, map, delay, takeWhile, timeout } from 'rxjs/operators';

import { thingShadow } from 'aws-iot-device-sdk';

import {
  Command,
  CSSmartOutlet,
  DeviceService,
  FirmwareService,
  DeviceFeature
} from '@connectsense/iot8020-library';

import { FirmwareUpdateStatus } from '../models/firmware-update-status';
import { FirmwareUpdateService } from '../services/firmware-update.service';

@Component({
  selector: 'app-device-controls',
  templateUrl: './device-controls.component.html',
  styleUrls: ['./device-controls.component.scss']
})
export class DeviceControlsComponent implements DoCheck {
  readonly FIRMWARE_UPDATE_EVENT_TYPES = ['mcu_update', 'system', 'reboot'];
  readonly firmwareUpdateMessage = {
    [FirmwareUpdateStatus.InProgress]: 'Updating...',
    [FirmwareUpdateStatus.Success]: 'Update Successful! Waiting to reboot',
    [FirmwareUpdateStatus.Reboot]: 'Rebooting...',
  };
  @Input() device: CSSmartOutlet;
  @Input() firmwareUpdateAvailable: boolean;
  @Input() shadow: thingShadow;
  LoadingMessage = LoadingMessage;
  loadingDialog: HTMLIonLoadingElement;
  DeviceFeature = DeviceFeature;
  differ: any;
  rebooting = false;

  itemLines = Capacitor.getPlatform() === 'ios' ? 'inset' : 'none';

  get eventsTopic(): string {
    return `iot8020/${this.device.deviceId}/events`;
  }

  get serialNumber(): string {
    return this.device.deviceId.split('-').pop();
  }

  get canBeHomeKitPaired(): boolean {
    return this.device.state['homekit_paired'] !== undefined
      ? (!this.device.state['homekit_paired'] && Capacitor.getPlatform() === 'ios')
      : false;
  }

  get isVersionLastItem(): boolean {
    return !this.device.hasFeature(DeviceFeature.FirmwareAutoUpdate) ||
    !(this.firmwareUpdateAvailable && this.device.isConnected()) ||
    !this.canBeHomeKitPaired;
  }

  constructor(
    private deviceService: DeviceService,
    private firmwareService: FirmwareService,
    private firmwareUpdateService: FirmwareUpdateService,
    private ionicDialogService: IonicDialogService,
    private router: Router,
    private differs:  KeyValueDiffers,
  ) {
    this.differ = differs.find([]).create();
  }

  ngDoCheck(): void {
    const changes = this.differ.diff(this.device);
    if (changes) {
      changes.forEachChangedItem((change) => {
        if (this.rebooting && change.key === 'data' && !change.currentValue.offline) {
          // Device has come back online from a reboot
          this.startHomeKitPairing();
          this.rebooting = false;
          this.ionicDialogService.dismissLoading();
        }
      });
    }
  }

  onAutoUpdateChange(checked: boolean, deviceId: string) {
    this.deviceService.executeCommand(deviceId, Command.SetAutoUpdateEnabled, checked)
    .subscribe(noop, error => {
      console.error(error);
      this.ionicDialogService.generic(ErrorTitle.Error, ErrorMessage.GenericError);
    });
  }

  identify(deviceId: string): void {
    this.deviceService.executeTopicCommand(deviceId, Command.Identify, {}, this.shadow);
  }

  async startHomeKitReboot(deviceId: string) {
    const didGrantAccess = await this.checkHomeKitPermissions();
    if (!didGrantAccess) {
      return this.ionicDialogService.confirm(
        DialogTitle.AllowHomeKit,
        DialogMessage.AllowHomeKit,
        [
          {
            text: DialogButtonText.HomeKitOpenSettings,
            role: 'confirm'
          },
          {
            text: DialogButtonText.Cancel,
            role: 'cancel',
            cssClass: 'secondary'
          },
        ]
      ).pipe(
        filter((userDidConfirm: boolean) => userDidConfirm === true)
      ).subscribe(() => {
        this.openSettings();
      });
    }

    this.ionicDialogService.confirm(
      DialogTitle.AddToHomeKit,
      DialogMessage.AddToHomeKit,
    ).pipe(
      filter(Boolean)
    ).subscribe(() => {
      this.sendRebootCommand(deviceId);
      this.rebooting = true;
      this.ionicDialogService.loading(LoadingMessage.StartHomeKit);
    });
  }

  sendRebootCommand(deviceId: string) {
    if (this.device.isCSSmartOutlet()) {
      this.deviceService.updateDeviceState(deviceId, { homekit_enabled: true });
    } else {
      this.deviceService.executeTopicCommand(deviceId, Command.ResetReboot, {}, this.shadow);
    }
  }

  async checkHomeKitPermissions(): Promise<boolean> {
    const { hasRequestedHomeAccess } = await HomeKitProvisioning.hasRequestedHomeAccess();
    const { didGrantAccess } = await HomeKitProvisioning.getAccessStatus();

    return didGrantAccess;
  }

  async startHomeKitPairing() {
    try {
      const result = await HomeKitProvisioning.start({ deviceType: this.device.deviceTypeId });
      if (result.serialNumber && result.uuid) {
      } else {
        this.showErrorDialog(ErrorTitle.Error, ErrorMessage.HomeKitResultFail);
      }
    } catch (e) {
      this.showErrorDialog(ErrorTitle.Error, e.message);
    }
  }

  async openSettings() {
    await HomeKitProvisioning.allowFromSettings();
  }

  showErrorDialog(title: string, message: string) {
    return this.ionicDialogService.confirm(title, message, [{
      text: DialogButtonText.OK,
      role: 'confirm'
    }]).subscribe(() => {
    });
  }

  updateFirmware() {
    this.ionicDialogService.confirm(
      DialogTitle.ConfirmFirmwareUpdate,
      DialogMessage.ConfirmFirmwareUpdate,
    ).pipe(
      filter(userConfirmsUpdate => userConfirmsUpdate === true),
      mergeMap(() => {
        const tenMinutes = 3600000;
        return this.ionicDialogService.loading(LoadingMessage.StartFirmwareUpdate, tenMinutes);
      }),
      mergeMap((loadingDialog) => {
        this.loadingDialog = loadingDialog;
        return this.firmwareService.updateFirmware(this.device.deviceTypeId, this.device.deviceId);
      }),
      mergeMap(() => {
        return race(
          from(this.loadingDialog.onDidDismiss()).pipe(map(() => { return FirmwareUpdateStatus.Fail })),
          this.subscribeToUpdate()
        )
      }),
      first()
    ).subscribe((result) => {
      if (result === FirmwareUpdateStatus.Fail) {
        this.ionicDialogService.generic(ErrorTitle.Error, ErrorMessage.FirmwareUpdateFail);
      } else {
        this.firmwareUpdateAvailable = false;
        this.ionicDialogService.success();
      }
      this.loadingDialog.dismiss();
    });
  }

  subscribeToUpdate() {
    let currentVersion = this.device.state.mcu_firmware_version;

    if (!this.device.hasFeature(DeviceFeature.FirmwareUpdateEvents)) {
      return this.firmwareUpdateService.watchForFirmwareChange(this.device.deviceId, currentVersion);
    }

    return this.firmwareUpdateService
    .subscribeToFirmwareEvents(this.shadow, this.device.deviceId)
    .pipe(
      tap(({ message }) => this.loadingDialog.message = message),
      filter(({ status }) => status === FirmwareUpdateStatus.Boot)
    )
  }

}
