import { Component, Input } from '@angular/core';

import { Device } from '@connectsense/iot8020-library';

@Component({
  selector: 'app-device-log',
  templateUrl: './device-log.component.html',
  styleUrls: ['./device-log.component.scss']
})
export class DeviceLogComponent {
  @Input() device: Device;
  groupedData = [];

  @Input()
  set data(newValue: any) {
    this.parseData(newValue);
  }

  parseData(newData) {
    if (!newData || !newData.length) {
      return;
    }

    let dateList = {};

    newData.forEach((entry) => {
      let date = new Date(entry['date']);
      let dateString = '' + date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate();

      if (!dateList[dateString]) {
        dateList[dateString] = [entry];
      } else {
        dateList[dateString].push(entry);
      }
    });

    let tmp = [];
    Object.keys(dateList).forEach(function(date) {
      tmp.push(dateList[date]);
    });

    this.groupedData = tmp;
  }
}
