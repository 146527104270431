import { Directive, Input, ElementRef, OnChanges } from '@angular/core';

import { Device } from '@connectsense/iot8020-library';

import { OutletTypes } from '../../models/outlet-types';

@Directive({
  selector: '[appOutletIcon]'
})
export class OutletIconDirective implements OnChanges {
  @Input() active: boolean;
  @Input() device: Device;
  @Input() occupied: boolean;
  @Input() type: number;
  readonly rootIconPath = '../../assets/outlet-type-icons/';

  constructor(private el: ElementRef) { }

  ngOnChanges() {
    this.el.nativeElement.src = this.iconPath(this.type, this.occupied);
    this.el.nativeElement.style.filter = this.grayScale(this.active);
  }

  grayScale(active: boolean) {
    let value = active ? '0%' : '100%';

    return `grayscale(${value})`;
  }

  iconPath(type = 0, occupied = true): string {
    let icon = 'default';

    if (this.device && this.device.isCSRebooter()) {
      return `${this.rootIconPath}rebooter.png`;
    }

    if (this.device && this.device.isCSLampController()) {
      type = OutletTypes.Lamp.id;
    }

    if (occupied) {
      const key = Object.keys(OutletTypes).find(outletType => {
        return OutletTypes[outletType].id === type;
      });
      icon = OutletTypes[key].iconName;
    }

    return `${this.rootIconPath}${icon}.png`;
  }
}
