<router-outlet></router-outlet>

<ng-container *ngIf="!route.children.length">
  <div class="toolbar-container" [ngStyle]="{ display: saving ? 'none' : 'flex' }">
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button (click)="previous()" *ngIf="step > 0">
          <app-icon name="arrow-back"></app-icon>
        </ion-button>
      </ion-buttons>

      <ion-title>{{title}}</ion-title>

      <ion-buttons slot="end">
        <ion-button (click)="skip()" *ngIf="step === 1">
          <ion-label>Skip</ion-label>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </div>

  <div class="body-content ion-padding" *ngIf="loading">
    <app-loading-indicator [condition]="true" [message]="message"></app-loading-indicator>
  </div>

  <div class="loaded-container ion-padding" *ngIf="!loading && !saving">
    <div class="form-container" label="Configure Device" *ngIf="step === 0" #step>
      <form class="setup-form" [formGroup]="deviceForm" (ngSubmit)="onSubmit()">
        <div class="body-content">
          <ion-item *ngIf="!device?.hasFeature(DeviceFeature.DualOutlets)">
            <ion-input formControlName="name" placeholder="Name Your Device"></ion-input>
          </ion-item>

          <ion-item *ngIf="homes.length"
            (click)="openSelectionModal('Select Room', 'room', device.roomId, 'homes')"
            [lines]="itemLines"
            button
            class="room-item"
          >
            <ion-label position="floating">Where is your outlet?</ion-label>
            <ion-input class="room-select" [value]="roomLabel" readonly></ion-input>
          </ion-item>

          <ng-container *ngIf="!homes.length">
            <app-form-field
              formControlName="home"
              [(value)]="home"
              name="home"
              maxlength="25"
              placeholder="Home Name"
              class="home-field"
            ></app-form-field>

            <app-form-field
              formControlName="newRoom"
              [(value)]="room"
              name="room"
              maxlength="25"
              placeholder="Room Name"
              class="room-field"
            ></app-form-field>
          </ng-container>
        </div>

        <div class="footer">
          <ion-button type="submit">Next</ion-button>
        </div>
      </form>
    </div>

    <ng-container *ngIf="!device?.hasFeature(DeviceFeature.DualOutlets)">
      <div *ngIf="device?.isCSLampController() && step === 1" class="form-container" label="Add Timer On/Off" #step>
        <div class="setup-form">
          <div class="body-content">
            <img src="/assets/outlet-type-icons/lamp.png" width="50" height="63" alt="Lamp" class="lamp-image">
            <p class="description-text">
              Would you like to turn your lamp on or off at a certain time?
              You can create a timer that repeats on specific days and times.
            </p>
          </div>

          <div class="footer">
            <ion-button [routerLink]="['/schedules', 'new']" [queryParams]="{setup: true}" [replaceUrl]="true">
              Add Timer
            </ion-button>
          </div>
        </div>
      </div>

      <ng-container *ngIf="device?.isCSRebooter()">
        <div class="form-container" label="Device Type" *ngIf="step === 1" #step>
          <div class="setup-form">
            <div class="body-content">
              <app-rebooter-type-picker (typeChange)="onRebooterTypeSelected($event)">
              </app-rebooter-type-picker>
            </div>

            <div class="footer">
              <ion-button (click)="onSubmit()">Next</ion-button>
            </div>
          </div>
        </div>

        <div class="form-container" label="Intelligent Reboot" *ngIf="step === 2" #step>
          <div class="setup-form">
            <div class="body-content">
              <img src="/assets/outlet-type-icons/rebooter.png" width="100" height="100">
              <ion-item class="ion-no-padding" lines="none">
                <ion-label>Enable Intelligent Reboot</ion-label>
                <ion-toggle [(ngModel)]="desiredConfig.auto_reset"></ion-toggle>
              </ion-item>
              <p class="description-text">
                Intelligent Reboot will periodically check for an active internet connection. If your internet is down, it will attempt to restore your connection by rebooting the device plugged into this Rebooter.
              </p>
            </div>

            <div class="footer">
              <ion-button (click)="onSubmit()">Save</ion-button>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="device?.hasFeature(DeviceFeature.DualOutlets)">
      <ng-container *ngFor="let outlet of outlets; let i = index">
        <div class="form-container" label="Select Type" *ngIf="step === i * 2 + 1" #step>
          <img [src]="getOutletImage(outlet.id, device?.deviceTypeId)" [alt]="outlet.label" class="outlet-image">
          <app-outlet-type-picker [(type)]="desiredData[outlet.id + '_type']" (labelChange)="onLabelChange($event, outlet.id)"></app-outlet-type-picker>
        </div>

        <div class="form-container" label="Name Outlet" *ngIf="step === i * 2 + 2" #step>
          <img [src]="getOutletImage(outlet.id, device?.deviceTypeId)" [alt]="outlet.label" class="outlet-image">
          <form class="setup-form setup-name-form" (submit)="i === 1 ? onSubmit() : next()">
            <app-form-field
              [(ngModel)]="desiredData[outlet.id + '_name']"
              [name]="outlet.id + '_name'"
              maxlength="25"
              [placeholder]="'Name ' + outlet.label"
              class="body-content"
              [label]="outlet.label + ' Name'"
            ></app-form-field>

            <div class="footer">
              <ion-button type="submit">{{outlet.id === 'outlet1' ? 'Next' : 'Done'}}</ion-button>
            </div>
          </form>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<div class="body-content" *ngIf="saving">
  <app-loading-indicator
    [message]="message"
    [condition]="saving"
  ></app-loading-indicator>
</div>
