<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="cancel()">Cancel</ion-button>
    </ion-buttons>
    <ion-title>Reset Password</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form name="pwReset-form" (ngSubmit)="onSubmit($event)" #pwReset="ngForm" class="form login reset">
    <ion-item class="ion-no-padding" lines="none">
      <ion-input type="email" name="email" placeholder="Email Address" required #userName="ngModel" pattern="^.*@.*$"
      [(ngModel)]="email"></ion-input>
      <ion-note slot="helper" class="helper">
        Your ConnectSense email address
      </ion-note>
    </ion-item>
    <ion-button type="submit" class="submit" [disabled]="!pwReset.form.valid">
      Reset My Password
    </ion-button>
  </form>
</ion-content>
