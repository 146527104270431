import { ThemingService } from './../../theming.service';
import { Component, Input } from '@angular/core';

import { ChartOptions, ChartDataSets } from 'chart.js';
import { format } from 'date-fns';

import { intervals, UsageInterval } from '../../models/usage-interval';

@Component({
  selector: 'app-usage-chart',
  templateUrl: './usage-chart.component.html',
  styleUrls: ['./usage-chart.component.scss']
})
export class UsageChartComponent {
  @Input() interval: UsageInterval;
  fontColor = this.themingService.isDarkTheme ? 'white' : '#607D8B';
  chartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          gridLines: {
            borderDash: [4, 2],
            color: 'rgb(221, 221, 224, 1)'
          },
          ticks: {
            maxRotation: 0,
            maxTicksLimit: 4.1,
            padding: -5,
            fontColor: this.fontColor
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            display: false,
            fontColor: this.fontColor
          }
        }
      ],
    },
    legend: {
      display: false,
      labels: {
        fontColor: this.fontColor
      }
    },
    tooltips: {
      callbacks: {
        label: this.formatTooltipLabel
      },
      displayColors: false
    }
  };
  chartColors = [ { backgroundColor: 'rgba(49, 179, 186, 0.5)' } ];
  chartLabels: string[] = [];
  chartData: ChartDataSets[] = [{data: []}];
  @Input()
  set data(value: { x: number, y: number }[]) {
    this.setChartData(value, this.interval);
  }

  constructor(
    private themingService: ThemingService
  ) { }

  setChartData(data: { x: number, y: number }[], interval: UsageInterval = intervals[0]): void {
    this.chartOptions.scales.xAxes[0].ticks.maxTicksLimit = interval.chartTicksLimit;
    this.chartData = [{ data: data.map(({ y }) => y) }];
    this.chartLabels = data.map(({ x }) => {
      return format(new Date(Number(x)), interval.chartLabelFormat);
    });

    // Trick ng2-charts into re-rendering 💩
    this.chartData = JSON.parse(JSON.stringify(this.chartData));
    setTimeout(() => {
      this.chartLabels = JSON.parse(JSON.stringify(this.chartLabels));
    }, 0);
  }

  formatTooltipLabel({ yLabel }: { yLabel: string }): string {
    return `${ Number((+yLabel / 1000).toFixed(5)) } kWh`;
  }

}
