<div class="login-bg">
<ion-content class="login">
  <div class="content">
    <div class="login-wrap" *ngIf="!handlingSocialLogin">
      <div class="logo-wrap">
        <img src="../../assets/logo-stacked.svg" alt="ConnectSense" class="logo-img">
        <h2 class="greeting">{{signup ? 'Sign up' : 'Hello'}}</h2>
      </div>

      <div class="main-content">
        <form name="login-form" (ngSubmit)="onSubmit($event)" #login="ngForm" *ngIf="!signup">
          <div>
            <ion-item>
              <ion-input type="email" name="username" #usernameText
                placeholder="Email Address"
                required
                pattern="^.*@.*$"
                [(ngModel)]="username"
                inputmode="email"
                enterkeyhint="next"
                (keyup.enter)="focusInput(passwordInput)"
                (change)="login.form.controls['username'].setValue($event.target.value)">
              </ion-input>
            </ion-item>
          </div>

          <div>
            <ion-item class="ion-no-margin">
              <ion-input [type]="passwordType" name="password" #passwordText
                placeholder="Password"
                required
                [(ngModel)]="password"
                autocomplete="password"
                enterkeyhint="go"
                (keyup.enter)="onSubmit($event)"
                (change)="login.form.controls['password'].setValue($event.target.value)">
              </ion-input>
              <ion-button
                tabindex="-1"
                (click)="toggleShow()"
                slot="end"
                fill="clear"
                class="ion-no-margin">
                <ion-icon [hidden]="!showPassword" slot="icon-only" name="eye-off-outline"></ion-icon>
                <ion-icon [hidden]="showPassword" slot="icon-only" name="eye-outline"></ion-icon>
              </ion-button>
            </ion-item>
          </div>

          <div class="forgot">
              <a (click)="openPasswordReset()">
              Forgot your password?
            </a>
          </div>

          <ion-button class="submit" type="submit" [disabled]="!login.form.valid || isAuthenticating">
            Continue
          </ion-button>
        </form>

        <div *ngIf="signup" class="signup-container">
          <app-signup (onSignup)="onSignupComplete($event)"></app-signup>
          <a (click)="signup = false">Cancel</a>
        </div>

        <div class="social-login">
          <span>or continue with</span>
          <div class="social-button-container">
            <ion-img class="social-button" src="../../assets/google-logo.png" (click)="socialLogin('google-oauth2')"></ion-img>
            <ion-img class="social-button" src="../../assets/apple-logo.png" (click)="socialLogin('apple')"></ion-img>
            <ion-img class="social-button" src="../../assets/amazon-logo.png" (click)="socialLogin('amazon')"></ion-img>
          </div>
        </div>

        <div class="no-account" *ngIf="!signup">
          <span>Don't have an account?</span>
          <div class="no-account-options">
            <a (click)="signup = true; showPassword = false; this.passwordType = 'password';">Sign up</a>
            <span *ngIf="isIos"> or
              <a [routerLink]="['/homekit-list']" [queryParams]="{isUnregisteredUser: true}">
                Use Only with HomeKit
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="footer" *ngIf="!signup">
      <a color="medium" href="https://www.iubenda.com/privacy-policy/32009963">Privacy Policy</a>
    </div>
  </div>

  <app-loading-indicator [message]="LoadingMessage.Login" [condition]="handlingSocialLogin"></app-loading-indicator>
</ion-content>
</div>
