import { Injectable } from '@angular/core';

import { Observable, Observer } from 'rxjs';
import { pluck, filter, flatMap, first, map, tap } from 'rxjs/operators';
import { FirmwareUpdateStatus } from '../models/firmware-update-status';

import { thingShadow } from 'aws-iot-device-sdk';

import { CSSmartOutlet, Device, Store } from '@connectsense/iot8020-library';

import { CopilotEvent } from '../models/copilot-event';
import { CopilotBridge } from '@copilot-bridge';

@Injectable({
  providedIn: 'root'
})
export class FirmwareUpdateService {
  readonly FIRMWARE_UPDATE_EVENT_TYPES = ['mcu_update', 'system', 'reboot'];
  readonly firmwareUpdateMessage = {
    [FirmwareUpdateStatus.InProgress]: 'Updating...',
    [FirmwareUpdateStatus.Success]: 'Update Successful! Waiting to reboot',
    [FirmwareUpdateStatus.Reboot]: 'Rebooting...',
  };
  deviceId: string;
  eventName: string = FirmwareUpdateStatus.InProgress;
  firmwareUpdateObserver: Observer<{ message: string, status: FirmwareUpdateStatus }>;
  shadow: thingShadow;
  get eventsTopic(): string {
    return `iot8020/${this.deviceId}/events`;
  }

  constructor(
    private store: Store,
  ) { }

  watchForFirmwareChange(deviceId: string, currentVersion: string): Observable<FirmwareUpdateStatus> {
    CopilotBridge.logEvent({eventName: CopilotEvent.FirmwareUpgradeStartedAnalyticsEvent});
    return this.store.changes.pipe(
      pluck(Store.Keys.Devices),
      filter(Boolean),
      flatMap((devices: Device[]) => devices),
      filter(Boolean),
      filter((device: Device) => !!device && (device.deviceId === deviceId)),
      filter((device: CSSmartOutlet) => currentVersion !== device.state.mcu_firmware_version),
      first(),
      tap(() => CopilotBridge.logEvent({eventName: CopilotEvent.FirmwareUpgradeSucceededAnalyticsEvent})),
      map(() => FirmwareUpdateStatus.Success)
    );
  }

  subscribeToFirmwareEvents(shadow: thingShadow, deviceId: string): Observable<{
    message: string, status: FirmwareUpdateStatus
  }> {
    this.shadow = shadow;
    this.deviceId = deviceId;
    CopilotBridge.logEvent({eventName: CopilotEvent.FirmwareUpgradeStartedAnalyticsEvent});

    return new Observable((observer: Observer<{ message: string, status: FirmwareUpdateStatus }>) => {
      this.firmwareUpdateObserver = observer;
      shadow.subscribe(this.eventsTopic);
      shadow.on('message', this.onMessage);
    });
  }

  onMessage = (topic: string, payload: Uint8Array): void => {
    if (topic !== this.eventsTopic) { return; }

    const { eventType, eventName } = JSON.parse(payload.toString());
    if (!eventType || !eventName) { return; }
    if (!this.FIRMWARE_UPDATE_EVENT_TYPES.includes(eventType)) { return; }

    if (eventName === FirmwareUpdateStatus.Fail) {
      CopilotBridge.logEvent({eventName: CopilotEvent.FirmwareUpgradeFailedAnalyticsEvent});
      this.firmwareUpdateObserver.error(eventName);
    } else {
      this.firmwareUpdateObserver.next({
        status: eventName,
        message: this.firmwareUpdateMessage[eventName]
      });

      if (eventName !== FirmwareUpdateStatus.Boot) {
        return;
      }
    }

    CopilotBridge.logEvent({eventName: CopilotEvent.FirmwareUpgradeSucceededAnalyticsEvent});

    this.firmwareUpdateObserver.complete();
    this.shadow.unsubscribe(this.eventsTopic);
  }
}
