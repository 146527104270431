<ion-list>
  <ng-container *ngFor="let home of homes | filterRoomlessHomes">
    <ion-item-group>
      <ion-item-divider class="subheader" sticky="true">
        <ion-label>{{home.name}}</ion-label>
      </ion-item-divider>
      <ion-item *ngFor="let room of home.rooms | sortByName; last as last"
        (click)="selectRoom(room.roomId, room.name)"
        class="ion-activatable"
        [lines]="last ? 'none' : 'inset'"
      >
        <ion-label>{{room.name}}</ion-label>
        <app-icon *ngIf="isSelected(room.roomId)" name="checkmark" color="primary"></app-icon>
      </ion-item>
    </ion-item-group>
  </ng-container>
</ion-list>
