import { ModalController } from '@ionic/angular';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-selection-modal',
  templateUrl: './selection-modal.component.html',
  styleUrls: ['./selection-modal.component.scss']
})
export class SelectionModalComponent {
  @Input() title: string;
  @Input() selectorType: string;
  @Input() value: any;
  @Input() array: [];

  constructor(
    private modalController: ModalController,
  ) { }

  submit() {
    return this.modalController.dismiss(this.value, 'confirm');
  }

}
