<ion-card>
  <ion-card-header>
    <ion-card-subtitle class="device-header" type="ios">
      <p class="device-name">{{hkDevice.deviceName}}</p>
      <!-- <p class="device-status">{{status}}</p> TODO: add fw update info -->
    </ion-card-subtitle>
    <ion-item-divider></ion-item-divider>
  </ion-card-header>

  <ion-card-content class="ion-no-padding">
    <ion-item class="outlet-content" lines="none">
      <img
      class="outlet-img"
      appOutletIcon
      [active]="true"
      [occupied]="false"
      [type]="0"
      >
      <div class="item-text">
        <ion-item class="outlet-label-container" lines="none">
          <ion-label class="outlet-label" [ngClass]="{'offline': !hkDevice.isReachable}">
            {{hkDevice.outlet1Name}}
          </ion-label>
        </ion-item>
        <ion-item class="offline-label" *ngIf="!hkDevice.isReachable; else isOnline" lines="none">
          <ion-label>
            Offline
          </ion-label>
        </ion-item>
        <ng-template #isOnline>
        </ng-template>
      </div>
    </ion-item>
    <ion-item class="outlet-content" lines="none">
      <img
      class="outlet-img"
      appOutletIcon
      [active]="true"
      [occupied]="false"
      [type]="0"
      >
      <div class="item-text">
        <ion-item class="outlet-label-container" lines="none">
          <ion-label class="outlet-label" [ngClass]="{'offline': !hkDevice.isReachable}">
            {{hkDevice.outlet2Name}}
          </ion-label>
        </ion-item>
        <ion-item class="offline-label" *ngIf="!hkDevice.isReachable; else isOnline" lines="none">
          <ion-label>
            Offline
          </ion-label>
        </ion-item>
      </div>
    </ion-item>
  </ion-card-content>
</ion-card>
