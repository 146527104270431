import { Injectable } from '@angular/core';

import { CSInWallOutlet, CSSmartOutlet, Device, DeviceService, Store, StoreHelper } from '@connectsense/iot8020-library';

import { thingShadow } from 'aws-iot-device-sdk';

import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShadowService {
  shadows: thingShadow;
  devices: Device[] = [];

  constructor(
    private deviceService: DeviceService,
    private storeHelper: StoreHelper
  ) { }

  watch(devices: Device[]): Observable<thingShadow> {
    this.devices = devices;

    return this.deviceService.getShadow().pipe(
      take(1),
      tap(shadows => {
        this.shadows = shadows;
        this.shadows.on('foreignStateChange', this.onShadowForeignStateChanged);
        this.shadows.on('connect', this.onShadowConnected);
      })
    );
  }

  onShadowForeignStateChanged = (name: string, operation: 'update'|'delete', stateObject: any): void => {
    const shadowUpdated = operation === 'update' && stateObject.state.reported;
    if (!shadowUpdated) { return; }

    const { data = {}, state = {} } = stateObject.state.reported;
    data.date = stateObject.timestamp * 1000;

    const updatedDevice = this.devices.find(({deviceId}) => deviceId === name);

    updatedDevice.data = { ...updatedDevice.data, ...data };

    if (stateObject.state.reported.state && (updatedDevice instanceof CSSmartOutlet || updatedDevice instanceof CSInWallOutlet)) {
      updatedDevice.state = { ...updatedDevice.state, ...state };
    }

    this.storeHelper.updateEntity(Store.Keys.Devices, updatedDevice);
  }

  onShadowConnected = () => {
    this.devices.map(device => {
      this.shadows.register(device.deviceId, {
        persistentSubscribe: true
      });
    });
  }

  unsubscribe(): void {
    if (!this.shadows) { return; }

    this.shadows.end();
  }
}
