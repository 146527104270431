<ion-toolbar>
  <ion-buttons slot="start" *ngIf="!router.url.includes('outlet-setup')">
    <ion-back-button defaultHref="../"></ion-back-button>
  </ion-buttons>

  <ion-title>Schedules</ion-title>

  <ion-buttons slot="end">
    <ion-button fill="clear" routerLink="new">
      <ion-icon slot="icon-only" name="add"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-content>
  <app-loading-indicator [message]="LoadingMessage.Schedules" [condition]="loading"></app-loading-indicator>

  <div *ngIf="!loading && !schedules.length" class="message">
    <p class="headline">It looks like you haven't created any schedules yet</p>
    <ion-button routerLink="new">Add a Schedule</ion-button>
  </div>

  <ion-list class="ion-no-padding">
    <ion-item *ngFor="let schedule of schedules" class="ion-no-padding" [routerLink]="[schedule.scheduleId]">
      <ion-label style="padding-left: 16px;">
        {{device.name}} -
        {{getScheduleAction(schedule, device)}}
        at {{schedule.time | date:'shortTime'}}
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-button [routerLink]="['/devices']" *ngIf="router.url.includes('outlet-setup')">
    Done
  </ion-button>
</ion-content>
