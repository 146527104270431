import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Device } from '@connectsense/iot8020-library';

@Pipe({
  name: 'filterGroupedDevices'
})
@Injectable({
  providedIn: 'root'
})
export class FilterGroupedDevicesPipe implements PipeTransform {

  transform(devices: Device[]): Device[] {
    return devices.filter(({ roomId }) => !roomId);
  }

}
