import { RebooterSchedule } from './../../models/rebooter-schedule';
import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { Device } from '@connectsense/iot8020-library';

import { Actions } from '../../models/actions';
import { Home } from '../../models/home';

@Component({
  selector: 'app-schedule-device-picker',
  templateUrl: './schedule-device-picker.component.html',
  styleUrls: ['./schedule-device-picker.component.scss']
})
export class ScheduleDevicePickerComponent implements OnInit {
  @Input() devices: Device[] = [];
  @Input() actions: Actions = {};
  @Input() homes: Home[] = []
  @Input() errorDeviceId: string;
  @Input() rebootersSchedules?: RebooterSchedule[];
  @Input() saveEnabled: Boolean = true;
  @Output() actionsChange = new EventEmitter<Actions>();
  selected: {
    [deviceId: string]: {
      outlet1: boolean,
      outlet2: boolean
    }
  } = {};
  isMultipleRebooters = false;

  ngOnInit() {
    this.isMultipleRebooters = this.devices.some(device => device.isCSRebooter());
    this.checkAvailableRebooterSchedules();
    this.setSelectedDevices(this.actions);
  }

  outletSelected(
    {deviceId, actions}: {deviceId: string, actions: { [target: string]: boolean }[]}
  ): void {
    if (JSON.stringify(actions) === JSON.stringify(this.actions[deviceId])) {
      return;
    }
    if (actions.length) {
      if (this.isMultipleRebooters) {
        // if rebooter, uncheck others
        this.actions = {};
        for (let selectedDeviceId in this.selected) {
          if (this.selected.hasOwnProperty(deviceId)) {
            this.selected[selectedDeviceId].outlet1 = false;
            this.selected[selectedDeviceId].outlet2 = false;
            this.selected[selectedDeviceId]['outlet1_active'] = false;
            this.selected[selectedDeviceId]['outlet1_reboot'] = false;
          }
        }
      }
      this.actions[deviceId] = actions;
      if (this.isMultipleRebooters) {
        this.setSelectedDevices(this.actions);
      }
    } else {
      delete this.actions[deviceId];
    }

    this.actionsChange.emit(this.actions);
  }

  checkAvailableRebooterSchedules() {
    let schedulableRebooters: Device[] = [];
    this.rebootersSchedules?.some(schedule => {
      if (schedule.enabled) {
        schedulableRebooters.push(schedule.device);
      }
    });

    if (schedulableRebooters.length === 1) {
      const schedulableId = schedulableRebooters[0].deviceId;

      if (this.selected.hasOwnProperty(schedulableId)) {
        this.selected[schedulableId].outlet1 = false;
        this.selected[schedulableId].outlet2 = false;
        this.selected[schedulableId]['outlet1_active'] = false;
        this.selected[schedulableId]['outlet1_reboot'] = false;
      }
      this.actions[schedulableId] = [{outlet1_active: true, outlet1_reboot: true}];
    }
  }

  setSelectedDevices(actions: Actions): void {
    this.selected = this.devices.reduce((selected, device: Device) => {
      const deviceActions = actions[device.deviceId];

      selected[device.deviceId] = {
        outlet1: !!deviceActions && !!deviceActions.filter(action => {
          if (action.outlet1_active !== undefined) {
            device.data.outlet1_active = action.outlet1_active
          }

          return action.outlet1_active !== undefined;
        }).length,
        outlet2: !!deviceActions && !!deviceActions.filter(action => {
          if (action.outlet2_active !== undefined) {
            device.data.outlet2_active = action.outlet2_active
          }

          return action.outlet2_active !== undefined;
        }).length,
      };

      if (this.isMultipleRebooters) {
        selected[device.deviceId].outlet1_active = !!deviceActions && !!deviceActions.filter(action => {
          return action.outlet1_reboot !== undefined;
        }).length;
        selected[device.deviceId].outlet1_reboot = !!deviceActions && !!deviceActions.filter(action => {
          return action.outlet1_reboot !== undefined;
        }).length;
      }

      return selected;
    }, {});
  }

  getScheduleDevice(device: Device): RebooterSchedule {
    return this.rebootersSchedules.filter(rebooterSchedule => this.isDeviceEqual(rebooterSchedule.device, device))[0];
  }

  isDeviceEqual(device1: Device, device2: Device): boolean {
    return device1.deviceId === device2.deviceId;
  }
}
