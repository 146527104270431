import { registerPlugin } from '@capacitor/core';

import type { HomeKitRegistrationPlugin } from './definitions';

const HomeKitRegistration = registerPlugin<HomeKitRegistrationPlugin>(
  'HomeKitRegistration'
);

export * from './definitions';
export { HomeKitRegistration };
