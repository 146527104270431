import { CopilotBridge } from '@copilot-bridge';
import { RebootService } from 'app/services/reboot.service';
import { ErrorTitle, LoadingMessage, ErrorMessage, DialogButtonText } from '../../assets/strings';
import { FilterGroupedDevicesPipe } from './../shared/pipes/filter-grouped-devices.pipe';
import { FilterEmptyHomesPipe } from './../shared/pipes/filter-empty-homes.pipe';
import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { Component, DoCheck, KeyValueDiffers } from '@angular/core'
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

import { range, Subject, Subscription, zip, Observable } from 'rxjs';
import {
  retryWhen,
  filter,
  takeUntil,
  first,
  take,
  delay,
  mergeMap,
  repeatWhen,
} from 'rxjs/operators';

import { thingShadow } from 'aws-iot-device-sdk';

import {
  Device,
  DeviceService,
} from '@connectsense/iot8020-library';

import { HomeService } from '../home/services/home.service';
import { Home } from '../models/home';
import { ShadowService } from 'app/services/shadow.service';
import { PushService } from 'app/services/push.service';

@Component({
  moduleId: module.id,
  selector: 'app-device-list',
  templateUrl: 'device-list.component.html',
  styleUrls: ['device-list.component.scss']
})
export class DeviceListComponent implements DoCheck {
  readonly MAX_VERIFICATION_ATTEMPTS = 12;
  readonly VERIFICATION_DELAY = 5000;
  LoadingMessage = LoadingMessage;
  devices: Device[] = [];
  devicesSubscription: Subscription;
  ungroupedDevices: Device[] = [];
  loading: boolean;
  loadingMessage = LoadingMessage.Devices;
  shadows: thingShadow;
  shadowsRegistered = false;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  homes: Home[] = [];
  shouldRefresh;
  isRebooting = false;
  rebootRefresh: Subscription;
  differ: any;
  hasFailedDeviceRetrieval = false;

  constructor(
      private deviceService: DeviceService,
      public route: ActivatedRoute,
      private router: Router,
      private homeService: HomeService,
      private ionicDialogService: IonicDialogService,
      private shadowService: ShadowService,
      private emptyHomesPipe: FilterEmptyHomesPipe,
      private groupedDevicesPipe: FilterGroupedDevicesPipe,
      private rebootService: RebootService,
      private differs:  KeyValueDiffers,
      private pushService: PushService,
  ) { this.differ = differs.find([]).create(); }

  getDevices(pendingDevice?: string, homeKitInfo?: any) {
    this.hasFailedDeviceRetrieval = false;
    this.loading = true;

    if (pendingDevice) {
      let newDevice: Device;
      this.loadingMessage = LoadingMessage.VerifyDevice;

      const devicePoller = range(0, 1).pipe(
        mergeMap(() => this.deviceService.getDevices()),
        repeatWhen(result => {
          return result.pipe(
            delay(this.VERIFICATION_DELAY),
            take(this.MAX_VERIFICATION_ATTEMPTS)
          );
        }),
        first((devices: Device[]) => {
          // We add a 'date' field to the shadow so it will always have at least 1 item
          // If it has more than one item, the device has connected and reported
          newDevice = devices.find(device => device.deviceId.includes(pendingDevice));
          return !!newDevice && Object.keys(newDevice.data).length > 1;
        })
      )
      .subscribe((devices: Device[]) => {
        devicePoller.unsubscribe();

        if (newDevice.isCSOutlet()) {
          this.loadingMessage = LoadingMessage.Devices;
          this.router.navigate(['/devices', newDevice.deviceId, 'outlet-setup'], { queryParams: homeKitInfo });
        } else {
          this.devices = devices;
          this.ungroupedDevices = this.groupedDevicesPipe.transform(this.devices);
          this.loadingMessage = LoadingMessage.Devices;
          this.loading = false;
          this.subscribeToLiveEvents();
          this.subscribeToRebootEvents();
        }
      }, () => {
        this.ionicDialogService.confirm(
          ErrorTitle.Error,
          ErrorMessage.VerifyTimeout,
          [DialogButtonText.OK]
        ).subscribe(() => {
          this.loadingMessage = LoadingMessage.Devices;
          this.router.navigate(['/devices']);
        });
      });

      return;
    }

    const autoRefresh = range(0, 1).pipe(
      mergeMap(() => {
        return zip(this.deviceService.getDevices(), this.homeService.getHomes())
      }),
      retryWhen(errors => {
        this.hasFailedDeviceRetrieval = true;
        return errors.pipe(
          delay(3000),
          take(5));
      }));

    const subscription = autoRefresh
      .subscribe(
        ([devices, homes]) => {
          this.hasFailedDeviceRetrieval = false;
          this.devices = devices;
          this.homes = this.homeService.associateHomesWithDevices(homes, this.devices);
          this.ungroupedDevices = this.groupedDevicesPipe.transform(this.devices);
          this.subscribeToLiveEvents();
          this.subscribeToRebootEvents();
          this.logDeviceFirmwareVersions(devices);

          if (this.shouldRefresh) {
            this.router.navigateByUrl('/', {replaceUrl: true})
          }
        },
        err => {
          console.error('Retrieving devices error:', err);
          this.hasFailedDeviceRetrieval = true;
        },
        () => {
          this.homes = this.emptyHomesPipe.transform(this.homes);
          this.loading = false;
          subscription.unsubscribe();
        }
      );
  }

  ngDoCheck(): void {
    if (!this.rebootService.rebootingDeviceIds.length) {
      return;
    }
    const changes = this.differ.diff(this.devices);
    if (changes && this.isRebooting) {
      changes.forEachChangedItem((change) => { });
    }
  }

  subscribeToRebootEvents() {
    this.rebootService.rebootingDevices.subscribe((deviceId) => {
      if (this.isRebooting && !this.rebootService.rebootingDeviceIds.length) {
        this.isRebooting = false;
        if (this.rebootRefresh) {
          this.rebootRefresh.unsubscribe();
        }
        return;
      }

      if (this.rebootService.rebootingDeviceIds.length) {
        if (!this.isRebooting) {
          this.isRebooting = true;
          this.refreshLiveEventSubscription();
        }
      }
    });
  }

  refreshLiveEventSubscription() {
    this.rebootRefresh = Observable.timer(5000, 10000)
    .takeWhile(() => this.isRebooting)
      .subscribe(() => {
        this.shadowService.unsubscribe();
        this.subscribeToLiveEvents();
      });
  }

  subscribeToLiveEvents() {
    if (!this.devices) { return; }

    this.shadowService.watch(this.devices).subscribe(() => {
      this.setDeviceShadows();
    });
  }

  setDeviceShadows(): void {
    this.shadows = this.shadowService.shadows;
  }

  private disconnectFromShadow = () => {
    this.shadowService.unsubscribe();
  }

  ionViewWillEnter(): void {
    this.devicesSubscription = this.route
      .queryParamMap
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((params: ParamMap) => !params.get('step'))
      )
      .subscribe(params => {
        this.shouldRefresh = params.get('refresh');
        const pendingDevice = params.get('pendingDevice');
        const deviceName = params.get('deviceName');
        const roomName = params.get('roomName');
        const outletAName = params.get('outletAName');
        const outletBName = params.get('outletBName');

        const homeKitInfo = {
          name: deviceName,
          room: roomName,
          outletA: outletAName,
          outletB: outletBName
        };

        this.getDevices(pendingDevice, homeKitInfo);
        this.pushService.register();
      });
  }

  ionViewWillLeave(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.devicesSubscription.unsubscribe();
    if (this.rebootRefresh) {
      this.rebootRefresh.unsubscribe();
    }
    this.disconnectFromShadow();
    this.devices = [];
    this.hasFailedDeviceRetrieval = false;
  }

  logDeviceFirmwareVersions(devices: Device[]) {
    for (let device of devices) {
      const deviceTypeVersion = `${device.deviceTypeId}|${device['state'].mcu_firmware_version}`;
      CopilotBridge.logFirmwareVersion({deviceTypeVersion});
    }
  }
}
