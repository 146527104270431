import { IonInput } from '@ionic/angular';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { Network } from '@connectsense/iot8020-library';

@Component({
  selector: 'app-network-list',
  templateUrl: './network-list.component.html',
  styleUrls: ['./network-list.component.scss']
})

export class NetworkListComponent {
  @Input() accessPoints: any[];
  @Input() selectedNetwork: Network;
  @Output() onSendCredentials = new EventEmitter<boolean>();
  @ViewChild('networkPassword') passwordInput: IonInput;
  getNetworkStrength = Network.strength;
  postingNetworkCredentials = false;
  manualNetworkName: string;
  userSelectedKnownNetwork = false;

  public knownNetworkSelected($event: Event) {
    this.selectedNetwork.ssid = '';
    this.manualNetworkName = '';
    this.userSelectedKnownNetwork = true;
    this.focusInput(this.passwordInput);
  }

  public otherChanged($event: Event) {
    this.selectedNetwork.ssid = '';
    this.userSelectedKnownNetwork = false;
  }

  public disableRegisterButton(): boolean {
    if (this.postingNetworkCredentials) {
      return true;
    }

    if (this.selectedNetwork.ssid === 'manual-selection') {
      return !this.manualNetworkName || this.manualNetworkName === '';
    } else {
      return !this.selectedNetwork.ssid || this.selectedNetwork.ssid === '';
    }
  }

  public sendNetworkCredentials($event: Event) {
    $event.preventDefault();
    this.onSendCredentials.emit();
  }

  focusInput(input: IonInput) {
    input.setFocus();
  }
}
