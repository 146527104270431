import { registerPlugin } from '@capacitor/core';

import type { HomeKitProvisioningPlugin } from './definitions';

const HomeKitProvisioning = registerPlugin<HomeKitProvisioningPlugin>(
  'HomeKitProvisioning'
);

export * from './definitions';
export { HomeKitProvisioning };
