export const OutletTypes = {
  Default: { id: 0, label: 'Default', iconName: 'default' },
  AirConditioner: { id: 1, label: 'Air Conditioner', iconName: 'airconditioner' },
  Aquarium: { id: 2, label: 'Aquarium', iconName: 'aquarium' },
  CableBox: { id: 3, label: 'Cable Box', iconName: 'cablebox' },
  CoffeeMaker: { id: 4, label: 'Coffee Maker', iconName: 'coffeemaker' },
  Computer: { id: 5, label: 'Computer', iconName: 'computer' },
  CurlingIron: { id: 6, label: 'Curling Iron', iconName: 'curlingiron' },
  Dishwasher: { id: 7, label: 'Dishwasher', iconName: 'dishwasher' },
  Dryer: { id: 8, label: 'Dryer', iconName: 'dryer' },
  Fan: { id: 9, label: 'Fan', iconName: 'fan' },
  Freezer: { id: 10, label: 'Freezer', iconName: 'freezer' },
  Fridge: { id: 11, label: 'Refrigerator', iconName: 'refrigerator' },
  GameConsole: { id: 12, label: 'GameConsole', iconName: 'gameconsole' },
  Heater: { id: 13, label: 'Heater', iconName: 'heater' },
  Hydroponics: { id: 14, label: 'Hydroponics', iconName: 'hydroponics' },
  Iron: { id: 15, label: 'Iron', iconName: 'iron' },
  Lamp: { id: 16, label: 'Lamp', iconName: 'lamp' },
  Microwave: { id: 17, label: 'Microwave', iconName: 'microwave' },
  Oven: { id: 18, label: 'Oven', iconName: 'oven' },
  Speakers: { id: 19, label: 'Speakers', iconName: 'speakers' },
  Terrarium: { id: 20, label: 'Terrarium', iconName: 'terrarium' },
  Toaster: { id: 21, label: 'Toaster', iconName: 'toaster' },
  TV: { id: 22, label: 'TV', iconName: 'tv' },
  Washer: { id: 23, label: 'Washer', iconName: 'washer' },
  HolidayTree: { id: 24, label: 'Holiday Tree', iconName: 'holidaytree' },
  LightStrand: { id: 25, label: 'Light Strand', iconName: 'lightstrand' }
}
