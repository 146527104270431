<ion-item lines="none">
  <ion-button
    size="default"
    (click)="confirmReboot()"
    [disabled]="disabled"
    [fill]="fill"
  >
    <app-icon *ngIf="shouldShowIcon" slot="start" name="reload"></app-icon>
    {{rebootText}}
  </ion-button>
</ion-item>
