import { registerPlugin } from '@capacitor/core';

import type { IwoProvisioningPlugin } from './definitions';

const IwoProvisioning = registerPlugin<IwoProvisioningPlugin>(
  'IwoProvisioning',
  {
    web: () => import('./web').then(m => new m.IwoProvisioningWeb()),
  },
);

export * from './definitions';
export { IwoProvisioning };
