import { NotificationMessage } from './../assets/strings';
import { ThemingService } from './theming.service';
import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { InjectionToken } from '@angular/core';

export const LOCATION_TOKEN = new InjectionToken<Location>('Window location object');

import { environment } from '../environments/environment';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { IonicNotificationService } from './services/ionic-notification.service';
import { CopilotBridge } from '@copilot-bridge';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: LOCATION_TOKEN, useValue: window.location }
  ]
})
export class AppComponent implements OnInit {
  tabs: {url: string, label: string, icon: string, useDefault?: boolean}[] = [
    {url: 'devices', label: 'Home', icon: 'home'},
    {url: 'schedules', label: 'Schedules', icon: 'time'},
    {url: 'usage', label: 'Usage', icon: 'flash'},
    {url: 'settings', label: 'Settings', icon: 'settings-sharp', useDefault: true },
  ];
  year: number = new Date().getFullYear();
  appVersion = environment.appVersion;

  constructor(
    private router: Router,
    private ionicNotificationService: IonicNotificationService,
    private updates: SwUpdate,
    @Inject(LOCATION_TOKEN) private location: Location,
    private route: ActivatedRoute,
    private themingService: ThemingService,
    private zone: NgZone,
  ) {}

  get shouldHideTabs(): boolean {
    const hiddenTabRoutes = ['outlet-setup', 'device-setup', 'login', 'password-reset', 'homekit-setup', 'homekit-list', 'blufi-setup'];
    return hiddenTabRoutes.some(route => this.router.url.includes(route));
  }

  get shouldMarginSafeArea(): boolean {
    const hiddenTabRoutes = ['login'];
    return !hiddenTabRoutes.some(route => this.router.url.includes(route));
  }

  listenForNavigation(): void {
    App.addListener('appUrlOpen', this.handleAppUrlOpen);
  }

  handleAppUrlOpen = (event: URLOpenListenerEvent): void => {
    this.zone.run(() => {
      const eventURL = new URL(event.url);
      this.router.navigateByUrl(eventURL.pathname + eventURL.hash);
    });
  }

  checkForAppUpdates(): void {
    this.updates.available.subscribe(() => {
      this.ionicNotificationService.show(
        NotificationMessage.AppUpdate, NotificationMessage.AppUpdateOption, 0
      ).subscribe(() => {
        this.location.reload();
      });
    });
  }

  trackRouteChanges(): void {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data),
      map((data: any) => data.screenName),
      filter(screenName => !!screenName)
    ).subscribe((screenName: string) => {
      CopilotBridge.logScreenLoad({screenName: screenName});
    });
  }

  ngOnInit() {
    this.checkForAppUpdates();
    this.trackRouteChanges();
    this.themingService.checkThemePreference();
    this.listenForNavigation();
  }
}
