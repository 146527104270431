<ion-list>
  <ion-item button
    [disabled]="!option.enabled"
    *ngFor="let option of options; let last = last;"
    (click)="selectOption(option.value)"
    [lines]="last ? 'none' : itemLines"
    [detail]="false"
  >
    {{ option.text }}
  </ion-item>
</ion-list>
