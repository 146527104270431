import { CustomBackButtonComponent } from './../custom-back-button/custom-back-button.component';
import { FormFieldComponent } from './../form-field/form-field.component';
import { SortByNamePipe } from './pipes/sort-by-name.pipe';
import { IconComponent } from './../icon/icon.component';
import { LoadingIndicatorComponent } from './../loading-indicator/loading-indicator.component';
import { IonicModule } from '@ionic/angular';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IoT8020Module } from '@connectsense/iot8020-library';

import { DeviceListItemComponent } from './device-list-item/device-list-item.component';
import { OutletIconDirective } from './outlet-icon/outlet-icon.directive';
import { OutletToggleComponent } from './outlet-toggle/outlet-toggle.component';
import { FilterEmptyRoomsPipe } from './pipes/filter-empty-rooms.pipe';
import { FilterGroupedDevicesPipe } from './pipes/filter-grouped-devices.pipe';
import { FilterEmptyHomesPipe } from './pipes/filter-empty-homes.pipe';
import { RoomNameFieldComponent } from './room-name-field/room-name-field.component';
import { DeviceScheduleListComponent } from '../schedules/device-schedule-list/device-schedule-list.component';
import { ScheduleDetailComponent } from '../schedules/schedule-detail/schedule-detail.component';
import { ScheduleDevicePickerComponent } from '../schedules/schedule-device-picker/schedule-device-picker.component';
import { ScheduleDayPickerComponent } from '../schedules/schedule-day-picker/schedule-day-picker.component';
import { ScheduleService } from '../schedules/services/schedule.service';
import { IonItemButtonComponent } from './ion-item-button/ion-item-button.component';
import { FilterRoomlessHomesPipe } from './pipes/filter-roomless-homes.pipe';
import { FriendlyDatePipe } from './pipes/friendly-date.pipe';
import { FilterRebootersPipe } from './pipes/filter-rebooters.pipe';
import { OrdinalPipe } from './pipes/ordinal.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IoT8020Module,
    RouterModule,
    IonicModule
  ],
  declarations: [
    DeviceListItemComponent,
    OutletIconDirective,
    OutletToggleComponent,
    FilterEmptyRoomsPipe,
    FilterGroupedDevicesPipe,
    FilterEmptyHomesPipe,
    RoomNameFieldComponent,
    DeviceScheduleListComponent,
    ScheduleDetailComponent,
    ScheduleDevicePickerComponent,
    ScheduleDayPickerComponent,
    LoadingIndicatorComponent,
    IconComponent,
    IonItemButtonComponent,
    FilterRoomlessHomesPipe,
    SortByNamePipe,
    FormFieldComponent,
    FriendlyDatePipe,
    CustomBackButtonComponent,
    FilterRebootersPipe,
    OrdinalPipe,
  ],
  exports: [
    DeviceListItemComponent,
    OutletIconDirective,
    OutletToggleComponent,
    FilterEmptyRoomsPipe,
    FilterGroupedDevicesPipe,
    FilterEmptyHomesPipe,
    RoomNameFieldComponent,
    DeviceScheduleListComponent,
    ScheduleDetailComponent,
    ScheduleDevicePickerComponent,
    ScheduleDayPickerComponent,
    LoadingIndicatorComponent,
    IconComponent,
    IonItemButtonComponent,
    FilterRoomlessHomesPipe,
    SortByNamePipe,
    FormFieldComponent,
    FriendlyDatePipe,
    CustomBackButtonComponent,
    FilterRebootersPipe,
    OrdinalPipe,
  ],
  providers: [
    ScheduleService
  ]
})
export class SharedModule { }
