export interface RegistrationPayload {
  registrationStep: string;
  serialNumber?: string;
  deviceTypeId?: string;
  roomName?: string;
  deviceName?: string;
  outletAName?: string;
  outletBName?: string;
}

export type RegistrationUpdate = (data: RegistrationPayload, error?: any) => void;

export interface HomeKitRegistrationPlugin {
  start(
    input: {isStaging: boolean, claimCode: String, accountId: String, uuid: String},
    callback: RegistrationUpdate
  ): Promise<any>;
  getHomeKitDeviceList(): Promise<any>;
  openHomeApp(): Promise<any>;
  removeHomeKitDevice(input: {deviceId: string}): Promise<any>;
}
