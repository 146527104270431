<ion-toolbar>
  <ion-buttons slot="start">
    <ng-container *ngIf="platform === 'ios'; else androidCancel">
      <app-custom-back-button
        (click)="saveSchedule()"
        [isNewSchedule]="!id"
        [saveButtonEnabled]="saveButtonEnabled"
      ></app-custom-back-button>
    </ng-container>
    <ng-template #androidCancel>
      <ion-button (click)="confirmCancel()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ng-template>
  </ion-buttons>
  <ion-title>{{!!id ? (platform === 'ios' ? 'Update' : 'Modify') : 'Add'}} Schedule</ion-title>

  <ion-buttons slot="end">
    <ng-container *ngIf="platform === 'ios'; else androidSave">
      <ion-button (click)="cancel()">Cancel</ion-button>
    </ng-container>
    <ng-template #androidSave>
      <app-custom-back-button
        (click)="saveSchedule()"
        [isNewSchedule]="!id"
        [saveButtonEnabled]="saveButtonEnabled"
      ></app-custom-back-button>
    </ng-template>
  </ion-buttons>
</ion-toolbar>

<ion-content>
  <app-loading-indicator [message]="LoadingMessage.Schedule" [condition]="loading.schedule"></app-loading-indicator>
  <app-loading-indicator [message]="LoadingMessage.SavingSchedule" [condition]="loading.saving"></app-loading-indicator>

   <ng-container *ngIf="!containsOnlineDevices && !loading.devices">
    <ion-card color="tertiary" class="offline-card">
      <ion-card-content *ngIf="!!id">
        Unable to change schedule with offline devices. Ensure these devices have power and appear online on your network.
      </ion-card-content>
      <ion-card-content *ngIf="!id">
        Unable to add schedule with offline devices. Ensure these devices have power and appear online on your network.
      </ion-card-content>
    </ion-card>
    <div class="disabled-overlay">
    </div>
   </ng-container>

  <ng-container *ngIf="!loading.schedule">
    <ion-list class="loaded-schedule">
      <ion-item
        class="days-widget"
        [lines]="itemLines"
      >
        <ion-label class="days-label" [position]="labelPosition">Days</ion-label>
        <app-schedule-day-picker class="schedule-time-picker" [(cronDays)]="days"></app-schedule-day-picker>
      </ion-item>

      <ion-item
        [lines]="itemLines">
        <ion-label [position]="labelPosition">Time of day</ion-label>
        <ion-datetime-button datetime="datetime"></ion-datetime-button>
      </ion-item>

      <ion-modal [keepContentsMounted]="true" trigger="datetime">
        <ng-template>
          <ion-datetime
            [(ngModel)]="time"
            id="datetime"
            presentation="time"
            [formatOptions]="timeFormatOptions">
          </ion-datetime>
        </ng-template>
      </ion-modal>

      <ion-item
        lines="none"
        *ngIf="!((device && device?.isCSRebooter()) || (devices.length == 1 && device?.isCSLampController()))"
      >
        <ion-note>ConnectSense devices trigger schedules at each device's local time.</ion-note>
      </ion-item>

      <app-form-field
        *ngIf="device && device?.isCSRebooter()"
        [label]="device.name"
        readonlyValue="Reboot"
        manualPosition="none"
      ></app-form-field>

      <app-schedule-device-picker
        *ngIf="device && device?.isCSLampController()"
        [(actions)]="actions"
        [devices]="[device]"
        [errorDeviceId]="errorDeviceId"
        (actionsChange)="handleActionsChange($event)"
      >
      </app-schedule-device-picker>

      <ion-item
        lines="none"
        *ngIf="(device && device?.isCSRebooter()) || (devices.length == 1 && device?.isCSLampController())"
      >
        <ion-note>ConnectSense devices trigger schedules at each device's local time.</ion-note>
      </ion-item>
    </ion-list>

    <ion-list *ngIf="!device?.isCSRebooter() && !device?.isCSLampController()">
      <app-loading-indicator [message]="LoadingMessage.Devices" [condition]="loading.devices"></app-loading-indicator>
      <app-schedule-device-picker
        [(actions)]="actions"
        [devices]="devices"
        [rebootersSchedules]="rebootersSchedules"
        [homes]="homes"
        [errorDeviceId]="errorDeviceId"
        [saveEnabled]="containsOnlineDevices"
        (actionsChange)="handleActionsChange($event)"
        *ngIf="!loading.devices && !device"
      >
      </app-schedule-device-picker>
      <ion-item lines="none" *ngIf="!rebootersSchedules?.length > 0">
        <ion-note>Select devices to include in the schedule and the action for each.</ion-note>
      </ion-item>
      <ion-item lines="none" *ngIf="rebootersSchedules?.length > 0">
        <ion-note>Select which Rebooter to schedule. A Rebooter can have just one schedule, so scheduled Rebooters appear disabled here.</ion-note>
      </ion-item>
    </ion-list>

    <div *ngIf="!!id">
      <app-ion-item-button color="danger" icon="close-circle" label="Remove Schedule" (click)="deleteSchedule()">
      </app-ion-item-button>
    </div>
  </ng-container>
</ion-content>
