import { Injectable } from '@angular/core';

import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { UsageSocket } from '../../models/usage-socket';

import { ApiService } from '../../services/api.service';

@Injectable()
export class UsageService {
  readonly BASE_PATH = '/graphql';
  readonly HIDE_TYPE = 'HIDE';

  constructor(private apiService: ApiService) {}

  getHomeUsage(homeId: string, interval: string): Observable<{
    data: { x: number, y: number }[],
    socketUsageDetails: UsageSocket[],
    totalCost: number,
    totalUsage: number
  }> {
    const query = `{
      homeUsage(homeId: "${homeId}" interval: "${interval}") {
        totalCost totalUsage data { x y } socketUsageDetails { outletType name id deviceId percentage }
      }
    }`;

    const path = `${this.BASE_PATH}?query=${encodeURIComponent(query)}`;

    return this.apiService.getAsStream(path).pipe(
    map(({ data }) => data),
    map(({ homeUsage }) => homeUsage));
  }

  getDeviceUsage(deviceId: string, outlet: string, interval: string): Observable<{
    data: { x: number, y: number }[],
    averageCost: number,
    averageUsage: number
  }> {
    const query = `{
      deviceUsage(deviceId: "${deviceId}" interval: "${interval}" outletId: "${outlet}") {
        deviceId outlet interval data { x y } averageCost averageUsage
      }
    }`;
    const path = `${this.BASE_PATH}?query=${encodeURIComponent(query)}`;

    return this.apiService.getAsStream(path).pipe(
    map(({ data }) => data),
    map(({ deviceUsage }) => deviceUsage));
  }

  getEvents(deviceId: string, startTime: Date, endTime: Date = new Date(), LastEvaluatedKey = null) {
    const query = `{
      adminEvent (filter: {
        thingName: {
          eq: "${deviceId}"
        },
        timestamp: {
          gt: ${startTime.getTime() / 1000},
          lt: ${endTime.getTime() / 1000}
        }
      },
      limit: 200,
      LastEvaluatedKey: ${LastEvaluatedKey}
      ) {
        Items {
          thingName,
          timestamp,
          eventName,
          eventType,
          eventDetail,
          eventDetails
        },
        LastEvaluatedKey
      }
    }`;
    const path = `/graphql?query=${encodeURIComponent(query)}`;

    return this.apiService.getAsStream(path).pipe(
      map(payload => payload.data.adminEvent),
      map(events => {
        return events.Items.map(event => {
          try {
            event.eventDetail = JSON.parse(event.eventDetail);
          } catch (e) {}

          return event;
        });
      })
    );
  }

  getRebootEvents(deviceId: string, startTime: Date, endTime: Date = new Date(), LastEvaluatedKey = null) {
    const internalQuery = `{
      adminEvent (filter: {
        thingName: {
          eq: "${deviceId}"
        },
        eventName: {
          eq: "internal"
        }
        timestamp: {
          gt: ${startTime.getTime() / 1000},
          lt: ${endTime.getTime() / 1000}
        }
      },
      limit: 400,
      LastEvaluatedKey: ${LastEvaluatedKey}
      ) {
        Items {
          thingName,
          timestamp,
          eventName,
          eventType,
          eventDetail,
          eventDetails
        },
        LastEvaluatedKey
      }
    }`;
    const interalPath = `/graphql?query=${encodeURIComponent(internalQuery)}`;

    const userQuery = `{
      adminEvent (filter: {
        thingName: {
          eq: "${deviceId}"
        },
        eventName: {
          eq: "user"
        }
        timestamp: {
          gt: ${startTime.getTime() / 1000},
          lt: ${endTime.getTime() / 1000}
        }
      },
      limit: 400,
      LastEvaluatedKey: ${LastEvaluatedKey}
      ) {
        Items {
          thingName,
          timestamp,
          eventName,
          eventType,
          eventDetail,
          eventDetails
        },
        LastEvaluatedKey
      }
    }`;
    const userPath = `/graphql?query=${encodeURIComponent(userQuery)}`;

    return forkJoin(
      this.apiService.getAsStream(interalPath),
      this.apiService.getAsStream(userPath),
    ).pipe(
      map(([resultOne, resultTwo]) => {
        const combinedData = [...resultOne.data.adminEvent.Items, ...resultTwo.data.adminEvent.Items];
        combinedData.sort((a, b) => a.timestamp - b.timestamp);
        return combinedData;
      }),
      map(events => {
        return events.map(event => {
          try {
            event.eventDetail = JSON.parse(event.eventDetail);
          } catch (e) {}

          return event;
        });
      })
    );
  }

  readifyRebootEvents(events) {
    for (let event of events) {
      switch (event.eventName) {
        case 'internal':
          switch (event.eventDetails) {
            case 'Cron scheduled reboot':
              event.eventName = 'Scheduled';
              break;
            case 'Internet outage detection auto reboot':
              event.eventName = 'Automatic';
              break;
            default:
                event.eventName = this.HIDE_TYPE;
          }
          break;
        case 'user':
          event.eventName = 'Requested';
          break;
        default:
          event.eventName = this.HIDE_TYPE;
      }
    }

    events = events.filter(event => event.eventName !== this.HIDE_TYPE);
    return events.reverse();
  }

}
