import { registerPlugin } from '@capacitor/core';

import type { BlufiProvisioningPlugin } from './definitions';

const BlufiProvisioning = registerPlugin<BlufiProvisioningPlugin>(
  'BlufiProvisioning'
);

export * from './definitions';
export { BlufiProvisioning };
