import { IonicNotificationService } from './ionic-notification.service';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { RebootStatus } from '../models/reboot-status';

@Injectable({
  providedIn: 'root'
})
export class RebootService {
  rebootingDeviceIds: string[] = [];
  readonly rebootMessage = {
    initiating: 'Initiating reboot',
    [RebootStatus.Reboot]: 'Reboot in progress...',
    [RebootStatus.Boot]: 'Reboot complete',
    failed: 'There was an error rebooting the device'
  };
  timeoutSessions: Map<string, NodeJS.Timeout> = new Map();
  rebootingDevices: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private ionicNotificationService: IonicNotificationService,
  ) { }

  recordReboot(rebootingDevice: string) {
    this.rebootingDeviceIds.push(rebootingDevice);
    this.rebootingDevices.next(rebootingDevice);
    this.startRebootTimeout(rebootingDevice);
  }

  finishReboot(rebootedDevice: string) {
    const rebootedDeviceIndex = this.rebootingDeviceIds.indexOf(rebootedDevice);
    this.rebootingDeviceIds.splice(rebootedDeviceIndex, 1);
    this.rebootingDevices.next('');
    clearInterval(this.timeoutSessions.get(rebootedDevice));
    this.timeoutSessions.delete(rebootedDevice);
  }

  startRebootTimeout(rebootingDevice: string) {
    const session = setInterval(() => {
      this.ionicNotificationService.show(this.rebootMessage.failed);
      clearInterval(session);
      this.rebootingDevices.next(`FAILED ${rebootingDevice}`);
      this.finishReboot(rebootingDevice);
    }, 240000);

    this.timeoutSessions.set(rebootingDevice, session);
  }


}
