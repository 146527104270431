import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Auth } from '@auth';
import { Capacitor } from '@capacitor/core';

import { AuthService } from '@connectsense/iot8020-library';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  async canActivate(route?: ActivatedRouteSnapshot, state?: RouterStateSnapshot): Promise<boolean> {
    const visitingLogin = state?.url.indexOf('login') > -1;
    let isLoggedIn: boolean;

    if (Capacitor.isPluginAvailable('Auth')) {
      const { hasValidCredentials } = await Auth.hasValidCredentials()

      isLoggedIn = hasValidCredentials;
    } else {
      isLoggedIn = this.authService.isAuthenticated();
    }

    if (visitingLogin && isLoggedIn) {
      this.router.navigateByUrl('/devices');
      return false;
    }

    return true;
  }

}
