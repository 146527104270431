<div id="signup">
  <form name="signup-form" (ngSubmit)="onSubmit($event)" #signup="ngForm">
    <ion-item class="ion-no-padding double-item" lines="none">
      <ion-input type="text" name="firstName" #firstNameInput
        placeholder="First Name"
        required
        #firstName="ngModel"
        [(ngModel)]="user.firstName"
        autocapitalize="words"
        enterkeyhint="next"
        (keydown.enter)="focusInput(lastNameInput)"
        class="double-input">
      </ion-input>
      <ion-note slot="error">Provide your first name</ion-note>

      <ion-input type="text" name="lastName" #lastNameInput
        placeholder="Last Name"
        required
        #lastName="ngModel"
        [(ngModel)]="user.lastName"
        autocapitalize="words"
        enterkeyhint="next"
        (keydown.enter)="focusInput(emailInput)"
        class="double-input">
      </ion-input>
      <ion-note slot="error">Provide your last name</ion-note>
    </ion-item>

    <ion-item class="ion-no-padding" lines="none">
      <ion-input type="email" name="email" #emailInput
        placeholder="Email Address"
        required
        pattern="^.*@.*$"
        #email="ngModel"
        [(ngModel)]="user.email"
        inputmode="email"
        enterkeyhint="next"
        (keydown.enter)="focusInput(passwordInput)">
      </ion-input>
      <ion-note slot="error">Provide a valid email</ion-note>
    </ion-item>

    <ion-item class="ion-no-padding" lines="none">
      <ion-input type="password" name="password" #passwordInput
        placeholder="Password"
        required
        #password="ngModel"
        [(ngModel)]="user.password"
        appValidatePasswordStrength
        enterkeyhint="next"
        (keydown.enter)="focusInput(passwordConfirmInput)">
      </ion-input>
      <ion-note slot="error" *ngIf="password.errors">
        Password must be:
        <ul class="error-list">
          <li *ngFor="let error of password.errors.strength">
            {{error.message}}

            <ul *ngIf="error.items">
              <li *ngFor="let item of error.items">
                {{item.message}}
              </li>
            </ul>
          </li>
        </ul>
      </ion-note>
    </ion-item>

    <ion-item class="ion-no-padding" lines="none">
      <ion-input type="password" name="passwordConfirm" #passwordConfirmInput
        placeholder="Confirm Password"
        required
        #passwordConfirm="ngModel"
        [(ngModel)]="user.passwordConfirm"
        enterkeyhint="go"
        (keydown.enter)="onSubmit($event)">
      </ion-input>
      <ion-note slot="error">Confirm your password</ion-note>
    </ion-item>

    <ion-button type="submit" class="submit" [disabled]="!signup.form.valid || submitted">
      Create Account
    </ion-button>
  </form>
</div>
