import { Component, Input } from '@angular/core';
import { Device, DeviceTypes } from '@connectsense/iot8020-library';
import { formatDistanceToNowStrict, subSeconds } from 'date-fns';

@Component({
  selector: 'app-outlet-power-monitor',
  templateUrl: './outlet-power-monitor.component.html',
  styleUrls: ['./outlet-power-monitor.component.scss']
})
export class OutletPowerMonitorComponent {
  @Input() amps: number;
  @Input() device: Device
  @Input() occupied;
  @Input() stateTime;
  @Input() totalAmps;
  @Input() watts: number;
  @Input() outletState: boolean;
  voltage = 120;
  wattsUnit = 'W'
  avgLabel = 'AVG'
  nowLabel = 'NOW';
  tiles: {
    [deviceType: string]: {
      attribute: string,
      label: string,
      unit: string,
      icon: string,
    }[]
  } = {
    [DeviceTypes.CSSmartOutlet]: [
      { attribute: 'calculatedWatts', label: 'nowLabel', unit: 'wattsUnit', icon: 'flash' },
      { attribute: 'averageWatts', label: 'avgLabel', unit: 'wattsUnit', icon: 'flash' },
      { attribute: 'stateTimeValue', label: '', unit: 'stateTimeDistanceUnit', icon: '' },
    ],
    [DeviceTypes.CSInWallOutlet]: [
      { attribute: 'watts', label: 'nowLabel', unit: 'wattsUnit', icon: 'flash' },
      { attribute: 'stateTimeValue', label: '', unit: 'stateTimeDistanceUnit', icon: '' },
    ]
  }

  get averageWatts(): number {
    return this.totalAmps / this.stateTime * this.voltage;
  }

  get calculatedWatts(): number {
    return this.amps * this.voltage;
  }

  get stateTimeDistance(): DistanceResult {
    if (!this.stateTime) {
      return {distanceNumber: 0, distanceString: ''};
    }

    return this.getRuntimeDistance(this.stateTime);
  }

  get stateTimeValue(): number {
    return this.stateTimeDistance.distanceNumber;
  }

  get stateTimeDistanceUnit(): string {
    return this.getRuntimeUnit(this.stateTimeDistance.distanceNumber, this.stateTimeDistance.distanceString);
  }

  getTileAttribute(attribute: string): number {
    return this[attribute];
  }

  getTileUnit(unit: string): string {
    return this[unit]
  }

  getTileLabel(label: string): string {
    return this[label];
  }

  getRuntimeDistance(runtime: number): DistanceResult {
    // Get date of now - runtime seconds
    const oldDate = subSeconds(Date.now(), runtime);

    // Format runtime seconds to nice units - e.g. "11 minutes"
    let distanceString = formatDistanceToNowStrict(oldDate);

    // Get just the number out of the string
    const distanceNumber = parseInt(distanceString, 10);

    return {distanceNumber, distanceString};
  }

  getRuntimeUnit(distanceNumber: number, distanceString: string): string {
    const numString = String(distanceNumber);
    const unit = distanceString.split(numString)[1];

    if (!unit) {
      return '';
    }

    if (unit.includes('second')) {
      return 'SEC';
    } else if (unit.includes('minute')) {
      return 'MIN';
    } else if (unit.includes('hour')) {
      return 'HR';
    }  else if (unit.includes('day')) {
      return 'DAYS';
    } else if (unit.includes('month')) {
      return 'MO';
    } else {
      return unit;
    }
  }
}

export interface DistanceResult {
  distanceNumber: number;
  distanceString: string;
}
