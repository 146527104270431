<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button defaultHref="../"></ion-back-button>
  </ion-buttons>

  <ion-title>Device Settings</ion-title>
</ion-toolbar>

<app-loading-indicator message="Loading device settings" [condition]="loading"></app-loading-indicator>

<ion-content>
  <div *ngIf="!loading">
    <ng-container *ngIf="device.isCSRebooter(); else noTabs">
      <ion-toolbar class="ion-no-padding">
        <ion-segment [(ngModel)]="selectedTab" (click)="setSliderValues(selectedTab)">
          <ion-segment-button *ngFor="let tab of tabs" [value]="tab" style="width: 50%;">
            <ion-label>{{tab}}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>

      <ng-container *ngIf="selectedTab === tabs[0]; else advancedTabs">
        <ng-container *ngTemplateOutlet="generalSettings"></ng-container>
      </ng-container>

      <ng-template #advancedTabs>
        <ng-container *ngTemplateOutlet="advancedSettings"></ng-container>
      </ng-template>
    </ng-container>

    <ng-template #noTabs>
      <ng-container *ngTemplateOutlet="generalSettings"></ng-container>
    </ng-template>
  </div>

  <ng-template #generalSettings>
    <form name="update-device-form" (ngSubmit)="onSubmit($event)" #updateDevice="ngForm" class="update-device-form" *ngIf="device">
      <ng-container class="general-settings">
        <ion-item class="ion-no-padding">
          <ion-label position="stacked">Device Name</ion-label>
          <ion-input placeholder="Device Name" [(ngModel)]="device.name" name="name" #name="ngModel" required>
          </ion-input>
          <app-icon
            name="help-circle"
            slot="end"
            (click)="displayHelpDialog($event)"
            *ngIf="device.hasFeature(DeviceFeature.DualOutlets)"
          ></app-icon>
          <ion-note slot="error">Provide a name</ion-note>
        </ion-item>
        <ion-item class="ion-no-padding">
          <ion-label position="stacked">Room</ion-label>
          <ion-select *ngIf="homes && homes.length" placeholder="Room" [(ngModel)]="device.roomId">
            <ng-container *ngFor="let home of homes">
              <ion-select-option disabled>{{home.name}}</ion-select-option>
              <ion-select-option *ngFor="let room of home.rooms" [value]="room.roomId">
                {{room.name}}
              </ion-select-option>
            </ng-container>
          </ion-select>
        </ion-item>
        <ion-item class="ion-no-padding" *ngIf="device.recording_interval">
          <ion-label position="stacked">Recording interval: {{device.recording_interval}} minutes</ion-label>
          <ion-range
            id="recording-interval"
            [max]="120"
            [min]="5"
            [step]="1"
            [value]="device.recording_interval"
            (ionChange)="onRecordingIntervalSliderChanged($event)">
          </ion-range>
        </ion-item>
      </ng-container>

      <div class="button-row">
        <ion-button class="button-save" [disabled]="!updateDevice.form.valid || disableForm" type="submit">
          <app-icon name="checkmark"></app-icon>
          Save
        </ion-button>
        <ion-button color="danger" [disabled]="disableForm" (click)="onDeactivateButtonClicked($event)">
          <app-icon name="remove-circle"></app-icon>
          Remove device
        </ion-button>
      </div>
    </form>
  </ng-template>

  <ng-template #advancedSettings>
    <ng-container class="advanced-settings">
      <form name="advanced-settings-form" (ngSubmit)="onSubmitAdvanced($event)" #updateAdvanced="ngForm" class="update-device-form" *ngIf="device">
        <h4>Intelligent Reboot</h4>
        <p class="reboot-description">When enabled, if internet is out for a duration of Offline Detection Time, the device will reboot after selected Reboot Delay</p>
        <ion-item-divider></ion-item-divider>

        <ion-item lines="none" class="ion-no-padding">
          <ion-label class="toggle-label">{{device.config.auto_reset ? 'Enabled' : 'Disabled'}}</ion-label>
          <ion-toggle
            [(ngModel)]="device['config'].auto_reset" name="rebootEnabled"
            [(labelPosition)]="before" [(checked)]="device.config.auto_reset" color="primary"
          ></ion-toggle>
        </ion-item>

        <fieldset>
          <legend>Offline Detection Time</legend>
          <div class="advanced-slider-container">
            <ion-range
              #detectionTimeSlider
              (ionChange)="setRangeValue($event, 'detection_time')"
              min="2" max="10" step="1" color="primary"
              [(disabled)]="!device.config.auto_reset"
            ></ion-range>
            <span class="slider-label">{{device.config.detection_time}}</span>
            <span>minutes</span>
          </div>
        </fieldset>

        <fieldset>
          <legend>Reboot Delay</legend>
          <div class="advanced-slider-container">
            <ion-range
              #delaySlider
              (ionChange)="setRangeValue($event, 'outlet1_on_delay')"
              min="0" max="10" step="1" color="primary"
              [(disabled)]="!device.config.auto_reset"
            ></ion-range>
            <span class="slider-label">{{device.config.outlet1_on_delay}}</span>
            <span>minutes</span>
          </div>
        </fieldset>

        <ion-item class="ion-no-padding">
          <ion-label position="stacked">Device Type</ion-label>
            <ion-select placeholder="Device Type" [(ngModel)]="device.config.outlet1_type" (ngModelChange)="selectType($event)">
            <ion-select-option *ngFor="let type of RebooterTypes" [value]="type.outlet1_type">
              {{type.label}}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <div class="button-row">
          <ion-button type="submit" class="button-save" [disabled]="!updateAdvanced.form.valid || disableForm">
            <app-icon name="checkmark"></app-icon>
            Save
          </ion-button>
        </div>
      </form>
    </ng-container>
  </ng-template>
</ion-content>
