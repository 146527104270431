import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-room-name-field',
  templateUrl: './room-name-field.component.html',
  styleUrls: ['./room-name-field.component.scss']
})
export class RoomNameFieldComponent {
  @Output() roomNameChange = new EventEmitter<string>();
  readonly suggestedRoomNames: string[] = [
    'Kitchen',
    'Master Bedroom',
    'Guest Bedroom',
    'Basement',
    'Living Room',
    'Dining Room',
    'Garage'
  ];
  private _roomName: string;
  maxlength = 25;
  @Input() get roomName() {
    return this._roomName;
  }
  set roomName(value: string) {
    this._roomName = value;
    this.roomNameChange.emit(this._roomName);
  }
}
