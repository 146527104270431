import { SelectionModalComponent } from './../selection-modal/selection-modal.component';
import { ModalController } from '@ionic/angular';
import { LoadingMessage } from './../../assets/strings';
import { Component, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

import { Observable, forkJoin, of, zip } from 'rxjs';
import { mergeMap, switchMap, filter } from 'rxjs/operators';

import {
  Device,
  DeviceService,
  FirmwareService,
  CSSmartOutlet,
  CSInWallOutlet,
  DeviceFeature
} from '@connectsense/iot8020-library';

import { HomeService } from '../home/services/home.service';
import { Home } from '../models/home';
import { Room } from '../models/room';
import { RebooterType, RebooterTypes } from '../models/rebooter-types';

@Component({
  selector: 'app-outlet-setup',
  templateUrl: './outlet-setup.component.html',
  styleUrls: ['./outlet-setup.component.scss']
})
export class OutletSetupComponent implements OnInit, AfterViewInit {
  @ViewChild('step', { static: false }) displayedStep: ElementRef;
  readonly DeviceFeature = DeviceFeature;
  LoadingMessage = LoadingMessage;
  device: CSSmartOutlet | CSInWallOutlet;
  deviceId: string;
  location: string;
  desiredData: {
    outlet1_type?: number,
    outlet1_name?: string,
    outlet2_type?: number,
    outlet2_name?: string
  } = {};
  homeKitInfo: {
    deviceName?: string,
    outlet1_name?: string,
    outlet2_name?: string
  } = {};
  desiredConfig: {
    auto_reset: boolean,
    outlet1_on_delay?: number,
    outlet1_type?: number,
    outage_trigger_time?: number,
  } = {
    auto_reset: true
  };
  outlets = [
    {
      id: 'outlet1',
      label: 'Outlet A'
    },
    {
      id: 'outlet2',
      label: 'Outlet B'
    }
  ];
  message: string;
  saving = false;
  loading = true;
  homes: Home[] = [];
  home = 'My Home';
  homeId: string;
  room: string;
  deviceForm: FormGroup = new FormGroup({
    name: new FormControl('Device'),
    room: new FormControl('Room'),
    home: new FormControl('My Home'),
    newRoom: new FormControl('My Room'),
  });
  step = 0;
  get title(): string {
    return this.displayedStep?.nativeElement?.getAttribute('label') || '';
  }

  get roomLabel(): string {
    let selectedRoomName: string;

    this.homes.forEach((home) => {
      return home.rooms?.forEach(room => {
        if (room.roomId === this.device.roomId) {
          return selectedRoomName = room.name;
        }
      });
    });
    return selectedRoomName;
  }

  constructor(
    private deviceService: DeviceService,
    public route: ActivatedRoute,
    private router: Router,
    private firmwareService: FirmwareService,
    private homeService: HomeService,
    private cdRef: ChangeDetectorRef,
    private modalController: ModalController,
  ) {}

  getOutletImage(outlet: string, deviceTypeId: string): string {
    if (!deviceTypeId) { return ''; }

    return `../../assets/outlet-setup/${deviceTypeId}-${outlet}.png`;
  }

  loadDeviceAndHomes(): void {
    this.route.params.pipe(
    switchMap(() => zip(
      this.deviceService.getDevice(this.deviceId),
      this.homeService.getHomes()
    )))
    .subscribe(([device, homes]: [Device, Home[]]) => {
      this.device = device;
      this.deviceForm.controls.name.setValue(device.name);
      this.deviceForm.controls.room.setValue(device.roomId);
      this.homes = homes;
      this.loading = false;
      this.updateFirmware();

      if (this.homes.length) { this.homeId = this.homes[0].homeId; }
    });
  }

  next(): void {
    this.step = this.step + 1;
    this.onStepChange(this.step);
  }

  previous(): void {
    this.step = this.step - 1;
    this.onStepChange(this.step);
  }

  onSubmit(): void {
    this.message = LoadingMessage.SavingOutlet;
    this.saving = true;

    forkJoin(
      this.updateData(),
      this.updateRoom(),
      this.updateDevice(),
      this.updateConfig()
    ).subscribe(() => {
      this.saving = false;

      if (!this.device.isCSLampController() && this.step > 1) {
        this.router.navigate(['/devices'], { queryParams: { 'refresh': true } });
        return;
      }

      this.next();
    });
  }

  onLabelChange(label: string, outletId: string): void {
    if (this.homeKitInfo[`${outletId}_name`]) {
      this.desiredData[`${outletId}_name`] = this.homeKitInfo[`${outletId}_name`];
      return this.next();
    }

    if (label === 'Default') {
      const selectedOutlet = this.outlets.filter(outlet => outlet.id === outletId)[0];
      label = selectedOutlet.label;
    }
    this.desiredData[`${outletId}_name`] = label;
    this.next();
  }

  skip(): void {
    this.router.navigate(['/devices'], { queryParams: { 'refresh': true } });
  }

  updateConfig(): Observable<boolean> {
    if (!this.device.isCSRebooter()) {
      return of(true);
    }

    return this.deviceService.updateDeviceConfig(this.deviceId, this.desiredConfig);
  }

  updateData(): Observable<boolean> {
    if (!Object.keys(this.desiredData).length) {
      return of(true);
    }

    return this.deviceService.updateDeviceData(this.deviceId, this.desiredData);
  }

  updateDevice(): Observable<Device> {
    if (this.homeKitInfo.deviceName) {
      this.device.name = this.homeKitInfo.deviceName;
      return this.deviceService.updateDevice(this.device);
    }

    if (this.deviceForm.pristine) { return of(this.device); }

    this.device.name = this.deviceForm.value.name;
    return this.deviceService.updateDevice(this.device);
  }

  async openSelectionModal(title: string, selectorType: string, value: any, arrayName?: string) {
    const modal = await this.modalController.create({
      component: SelectionModalComponent,
      componentProps: {
        ['title']: title,
        ['selectorType']: selectorType,
        ['value']: value,
        ['array']: this[arrayName]
      }
    });

    modal.present();
    const { data, role } = await modal.onWillDismiss();

    this.deviceForm.controls.room.setValue(data);
    this.device.roomId = data;
  }

  updateRoom(): Observable<any> {
    if (!this.homes.length) {
      return this.createHomeAndRoom();
    }

    this.device.roomId = this.deviceForm.value.room;

    if (!this.device.roomId) {
      return of(true);
    }

    return this.homeService.changeDeviceRoom(this.deviceId, this.device.roomId);
  }

  createHomeAndRoom() {
    const homeName = this.deviceForm.value.home;
    const roomName = this.deviceForm.value.newRoom;

    const home: Home = {
      devices: [],
      homeId: '',
      name: homeName,
      rooms: []
    };

    return this.homeService.createHome(home).pipe(mergeMap(({ homeId }) => {
      const room: Room = {
        devices: [this.device],
        homeId,
        name: roomName,
        roomId: ''
      };

      return this.homeService.createRoom(room);
    }));
  }

  updateFirmware(): void {
    const { deviceTypeId, deviceId, state: { mcu_firmware_version } } = this.device;

    if (!mcu_firmware_version) {
      return;
    }

    this.firmwareService.checkForUpdate(deviceTypeId, deviceId, mcu_firmware_version)
    .pipe(
      filter(({ updateAvailable }) => updateAvailable),
      mergeMap(() => this.firmwareService.updateFirmware(deviceTypeId, deviceId))
    )
    .subscribe();
  }

  onStepChange(step: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { step }
    });
  }

  onRebooterTypeSelected(type): void {
    const selectedType = RebooterTypes.find((rebooterType) => rebooterType.outlet1_type === type);
    this.desiredConfig = {
      outlet1_type: selectedType.outlet1_type,
      outlet1_on_delay: selectedType.outlet1_on_delay,
      auto_reset: this.desiredConfig.auto_reset,
      outage_trigger_time: selectedType.outage_trigger_time,
    };
  }

  ngOnInit() {
    this.deviceId = this.route.snapshot.params['deviceId'];
    this.route.queryParamMap
    .subscribe(params => {
      if (params.get('name')) {
        const homeKitName = (params.get('name'));
        const homeKitRoom = (params.get('room'));
        const homeKitOutletA = (params.get('outletA'));
        const homeKitOutletB = (params.get('outletB'));

        this.homeKitInfo.deviceName = homeKitName;
        this.homeKitInfo.outlet1_name = homeKitOutletA;
        this.homeKitInfo.outlet2_name = homeKitOutletB;
      }

      this.loadDeviceAndHomes();
      this.deviceService.getDevices().subscribe(); // Workaround for empty device store
    });
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
    this.route
    .queryParamMap
    .subscribe(params => {
      this.step = Number(params.get('step'));
    });
  }
}
