export interface RebooterType {
  outlet1_type: number
  label: string
  outlet1_on_delay: number
  outage_trigger_time: number
};

export const RebooterTypes: RebooterType[] = [
  {
    outlet1_type: 1,
    label: 'Modem',
    outlet1_on_delay: 0,
    outage_trigger_time: 0,
  },
  {
    outlet1_type: 2,
    label: 'Router',
    outlet1_on_delay: 4,
    outage_trigger_time: 4,
  },
  {
    outlet1_type: 3,
    label: 'Combination',
    outlet1_on_delay: 0,
    outage_trigger_time: 0,
  },
  {
    outlet1_type: 0,
    label: 'Other',
    outlet1_on_delay: 0,
    outage_trigger_time: 0,
  }
];
