<ion-toolbar>
  <ion-buttons slot="start">
    <ion-back-button defaultHref="../" (click)="onSubmit($event)"></ion-back-button>
  </ion-buttons>

  <ion-title>Outlet Type</ion-title>
</ion-toolbar>

<ion-content>
  <app-outlet-type-picker [(type)]="type"></app-outlet-type-picker>
</ion-content>
