import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { format, isToday, isYesterday, isThisWeek, isValid } from 'date-fns';

@Pipe({
  name: 'friendlyDate'
})
@Injectable({
  providedIn: 'root'
})
export class FriendlyDatePipe implements PipeTransform {
  transform(timestamp, capitalize = false): string {
    const date = new Date(timestamp);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (!isValid(date)) {
      return;
    }

    let result = '';

    if (isToday(date)) {
      result = 'today at ' + format(date, 'h:mm a');
    } else if (isYesterday(date)) {
      result = 'yesterday at ' + format(date, 'h:mm a');
    } else if (isThisWeek(date)) {
      result = format(date, 'iiii') + ' at ' + format(date, 'h:mm a');
    } else {
      result = format(date, 'MMM d, yyyy') + ' at ' + format(date, 'h:mm a');
    }

    return capitalize ? this.capitalizeFirstLetter(result) : result;
  }

  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
