import { RebootService } from './../services/reboot.service';
import { thingShadow } from 'aws-iot-device-sdk';
import { Component, Input, KeyValueDiffers, DoCheck, OnInit } from '@angular/core';

import { Device, DeviceFeature } from '@connectsense/iot8020-library';

@Component({
  selector: 'app-device-summary',
  templateUrl: './device-summary.component.html',
  styleUrls: ['./device-summary.component.scss']
})
export class DeviceSummaryComponent implements DoCheck, OnInit {
  @Input() device: Device;
  @Input() outlet: string;
  @Input() shadow: thingShadow;
  differ: any;
  isRebooting = false;
  readonly DeviceFeature = DeviceFeature;

  get canShowSummary(): boolean {
    return (this.device.hasFeature(DeviceFeature.OutletControl) || this.device.hasFeature(DeviceFeature.RebootManually))
    || (!this.device.isConnected())
  }

  constructor(
    private rebootService: RebootService,
    private differs:  KeyValueDiffers,
  ) { this.differ = differs.find([]).create(); }

  ngOnInit(): void {
    this.rebootService.rebootingDevices.subscribe((deviceId) => {
      if (!deviceId) {
        return;
      }

      if (deviceId.includes('FAILED') && deviceId.includes(this.device.deviceId)) {
        this.isRebooting = false;
      } else if (this.rebootService.rebootingDeviceIds.includes(this.device.deviceId)) {
        this.isRebooting = true;
      }
    });
  }

  ngDoCheck(): void {
    if (!this.device.isCSRebooter() || !this.rebootService.rebootingDeviceIds.includes(this.device.deviceId)) {
      return;
    }
    const changes = this.differ.diff(this.device);
    if (changes) {
      changes.forEachChangedItem((change) => {
        if (change.key === 'data' && !change.currentValue.offline) {
          this.isRebooting = false;
        }
      });
    }
  }
}
