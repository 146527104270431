export interface UsageInterval {
  averageLabel: string,
  chartLabelFormat: string,
  chartTicksLimit: number,
  label: string,
  length: number
}

export const intervals: UsageInterval[] = [
  {
    averageLabel: 'hour',
    chartLabelFormat: 'h a',
    chartTicksLimit: 4.1,
    label: 'day',
    length: 24
  },
  {
    averageLabel: 'day',
    chartLabelFormat: 'dd',
    chartTicksLimit: undefined,
    label: 'week',
    length: 7
  },
  {
    averageLabel: 'day',
    chartLabelFormat: 'd',
    chartTicksLimit: 5.1,
    label: 'month',
    length: 31
  },
  {
    averageLabel: 'month',
    chartLabelFormat: 'MMM',
    chartTicksLimit: undefined,
    label: 'year',
    length: 12
  }
];
