<ion-card>
  <ion-card-header *ngIf="outlets[1] || status">
    <ion-card-subtitle class="device-header" type="ios">
      <p class="device-name">{{device.name}}</p>
      <p class="device-status">{{status}}</p>
    </ion-card-subtitle>
    <ion-item-divider></ion-item-divider>
  </ion-card-header>

  <ion-card-content class="ion-no-padding">
    <ng-container *ngFor="let outlet of outlets">
      <ion-item class="outlet-content" (click)="navigate(outlet)" lines="none">
        <img
        *ngIf="!isRebooting"
        [ngClass]="{'rebooting-img': device.isCSRebooter()}"
        class="outlet-img"
        appOutletIcon
        [active]="device.data[outlet + '_active']"
        [device]="device"
        [occupied]="device.data[outlet + '_occupied']"
        [type]="device.data[outlet + '_type']"
        >
        <img
        *ngIf="isRebooting"
        class="outlet-img rebooting-img"
        src="../../assets/outlet-type-icons/rebooting.gif"
        >
        <div class="item-text">
          <ion-item class="outlet-label-container" (click)="navigate(outlet)" lines="none">
            <ion-label class="outlet-label" [ngClass]="{'offline': !device.isConnected()}">
              {{device.data[outlet + '_name'] || device.name}}
            </ion-label>
          </ion-item>
          <ion-item class="offline-label" *ngIf="!device.isConnected() && !isRebooting; else isOnline" lines="none">
            <ion-label>
              Offline
            </ion-label>
          </ion-item>
          <ng-template #isOnline>
            <app-device-card-control
              (click)="didClickCardControl($event)"
              [device]="device"
              [outlet]="outlet"
              [shouldExecuteCommand]="true"
              [shadow]="shadow"
            ></app-device-card-control>
          </ng-template>
        </div>
      </ion-item>
    </ng-container>
  </ion-card-content>
</ion-card>
