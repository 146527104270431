import { DialogTitle } from './../../assets/strings';
import { IonInput } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { IonicDialogService } from 'app/services/ionic-dialog.service';
import { Component, forwardRef, Input, ViewChild, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector : 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => FormFieldComponent),
  }]
})
export class FormFieldComponent implements ControlValueAccessor, OnInit {
  @Input() hint: string;
  @Input() maxlength: number;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() labelDisabled: boolean;
  @Input() useMinLabelWidth = true;
  @Input() readonlyValue: string;
  @Input() helperButtonText: string;
  @Input() manualPosition: string;
  @Input() manualLines: string;
  @Input() noPadding: boolean;
  @Input() customClass: string;
  @Input() isButton = false;
  @Input() inputType = '';
  @ViewChild('input', { static: true }) input: IonInput;
  position = Capacitor.getPlatform() === 'ios' ? undefined : 'floating';
  itemLines = Capacitor.getPlatform() === 'ios' ? 'inset' : 'none';
  private _value = '';
  get value(): string { return this._value; };

  set value(value: string) {
    if (value !== this._value) {
      this._value = value;
      this.onChange(value);
    }
  }

  constructor(
    private ionicDialogService: IonicDialogService,
  ) {}

  ngOnInit() {
    if (this.manualPosition === 'none') {
      this.position = undefined;
    }

    if (this.manualLines) {
      this.itemLines = this.manualLines;
    }
  }

  writeValue(value: string) {
    this._value = value;
    this.onChange(value);
  }

  onChange = (_) => {};
  onTouched = () => {};

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  displayHelpDialog(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    this.ionicDialogService.generic(DialogTitle.Help, `<p>${this.helperButtonText}<p>`);
  }

  blurInput(input: IonInput) {
    input.getInputElement().then((nativeInput) => {
      nativeInput.blur();
  });
  }
}
