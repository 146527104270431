import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { OutletTypes } from '../models/outlet-types';

@Component({
  selector: 'app-outlet-type-picker',
  templateUrl: './outlet-type-picker.component.html',
  styleUrls: ['./outlet-type-picker.component.scss']
})
export class OutletTypePickerComponent implements OnInit {
  @Input() type: number;
  @Output() typeChange = new EventEmitter<number>();
  @Output() labelChange = new EventEmitter<string>();
  outletTypes = Object.keys(OutletTypes).map(key => OutletTypes[key]);

  isSelected(id: number): boolean {
    return this.type === id;
  }

  selectType(id: number, label: string) {
    this.typeChange.emit(id);
    this.labelChange.emit(label);
  }

  ngOnInit() {
    this.type = this.type || 0;
  }
}
