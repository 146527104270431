<ion-list>
  <app-form-field
    label="Serial Number"
    [readonlyValue]="serialNumber"
  ></app-form-field>
  <ng-container *ngIf="device.hasFeature(DeviceFeature.Identify)">
    <ion-item [lines]="itemLines">
      <ion-button fill="clear" class="ion-no-padding ion-no-margin list-button" (click)="identify(device.deviceId)">
        <span>Identify</span>
      </ion-button>
    </ion-item>
  </ng-container>
  <app-form-field
    label="Firmware Version"
    [readonlyValue]="device.state.mcu_firmware_version"
    [manualLines]="isVersionLastItem ? 'none' : itemLines"
  ></app-form-field>
  <ng-container *ngIf="device.hasFeature(DeviceFeature.FirmwareAutoUpdate)">
    <ion-item [lines]="itemLines">
      <ion-label>Auto-install Firmware Updates</ion-label>
      <ion-toggle
        [checked]="device.state.auto_update"
        color="primary"
        id="auto-update-checkbox"
        slot="end"
        (ionChange)="onAutoUpdateChange($event.detail.checked, device.deviceId)"
      ></ion-toggle>
    </ion-item>
  </ng-container>
  <ion-item *ngIf="firmwareUpdateAvailable && device.isConnected()" [lines]="itemLines">
    <ion-button fill="clear" class="ion-no-padding ion-no-margin list-button" (click)="updateFirmware()">
      <span>Update Firmware Now</span>
    </ion-button>
  </ion-item>
    <ng-container *ngIf="canBeHomeKitPaired">
      <ion-item>
        <ion-button [disabled]="!device.isConnected()" fill="clear" class="ion-no-padding ion-no-margin list-button" (click)="startHomeKitReboot(device.deviceId)">
          <span>Add to HomeKit</span>
        </ion-button>
      </ion-item>
      </ng-container>
</ion-list>
