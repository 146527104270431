import { RebooterSchedule } from './../../models/rebooter-schedule';
import { FilterRebootersPipe } from './../../shared/pipes/filter-rebooters.pipe';
import { Capacitor } from '@capacitor/core';
import { ErrorTitle, LoadingMessage, ErrorMessage, DialogTitle, DialogMessage } from './../../../assets/strings';
import { IonicDialogService } from './../../services/ionic-dialog.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router, Params } from '@angular/router';

import { zip, of } from 'rxjs';
import { map, concatMap, filter, finalize, switchMap, flatMap, take, pluck } from 'rxjs/operators';

import { Device, DeviceService, Store } from '@connectsense/iot8020-library';

import { Actions } from '../../models/actions';
import { Home } from '../../models/home';
import { Schedule } from '../../models/schedule';
import { ScheduleService } from '../services/schedule.service';
import { HomeService } from '../../home/services/home.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-schedule-detail',
  templateUrl: './schedule-detail.component.html',
  styleUrls: ['./schedule-detail.component.scss']
})
export class ScheduleDetailComponent implements OnInit {
  LoadingMessage = LoadingMessage;
  device: Device;
  deviceId: string;
  rebootersSchedules: RebooterSchedule[];
  actions: Actions = {};
  devices: Device[] = [];
  homes: Home[] = []
  id: string;
  time = '03:00';
  days = '0,1,2,3,4,5,6';
  loading = {
    devices: true,
    schedule: true,
    saving: false,
  };
  errorDeviceId: string;
  isOnDeviceDetail = false;
  timeFormatOptions: any;

  platform = Capacitor.getPlatform();
  labelPosition = this.platform === 'ios' ? undefined : 'floating';
  itemLines = this.platform === 'ios' ? 'inset' : 'none';

  get saveButtonEnabled(): Boolean {
    return this.deviceSelected && this.containsOnlineDevices;
  }

  get deviceSelected(): Boolean {
    for (const deviceId in this.actions) {
      if (this.actions.hasOwnProperty(deviceId)) {
        return true;
      }
    }

    return false;
  }

  get containsOnlineDevices(): Boolean {
    if (this.device) {
      return this.device.isConnected();
    }

    const isAnyDeviceOffline = Object.keys(this.actions).some(deviceId =>
      this.devices?.some(device =>
        device.deviceId === deviceId && !device.isConnected()
      )
    );

    const isEveryDeviceOffline = this.devices?.every(device =>
      !device.isConnected()
    );

    if (isEveryDeviceOffline) {
      return false;
    }

    if (isAnyDeviceOffline && !!this.id) {
      return false;
    }

    if (isAnyDeviceOffline && !this.id) {
      return true;
    }

    if (!isAnyDeviceOffline) {
      return true;
    }
  }

  constructor(
    private deviceService: DeviceService,
    private scheduleService: ScheduleService,
    private route: ActivatedRoute,
    private router: Router,
    private ionicDialogService: IonicDialogService,
    private homeService: HomeService,
    private store: Store,
    private navController: NavController,
    private filterRebootersPipe: FilterRebootersPipe,
  ) { }

  ngOnInit() {
    this.getDevicesAndHomes();
    this.isOnDeviceDetail = this.router.url.includes('devices');
    this.setTimeFormatOptions();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.getSchedule(params.get('id'));
    });

    this.route
      .queryParamMap
      .subscribe(params => {
        const deviceId = params.get('deviceId');
        if (deviceId !== null && this.deviceId === undefined) {
          this.deviceId = deviceId;
        }
    });

    this.route?.paramMap
    .pipe(
      switchMap((params: Params) => {
        const deviceId = params.get('deviceId');
        if (deviceId !== null && this.deviceId === undefined) {
          this.deviceId = deviceId;
        }

        return this.store.changes.pipe(pluck(Store.Keys.Devices));
      }),
      filter(Boolean),
      flatMap((devices: Device[]) => devices),
      filter(({ deviceId }: Device) => deviceId === this.deviceId),
      take(1)
    )
    .subscribe((device: Device) => {
      if (this.rebootersSchedules?.length) {
        return this.device = undefined;
      }
      this.device = device;
      this.loading.devices = false;
      this.setDeviceActions();
    });
  }

  setDeviceActions() {
    if (this.actions[this.device.deviceId]) {
      this.device.data.outlet1_active = this.actions[this.device.deviceId][0].outlet1_active;
    } else {
      this.device.data.outlet1_active = true;

      let actions = { outlet1_active: true };

      if (this.device.isCSRebooter()) {
        actions['outlet1_reboot'] = true;
      }

      this.actions = {[this.device.deviceId]: [actions]};
    }
  }

  getDevicesAndHomes(): void {
    const currentNavigation = this.router.getCurrentNavigation();
    this.rebootersSchedules = currentNavigation?.extras.state?.rebootersSchedules;
    if (this.rebootersSchedules?.length) {
      this.device = undefined;
    }
    const specificDevices = this.rebootersSchedules?.map(schedule => schedule.device);
    const devicesObservable = specificDevices ? of(specificDevices) : this.deviceService.getDevices();

    zip(
      devicesObservable,
      this.homeService.getHomes()
    ).pipe(
    map(([devices, homes]: [Device[], Home[]]) => {
      devices = devices.map(device => {
        device.data.outlet1_active = false;
        device.data.outlet2_active = false;
        return device;
      });

      devices = specificDevices ? devices : this.filterRebootersPipe.transform(devices);
      return { devices, homes };
    }))
    .subscribe(({ devices, homes }) => {
      this.devices = devices;
      this.homes = this.homeService.associateHomesWithDevices(homes, this.devices);
      this.loading.devices = false;
    });
  }

  setTimeFormatOptions() {
    const format = new Intl.DateTimeFormat(undefined, { hour: 'numeric' });
    const options = format.resolvedOptions();
    this.timeFormatOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: options.hour12,
    };
  }

  getSchedule(id: string): void {
    if (id === 'new') {
      this.loading.schedule = false;
      return;
    }

    this.id = id;

    this.scheduleService.getSchedule(id).subscribe((schedule: Schedule) => {
      const [minutes, hours, , , days] = schedule.schedule.split(' ');
      this.days = days;
      this.time = `${hours}:${minutes}`;
      this.actions = schedule.action;

      if (this.device) {
        this.device.data.outlet1_active = this.actions[this.device.deviceId][0].outlet1_active;
      }
    }, error => {
      console.error(error);
      this.ionicDialogService.generic(ErrorTitle.Error, ErrorMessage.ScheduleFetchFail);
    }, () => {
      this.loading.schedule = false;
    });
  }

  createSchedule(): void {
    this.errorDeviceId = undefined;

    this.scheduleService.createSchedule(this.time, this.days, this.actions)
    .subscribe(() => {
      this.cancel();
    }, error => {
      if (error && error.error && error.error.code === 400 && error.data) {
        const message = ErrorMessage.ScheduleLimitFail;
        this.errorDeviceId = error.data;
        this.ionicDialogService.generic(ErrorTitle.Error, message);

        return;
      }

      this.ionicDialogService.generic(ErrorTitle.Error, ErrorMessage.ScheduleCreateFail);
    });
  }

  confirmCancel() {
    if (this.saveButtonEnabled) {
      return this.ionicDialogService.confirm(
        'Close schedule',
        'Close schedule and abandon changes?',
        [
          {text: 'Cancel', role: 'cancel'},
          {text: 'Close', role: 'confirm'},
        ]).pipe(
          filter((userDidConfirm) => userDidConfirm === true)
        )
        .subscribe(() => {
          this.cancel();
        });
    } else {
      this.cancel();
    }
  }

  cancel() {
    this.navController.back();
  }

  saveSchedule(): void {
    if (!this.saveButtonEnabled) {
      return this.cancel();
    }

    this.loading.saving = true;

    if (!this.id) {
      return this.createSchedule();
    }

    this.scheduleService.updateSchedule(this.id, this.time, this.days, this.actions)
    .subscribe(() => {
      return this.cancel();
    }, error => {
      console.error(error);
      this.ionicDialogService.generic(ErrorTitle.Error, error || ErrorMessage.ScheduleUpdateFail);
    });
  }

  deleteSchedule(): void {
    const title = DialogTitle.ScheduleDelete;
    const body = DialogMessage.ScheduleDelete;
    this.ionicDialogService.confirm(title, body)
    .pipe(
      filter((userDidConfirm: boolean) => userDidConfirm === true),
      concatMap(() => this.scheduleService.deleteSchedule(this.id))
    )
    .subscribe(() => {
      this.cancel();
    }, error => {
      console.error(error);
      this.ionicDialogService.generic(ErrorTitle.Error, ErrorMessage.ScheduleDeleteFail);
    });
  }

  outletSelected(
    {deviceId, actions}: {deviceId: string, actions: { [target: string]: boolean }[]}
  ): void {
    this.actions[deviceId] = actions;
  }

  handleActionsChange(event) {
  }
}
