import { DialogTitle, DialogMessage, DialogButtonText } from './../../assets/strings';
import { ModalController } from '@ionic/angular';
import { AuthService } from '@connectsense/iot8020-library';
import { IonicDialogService } from './../services/ionic-dialog.service';
import { Component, OnDestroy, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ErrorTitle } from 'assets/strings';


@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnDestroy {
  @ViewChild('pwReset', { static: true }) pwReset: NgForm;
  ngUnsubscribe: Subject<void> = new Subject<void>();
  @Input() loginURL = '/login'
  @Input() email;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private ionicDialogService: IonicDialogService,
    private router: Router,
    private modalController: ModalController,
  ) { }

  onSubmit = ($event: Event) => {
    $event.preventDefault();

    this.authService.changePassword(this.email)
    .subscribe(success => {
      this.ionicDialogService.confirm(
        DialogTitle.Success,
        DialogMessage.ResetSuccess,
        [{text: DialogButtonText.OK, role: 'confirm'}]
      ).subscribe(() => {
        this.router.navigate([this.loginURL], { queryParams: {email: this.email} });
      });
    }, err => {
      this.ionicDialogService.generic(ErrorTitle.Error, err.description);
      console.error(err);
    });
  }

  cancel() {
    return this.modalController.dismiss(null, 'cancel');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
