<ng-container *ngIf="device">
  <ion-list
    [ngClass]="device.deviceTypeId"
    *ngIf="canShowSummary"
  >
    <ion-item [ngClass]="{'offline': !device.isConnected()}" lines="none">
      <ion-thumbnail>
        <img
          *ngIf="!isRebooting"
          [ngClass]="{'rebooting-img': device.isCSRebooter()}"
          class="outlet-img"
          appOutletIcon
          [active]="device.data[outlet + '_active']"
          [device]="device"
          [occupied]="device.data[outlet + '_occupied']"
          [type]="device.data[outlet + '_type']"
        >
        <img
          *ngIf="isRebooting"
          class="outlet-img rebooting-img"
          src="../../assets/outlet-type-icons/rebooting.gif"
        >
      </ion-thumbnail>
      <ion-item *ngIf="!device.isConnected() && !isRebooting; else isOnline" lines="none" class="offline-label">
        <ion-label class="ion-no-margin">Offline</ion-label>
      </ion-item>
      <ng-template #isOnline>
        <app-outlet-toggle
          *ngIf="!device.hasFeature(DeviceFeature.RebootManually)"
          [device]="device"
          [outlet]="outlet">
        </app-outlet-toggle>
        <div class="reboot-container" *ngIf="device.hasFeature(DeviceFeature.RebootManually)">
          Online
          <app-manual-reboot-button
          *ngIf="device.hasFeature(DeviceFeature.RebootManually)"
          [device]="device"
          [disabled]="!device.isConnected()"
          [shadow]="shadow"
        ></app-manual-reboot-button>
        </div>
      </ng-template>
    </ion-item>

    <ion-item *ngIf="device.hasFeature(DeviceFeature.RealtimePowerMonitoring)" lines="none">
      <app-outlet-power-monitor
        [amps]="device.data[outlet + '_amps']"
        [device]="device"
        [occupied]="device.data[outlet + '_occupied']"
        [stateTime]="device.data[outlet + '_state_time']"
        [totalAmps]="device.data[outlet + '_total_amps']"
        [watts]="device.data[outlet + '_watts']"
        [outletState]="device.data[outlet + '_active']"
      ></app-outlet-power-monitor>
    </ion-item>
  </ion-list>
</ng-container>

