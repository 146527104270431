<ion-toolbar>
  <ion-buttons slot="start">
    <ng-container *ngIf="platform === 'ios'; else androidBack">
      <app-custom-back-button
        (click)="back()"
        [isNewSchedule]="false"
        [saveButtonEnabled]="true"
      ></app-custom-back-button>
    </ng-container>
    <ng-template #androidBack>
      <ion-button (click)="back()">
        <ion-icon slot="icon-only" name="arrow-back"></ion-icon>
      </ion-button>
    </ng-template>
  </ion-buttons>

  <ion-title>Monitored Addresses</ion-title>

  <ion-buttons slot="end">
    <ion-buttons slot="end">
      <ion-button (click)="addAddress()" [disabled]="targetAddresses.length === 5">
        <app-icon name="add"></app-icon>
      </ion-button>
    </ion-buttons>
  </ion-buttons>
</ion-toolbar>

<ion-content>
  <app-loading-indicator [condition]="loading"></app-loading-indicator>
  <app-loading-indicator [condition]="isWaitingToNavBack"></app-loading-indicator>

  <ng-container *ngIf="!loading">
    <ion-list class="address-list">
      <ion-item-sliding *ngFor="let address of targetAddresses; let i = index; trackBy: trackByFn" #slidingItem>
        <app-form-field
          label="{{i + 1 | ordinal}} Address"
          [useMinLabelWidth]="false"
          [ngModel]="!forcingRender[i] ? address : ''"
          (ionBlur)="updateAddress($event, address, i)"
          inputType="url"
        ></app-form-field>

        <ion-item-options>
          <ion-item-option color="danger" (click)="deleteAddress(i, slidingItem)">Delete</ion-item-option>
        </ion-item-options>
      </ion-item-sliding>
      <ion-item lines="none" class="form-divider">
        <ion-note slot="helper">
          {{addressesHint}}
        </ion-note>
      </ion-item>
    </ion-list>

    <app-form-field
      label="Outage Condition"
      placeholder="-"
      manualLines="none"
      [readonlyValue]="outageConditionLabel"
      [isButton]="true"
      [hint]="outageConditionHint"
      (click)="openOutageConditionModal($event)"
    ></app-form-field>

    <ion-item lines="none">
      <ion-button fill="clear" class="ion-no-padding ion-no-margin list-button" (click)="resetDefaults()">
        <span>Reset to Defaults</span>
      </ion-button>
    </ion-item>
  </ng-container>
</ion-content>
