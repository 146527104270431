import { LoadingMessage } from './../../../assets/strings';
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { switchMap, filter, take, flatMap, pluck } from 'rxjs/operators';

import { Device, Store } from '@connectsense/iot8020-library';

import { ScheduleService } from '../services/schedule.service';
import { Schedule } from '../../models/schedule';

@Component({
  selector: 'app-device-schedule-list',
  templateUrl: './device-schedule-list.component.html',
  styleUrls: ['./device-schedule-list.component.scss']
})
export class DeviceScheduleListComponent {
  LoadingMessage = LoadingMessage;
  device: Device;
  deviceId: string;
  loading = true;
  schedules: (Schedule & { time: number })[];

  constructor(
    private scheduleService: ScheduleService,
    private route: ActivatedRoute,
    private store: Store,
    public router: Router
  ) { }

  ionViewWillEnter() {
    this.route.paramMap
    .pipe(
      switchMap((params: Params) => {
        this.deviceId = params.get('deviceId');

        return this.store.changes.pipe(pluck(Store.Keys.Devices));
      }),
      filter(Boolean),
      flatMap((devices: Device[]) => devices),
      filter(({ deviceId }: Device) => deviceId === this.deviceId),
      take(1)
    )
    .subscribe((device: Device) => {
      this.device = device;
      this.getSchedules();
    });
  }

  getScheduleAction(schedule: Schedule, device: Device): string {
    if (device.isCSRebooter()) {
      return 'Reboot';
    } else {
      return schedule.action[this.device.deviceId][0].outlet1_active ? 'Turn On' : 'Turn Off';
    }

  }

  getSchedules() {
    this.scheduleService.getSchedules(this.deviceId)
    .subscribe((schedules: Schedule[]) => {
      this.schedules = schedules.map((schedule: Schedule & { time: number }) => {
        const { minutes, hours } = this.scheduleService.parseCronExpression(schedule.schedule);
        schedule.time = new Date().setHours(hours, minutes);
        return schedule;
      });
      this.loading = false;
    });
  }

}
