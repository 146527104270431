import { ErrorTitle } from './../../assets/strings';
import { IonInput } from '@ionic/angular';
import { IonicDialogService } from './../services/ionic-dialog.service';
import { Component, Output, EventEmitter, ViewChild } from '@angular/core';

import { AuthService } from '@connectsense/iot8020-library';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  @ViewChild('lastNameInput') lastNameInput: IonInput;
  @ViewChild('emailInput') emailInput: IonInput;
  @ViewChild('passwordInput') passwordInput: IonInput;
  @ViewChild('passwordConfirmInput') passwordConfirmInput: IonInput;

  user = {
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    email: ''
  };

  submitted = false;

  @Output() onSignup = new EventEmitter<string>();

  constructor(
    private auth: AuthService,
    private ionicDialogService: IonicDialogService
  ) { }

  onSignupComplete($event: Event) {
    this.onSignup.emit(this.user.email);
  }

  focusInput(input: IonInput) {
    input.setFocus();
  }

  onSubmit($event) {
    $event.preventDefault();

    this.submitted = true;
    const user = this.user;

    this.auth
      .signup(user.firstName, user.lastName, user.email, user.password, user.passwordConfirm)
      .subscribe(() => {
        this.onSignupComplete(null);
      }, err => {
        this.submitted = false;

        this.ionicDialogService.generic(ErrorTitle.Error, err.description || err);
        console.error(err);
      });
  }
}
